import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import moment from "moment";

import {
  Autocomplete,
  Box,
  Typography,
  Grid,
  TextField,
} from '@mui/material';

import { debounce } from '@mui/material/utils';

import {
  sendSearch,
  addToSymptomsList,
  addToClarificationsList,
  addToPatientEditDisease,
  addToPatientEditClarificationsList,
  sendGetSymptomsFromText,
} from "features/pages/pagesSlice";

// import styles from '../Patient.module.css';

function declension(forms, val) {
  const cases = [2, 0, 1, 1, 1, 2];
  return forms[val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]];
}
const ref_days = ["день", "дня", "дней"],
  ref_weeks = ["неделя", "недели", "недель"],
  ref_months = ["месяц", "месяца", "месяцев"],
  ref_years = ["год", "года", "лет"];


const defOptions = [
  { label: "1 год", value: 1693861200000 },
  { label: "1 месяц", value: 1693861200000 },
  { label: "1 день", value: 1693861200000 }
];

const ageFormat = {
  value: 1693861200000,
  inputData: [
    {
      label: "1 год",
      type: "y",

    },
    {
      label: "2 месяца",
      type: "m",
    },
    {
      label: "2 недели",
      type: "w",
    },
    {
      label: "5 дней",
      type: "d"
    }
  ]
}

const BirthDateComponent = ({ value = "", type, resultItemProps = {}, label = "", item = {}, itemIcon = "", showLevel = false, inputColor = "", variant = "inline" }) => {
  const dispatch = useDispatch();

  // const [value, setValue] = useState(null);
  const [optionsDisabled, setOptionsDisabled] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(defOptions);



  const fetch = useMemo(
    () =>
      debounce((request, success) => {
        console.log("DEB");
      }, 400),
    [dispatch, item.id, type],
  );

  useEffect(() => {
    let active = true;


    // if (inputValue === '' || inputValue.length <= MIN_SEARCH_TEXT) {
    //   setOptions(value ? [value] : []);
    //   return undefined;
    // }

    const wordsCount = (inputValue.match(/\S+/g) || []).length;

    console.log(wordsCount);

    // if (wordsCount > MAX_SEARCH_WORDS) {
    //   setOptions([]);
    //   return undefined;
    // }

    fetch({ text: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        // setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return <TextField
    fullWidth
    disabled={true}
    label="Возраст"
    inputProps={{
      sx: {
        backgroundColor: "#fcfdfd"
      }
    }}
  />;
  // return <>
  //   <Autocomplete
  //     sx={{
  //       backgroundColor: "#ceedff66"
  //     }}
  //     disabled={true}
  //     fullWidth
  //     filterSelectedOptions
  //     id="search1"
  //     getOptionLabel={(option) =>
  //       typeof option === 'string' ? option : option.label
  //     }
  //     filterOptions={(x) => x}
  //     options={optionsDisabled ? [] : options}
  //     // freeSolo={true}
  //     inputValue={inputValue}
  //     value={value}

  //     noOptionsText={"нет результатов"}

  //     onHighlightChange={(_, options) => {
  //       setIsHighlighted(!!options?.name);
  //     }}
  //     onChange={(_, newValue) => {

  //       let year = 0,
  //         month = 0,
  //         week = 0,
  //         day = 0,
  //         birthyear = 0;

  //       let str = newValue;
  //       let resArr = str.split(", ");
  //       if (resArr.length < 2) resArr = str.split(" ");
  //       resArr.forEach((val, i) => {
  //         if (ref_years.includes(val)) year = parseInt(resArr[i - 1]);
  //         if (ref_months.includes(val)) month = parseInt(resArr[i - 1]);
  //         if (ref_weeks.includes(val)) week = parseInt(resArr[i - 1]);
  //         if (ref_days.includes(val)) day = parseInt(resArr[i - 1]);
  //         if (val === "рождения" || val === "р.") birthyear = parseInt(resArr[i - 2]);
  //         if (val === "г.р.") birthyear = parseInt(resArr[i - 1]);
  //       });
  //       // console.log({ year, month, week, day });
  //       // console.log(moment().format('DD.MM.YYYY'));
  //       // if (year < 150) {
  //       let date = moment().add({ years: -year, months: -month, weeks: -week, days: -day }).format("x");
  //       if (birthyear > 0) date = moment().set("year", birthyear).format("x");
  //       //let date = moment().year(-year).month(-month).week(-week).day(-day).format('X')
  //       // console.log('birth', date, birthyear);
  //       // onChange && onChange(null, { name, value: parseInt(date) });
  //       // this.setState({ date, birth: value });

  //       console.log("date", date);
  //       console.log("value", value);















  //       // if (type === "Symptoms") {
  //       //   dispatch(addToSymptomsList(newValue));
  //       // } else if (type === "Details" || type === "Localization") {
  //       //   dispatch(addToClarificationsList({ item, value: newValue, type }));
  //       // } else if (type === "Disease") {
  //       //   dispatch(addToPatientEditDisease(newValue));
  //       // } else if (type === "DiseaseDetails") {
  //       //   dispatch(addToPatientEditClarificationsList({ item, value: newValue, type }));
  //       // }

  //       // setInputValue("");
  //       // setValue(null);
  //     }}
  //     onInputChange={(_, newInputValue) => {


  //       console.log(newInputValue);
  //       let results = [];

  //       if (newInputValue < 121) {
  //         results.push({ type: "y", label: `${newInputValue} ${declension(ref_years, newInputValue)}` });
  //       }
  //       if (newInputValue <= 12) {
  //         results.push({ type: "m", label: `${newInputValue} ${declension(ref_months, newInputValue)}` });
  //       }
  //       if (newInputValue <= 44) {
  //         results.push({ type: "w", label: `${newInputValue} ${declension(ref_weeks, newInputValue)}` });
  //       }
  //       if (newInputValue <= 31) {
  //         results.push({ type: "d", label: `${newInputValue} ${declension(ref_days, newInputValue)}` });
  //       }

  //       setOptions(results);

  //       setInputValue(newInputValue);
  //     }}
  //     renderInput={(params) => (
  //       <TextField
  //         label={label}
  //         fullWidth
  //         multiline={variant === "parse"}
  //         {...params}
  //       />
  //     )}

  //     renderOption={(props, option) => {
  //       return (
  //         <li {...props} key={props.id}>
  //           <Grid container alignItems="center">
  //             <Grid item sx={{ display: 'flex', width: 30 }}>
  //               {itemIcon}
  //             </Grid>
  //             <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
  //               <Box
  //                 component="span" {...resultItemProps}
  //               >
  //                 {option.label}
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </li>
  //       );
  //     }}
  //   />
  // </>;
}

export const BirthDate = memo(BirthDateComponent);
