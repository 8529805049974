import React, { memo } from "react";

import {
    Box,
    Collapse,
    FormControl,
    Typography,
    Slider,

} from '@mui/material';

import { TransitionGroup } from 'react-transition-group';

import { InputValueBlock } from "./InputValueBlock";

const SliderBlockComponent = ({
    onChange = null,
    label = "",
    canTurnOff,
    valueLabelFormat,
    value,
    min,
    step,
    disabled,
    turnKey,
    ...otherProps
}) => {

    let realMin = min;

    if (canTurnOff) {
        min = min - step;
    }

    const handleChange = (event) => {
        onChange && onChange(event);
    }

    return <TransitionGroup>
        {!disabled && <Collapse>
            <Box
                sx={{
                    transition: "all 3s",
                }}>
                <FormControl fullWidth sx={{ mt: 3, width: "94%", opacity: value < realMin ? 1 : 1 }} >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <Typography>{label}</Typography>
                        <InputValueBlock value={valueLabelFormat ? valueLabelFormat(value) : value} />
                    </Box>
                    <Slider
                        size="small"
                        valueLabelDisplay="auto"
                        onChange={handleChange}
                        valueLabelFormat={valueLabelFormat}
                        color={value < realMin ? "error" : "primary"}
                        value={value}
                        min={min}
                        marks={step >= 1}
                        step={step}
                        disabled={disabled}
                        {...otherProps}
                    />
                </FormControl>
            </Box>
        </Collapse>}
    </TransitionGroup>;
}

export const SliderBlock = memo(SliderBlockComponent);
