import React, { memo } from "react";

import {
    List,
    Typography,
} from "@mui/material";

import { ResultItem } from "./ResultItem";

const Items = ({ data, depth = 0 }) => {
    return <>
        {
            data.map((item, key) => {
                return <React.Fragment key={key}>
                    <ResultItem item={item} depth={depth} />
                    {item.internalEntities.length ? <Items data={item.internalEntities} depth={depth + 1} /> : null}
                </React.Fragment>;
            })
        }
    </>;
}

const ResultListComponent = ({ data = [], title = "" }) => {

    return <>
        <Typography variant="h6">{title}</Typography>
        <List>
            <Items data={data} />
        </List>
    </>;
}

export const ResultList = memo(ResultListComponent);
