export default function (G6) {
  G6.registerBehavior('select-neighbours', {
    getDefaultCfg() {
      return {
        multiple: false,
      };
    },
    getEvents() {
      return {
        'node:click': 'onNodeClick',
      };
    },

    onNodeClick(event) {
      const originalEvent = event.originalEvent || {};
      const currentShape = event.item._cfg.currentShape;

      if (originalEvent.shiftKey) {
        const item = event.item;
        const selected = item.hasState("selected");

        if (originalEvent.ctrlKey) {
          const neighbors = this.graph.getNeighbors(item, 'target');

          neighbors.forEach(node => {
            this.graph.setItemState(node, 'selected', !selected);
          });
        }

        if (currentShape !== "ghost-node") {
          this.graph.setItemState(item, 'selected', !selected);
        }
      }
    },
  });
}
