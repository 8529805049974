import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    List,
} from "@mui/material";


import {
    selectPatientEditLifesAnamnez,
} from "features/pages/pagesSlice";

import { LifesAnamnezItem } from "./LifesAnamnezItem";

const LifesAnamnezListComponent = ({ id }) => {
    const LifesAnamnez = useSelector(selectPatientEditLifesAnamnez);


    return <>{LifesAnamnez.length ?
        <Box sx={{
            // height: "calc(100vh - 180px)",
            width: "100%",
            overflow: "auto"
        }}>
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                }}>
                {LifesAnamnez.map((item, index) => (
                    <LifesAnamnezItem 
                    item={item} 
                    key={`${index}_${item.id}`}
                     />
                ))}
            </List>
        </Box> : null}
    </>;
}

export const LifesAnamnezList = memo(LifesAnamnezListComponent);
