import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";

import ClearIcon from '@mui/icons-material/Clear';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Fade,
} from "@mui/material";

import {
  removeSymptomById,
  sendCheckParametricData,
  setCurrentSymptom,
} from "features/pages/pagesSlice";

const SymptomItemComponent = ({ item }) => {
  const [isIn, setIsIn] = useState(true);

  const dispatch = useDispatch();

  const handleClickRemoveSymptom = () => {
    setIsIn(false);

    setTimeout(() => {
      dispatch(removeSymptomById(item.id));
    }, 300);
  }

  const handleClickClarifications = () => {
    dispatch(sendCheckParametricData({ data: { id: item.id },success:()=>{} }));
    dispatch(setCurrentSymptom(item.id));
  }

  let characters = [];
  let localizations = [];

  if (item.clarifications?.length) {
    item.clarifications.forEach(item => {
      if (item.type === "Details") {
        characters.push(item.name);
      }
    });

    item.clarifications.forEach(item => {
      if (item.type === "Localization") {
        localizations.push(item.name);
      }
    });
  }
  
  return (<Fade
    in={isIn}
    style={{ transformOrigin: '0 0 0' }}
    timeout={400} >
    <ListItem
      sx={{
        width: "100%",
        backgroundColor: item.type !== "Nozology" ? "#aa12bf0d": "#3977c614",
        "&:not(:last-child)": {
          borderBottom: "1px solid lightgray",
        },
      }}
      key={item.id}
      secondaryAction={
        <>
          <IconButton edge="end" aria-label="settings" onClick={handleClickClarifications}>
            <SettingsIcon />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={handleClickRemoveSymptom}>
            <ClearIcon />
          </IconButton>
        </>
      }
      disablePadding
    >
      <ListItemButton>
        <ListItemText
          id={"labelId"}
          primary={<Box sx={{ display: "flex" }}>
            <Box sx={{
              fontWeight: "600",
              "&:first-letter": {
                textTransform: "uppercase"
              },
            }}>{item.name || "- без названия -"}</Box>
            <Box sx={{ color: "rgba(0, 0, 0, 0.6)", ml: 2, textTransform: "lowercase" }}>{localizations.join(", ")}</Box>
          </Box>}
          secondary={characters.join(", ")}
          secondaryTypographyProps={{ sx: { textTransform: "lowercase" } }}
        />
      </ListItemButton>
    </ListItem>
  </Fade>
  );
}

export const SymptomItem = memo(SymptomItemComponent);
