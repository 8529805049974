
import { useEffect, useRef } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

export const useCurrentPath = (routes) => {
    const location = useLocation();
    const [{ route }] = matchRoutes(routes, location);

    return route.path;
};

export const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
