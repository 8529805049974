export default function (G6) {
  G6.registerEdge('tool-edge', {
    draw(cfg, group) {
      const { startPoint, endPoint } = cfg;
      const keyShape = group.addShape('path', {
        attrs: {
          path: [
            ['M', startPoint.x, startPoint.y],
            ['L', endPoint.x, endPoint.y],
          ],
          stroke: 'steelblue',
          lineWidth: 2,
          lineDash: [15, 3],
          lineAppendWidth: 5,
          // startArrow: {
          //   // The custom arrow is a path points at (0, 0), and its tail points to the positive direction of x-axis
          //   path: 'M 0,0 L 20,10 L 20,-10 Z',
          //   // the offset of the arrow, nagtive value means the arrow is moved alone the positive direction of x-axis
          //   // d: -10
          // },
          // endArrow: {
          //   // The custom arrow is a path points at (0, 0), and its tail points to the positive direction of x-axis
          //   path: 'M 0,0 L 20,10 L 20,-10 Z',
          //   // the offset of the arrow, nagtive value means the arrow is moved alone the positive direction of x-axis
          //   // d: -10
          // },
        },
        // must be assigned in G6 3.3 and later versions. it can be any value you want
        name: 'path-shape',
      });
      return keyShape;
    },
  });
}