import React, { memo, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Button,
    ButtonGroup,
    Typography,
    FormControl,
    Slider,
} from '@mui/material';

import {
    resetSettings,
    selectSettings,
    selectSymptomsList,
    setSetting,
    sendGetDiagnostic,
    selectLoading,
    selectRAS,
    setRASPage,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";

import { Loader } from "UI";
import { Questions } from "./components";



const RASComponent = () => {
    const dispatch = useDispatch();


    const RAS = useSelector(selectRAS);

    const { questions, currentPage } = RAS;

    console.log(questions, currentPage);

    const pagesCount = questions.length;


    const pageObjectDefault = useMemo(() => questions ? questions[currentPage] : {}, [currentPage, questions]);

    console.log("pageObjectDefault", pageObjectDefault);

    const [pageObject, setPageObject] = useState({});

    const { diagnostic: loading } = useSelector(selectLoading);


    const handleClickBack = () => {
        console.log("handleClickBack");

        if (currentPage === 0) {
            dispatch(setCurrentPage("result"));
        } else {
            dispatch(setRASPage(currentPage - 1));
        }
    }

    const handleClicNext = () => {
        console.log("handleClicNext");

        if (currentPage >= pagesCount - 1) {
            dispatch(sendGetDiagnostic({
                success: () => dispatch(setCurrentPage("result"))
            }));
        } else {
            dispatch(setRASPage(currentPage + 1));
        }
    }

    useEffect(() => {
        setPageObject(pageObjectDefault)
    }, [pageObjectDefault]);


    console.log("pageObject", pageObject);

    const sensors = pageObject.sensors || {};


    return <>
        <Loader loading={loading}>
            <Box sx={{
                height: "calc(100vh - 126px)",
                overflow: "auto",
                width: '100%',
                pt: 0,
                "& :nth-child(2n)": {
                    // backgroundColor: "lightgray",
                },
            }}>
                <Box
                    sx={{
                        mb: 2,
                    }}>
                    <Typography variant="h4">{pageObject.title || ""}</Typography>
                    <Typography variant="body1">{pageObject.description || ""}</Typography>
                </Box>

                <Questions sensors={sensors} />

            </Box>

            <ButtonGroup
                fullWidth
                size="large"
                aria-label="button group"
            >
                <Button
                    disabled={false}
                    onClick={handleClickBack}
                >Назад</Button>

                <Button
                    disabled={false}
                    onClick={handleClicNext}
                >Дальше</Button>
            </ButtonGroup>
        </Loader>
    </>;
}

export const RAS = memo(RASComponent);
