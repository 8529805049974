import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {

    setAlerts: (state, action) => {
      state = [
        ...action.payload
      ];
    },

    addAlerts: (state, action) => {
      state = [
        ...state,
        ...action.payload
      ];
    },
  },
});

export const {
  setAlerts,
  addAlerts,
} = alertsSlice.actions;

export const selectAlerts = (state) => state.alerts;


export default alertsSlice.reducer;
