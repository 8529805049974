import { GRAPH_SETTINGS } from 'constants';

// import { fixColor, getIcon } from '../../../../helpers';

import red from '@mui/material/colors/red';
import purple from '@mui/material/colors/purple';
import blue from '@mui/material/colors/blue';
import teal from '@mui/material/colors/teal';
import orange from '@mui/material/colors/orange';
import indigo from '@mui/material/colors/indigo';
import cyan from '@mui/material/colors/cyan';
import green from '@mui/material/colors/green';

const colors = [
    red[500],
    blue[500],
    teal[500],
    purple[500],
    orange[500],
    cyan[500],
    green[500],
    indigo[500],
];

const ANCHOR_RADIUS = GRAPH_SETTINGS.NODE_RADIUS + 10;
const ANCHOR_SHIFTED = ANCHOR_RADIUS - (GRAPH_SETTINGS.ANCHOR_SIZE / 2);

export default function MasterConcept(G6) {
    G6.registerNode(
        'master-concept-node',
        {
            fittingString(str, maxWidth, fontSize) {
                let currentWidth = 0;
                let res = str || "";
                res = res.trim();

                if (!res) {
                    return [""];
                }

                const words = str.split(" ");
                const letterWidth = G6.Util.getLetterWidth("м", fontSize);
                let rows = [];
                let currentRow = "";

                words.forEach((word, i) => {
                    currentWidth += word.length * letterWidth;
                    currentRow += word + " ";

                    if (currentWidth > maxWidth || words.length - 1 === i) {
                        rows.push(currentRow.trim());
                        currentWidth = 0;
                        currentRow = "";
                    }
                });
                return rows;
            },

            generateWedgeString(startX, startY, startAngle, endAngle, radius) {
                const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
                const x1 = startX + radius * Math.cos(Math.PI * startAngle / 180);
                const y1 = startY + radius * Math.sin(Math.PI * startAngle / 180);
                const x2 = startX + radius * Math.cos(Math.PI * endAngle / 180);
                const y2 = startY + radius * Math.sin(Math.PI * endAngle / 180);
                const pathString = "M" + startX + " " + startY + " L" + x1 + " " + y1 + " A" + radius + " " + radius + " 0 " + largeArcFlag + " 1 " + x2 + " " + y2 + " z";

                return pathString;
            },

            currentTitle: null,

            drawShape(cfg, group) {
                console.log("drawShape");
            },

            draw(cfg, group) {
                let data = cfg?.data || {};
                let levelColor = data.levelColor || null;
                let color = data.color || 'black';

                /**
                 * Этот круг вмещает в себя все элементы ноды, в данный момент используется для того, чтобы рёбра строились от центра и не смещались.
                 * Смещение происходит из-за того, что отступ заголовка (offset of label) больше размеров ноды.
                 * @todo попробовать как вариант - не привязывать к центру ноды, а задать конкретную точку, которая центр круга 
                */

                let containerShape = group.addShape('circle', {
                    attrs: {
                        r: Math.floor(GRAPH_SETTINGS.NODE_RADIUS * 1.8),
                        // fill: 'transparent',

                        fillOpacity: 0.1,
                        lineDash: 11
                    },
                    draggable: true,
                    zIndex: 20,
                    name: 'master-concept-node-circle-container',
                });

                // Группа для инструментов
                const toolsGroup = group.addGroup({
                    id: 'toolsGroup',
                    zIndex: 1,
                    visible: false,
                    draggable: true,
                    name: 'tools-group',
                });

                const tools = data.tools || [];
                const radius = GRAPH_SETTINGS.NODE_RADIUS;
                const deg = 360 / tools.length;


                // Перемещаем деление концепта в конец, чтобы его рамка не перекрывалась другими инструментами
                let binaryToolIndex = tools.findIndex(tool => tool.id === -1);

                if (binaryToolIndex !== -1) {
                    const sizeof = tools.length;
                    // tools.splice(0, 0, tools.splice(binaryToolIndex, 1)[0]);
                    [tools[binaryToolIndex], tools[sizeof - 1]] = [tools[sizeof - 1], tools[binaryToolIndex]];
                }

                tools.forEach((tool, i) => {
                    // Уменьшаем радиус, если в инструменте нет связей
                    const currentDeg = 180 + (deg * i) - (deg / 2);
                    const toolRadius = tool.count ? radius : Math.floor(radius / 1.15);
                    const opacity = tool.count ? 1 : 1;

                    // Для деления концепта собственные стили
                    let toolStyleParams = {};
                    if (tool.id === -1) {
                        toolStyleParams = {
                            stroke: "#0082dd",
                            fill: "#FFF",
                            lineWidth: 2,
                        };
                    }

                    // Инструменты
                    toolsGroup.addShape('path', {
                        attrs: {
                            path: this.generateWedgeString(0, 0, currentDeg, currentDeg + deg, toolRadius),
                            // stroke: randomFromArray(colors),
                            stroke: '#FFF',
                            lineWidth: 2,
                            // fill: randomFromArray(colors),
                            fill: tool.color,
                            opacity,
                            ...toolStyleParams,
                        },
                        name: 'inst-path',
                        // visible: true,
                        zIndex: 10,
                        draggable: true,
                        anchorPointIdx: i
                    });
                });

                // Внешний круг концепта (цвет типа)
                if (levelColor) {
                    group.addShape('circle', {
                        attrs: {
                            r: Math.floor(GRAPH_SETTINGS.NODE_RADIUS - (GRAPH_SETTINGS.NODE_RADIUS / 100 * 35)),
                            fill: levelColor,
                            stroke: '#FFF',
                            lineWidth: 2,
                        },
                        zIndex: 20,
                        draggable: true,
                        name: 'master-concept-node-level-circle',
                    });

                    // Внутренний круг концепта (цвет таблицы)
                    group.addShape('circle', {
                        attrs: {
                            r: Math.floor(GRAPH_SETTINGS.NODE_RADIUS - (GRAPH_SETTINGS.NODE_RADIUS / 100 * 35) - 12),
                            fill: color,
                            stroke: GRAPH_SETTINGS.NODE_STROKE_COLOR,
                            lineWidth: 1,
                        },
                        zIndex: 25,
                        draggable: true,
                        name: 'master-concept-node-circle',
                    });
                } else {
                    group.addShape('circle', {
                        attrs: {
                            r: Math.floor(GRAPH_SETTINGS.NODE_RADIUS - (GRAPH_SETTINGS.NODE_RADIUS / 100 * 35)),
                            fill: color,
                            stroke: '#FFF',
                            lineWidth: 2,
                        },
                        zIndex: 20,
                        draggable: true,
                        name: 'master-concept-node-circle',
                    });
                }

                const fittingNameArray = this.fittingString(data.name || "", 200, GRAPH_SETTINGS.MAIN_FONT_SIZE);
                const fittingName = fittingNameArray.join("\n");

                // Название концепта
                const itemText = group.addShape('text', {
                    attrs: {
                        x: 0,
                        y: GRAPH_SETTINGS.NODE_RADIUS - 13 + ((GRAPH_SETTINGS.MAIN_FONT_SIZE + 1) * fittingNameArray.length),
                        text: fittingName,
                        textAlign: 'center',
                        fill: GRAPH_SETTINGS.NODE_LABEL_COLOR,
                        fontWeight: 400,
                        // shadowOffsetX: 0,
                        // shadowOffsetY: 0,
                        fontSize: GRAPH_SETTINGS.MAIN_FONT_SIZE,
                    },

                    draggable: true,
                    name: 'master-concept-node-name',
                    zIndex: 10000,
                });

                // const TitleBbox = itemText.getBBox();

                // group.addShape('rect', {
                //     attrs: {
                //         x: TitleBbox.x,
                //         y: TitleBbox.y,
                //         width: TitleBbox.width,
                //         height: TitleBbox.height,
                //         fill: "#FFF",
                //         stroke: 'black',
                //         radius: [2, 4],
                //     },
                //     // must be assigned in G6 3.3 and later versions. it can be any value you want
                //     name: 'title-background',
                //     zIndex: 100,
                // });

                // group.sort();

                // itemText.move(0, GRAPH_SETTINGS.NODE_RADIUS + 12)


                /**
                 * Группа для заголовков инструментов, используется в hoverTool.js
                 * */
                // group.addGroup({
                //     id: 'toolsTitlesGroup',
                //     zIndex: 100,
                // });

                return group;
            },

            afterDraw(cfg, group) {
                // console.log("afterDraw");
            },

            getAnchorShapes(cfg) {
                const tools = cfg?.data?.tools || [];
                const deg = 360 / tools.length;
                let anchorsArray = [];

                Math.radians = function (degrees) {
                    return degrees * Math.PI / 180;
                };

                tools.forEach((tool, i) => {
                    let transformX = Math.cos(Math.radians(i * deg)) * (ANCHOR_SHIFTED / 2) - ANCHOR_SHIFTED / 2;
                    let transformY = Math.sin(Math.radians(i * deg)) * (ANCHOR_SHIFTED / 2) - ANCHOR_SHIFTED / 2;
                    transformX = Math.abs(transformX) / ANCHOR_SHIFTED;
                    transformY = Math.abs(transformY) / ANCHOR_SHIFTED;
                    anchorsArray.push([transformX, transformY]);
                });
                return anchorsArray;
                // return [[0, 0.5], [0.33, 0], [0.66, 0], [1, 0.5], [0.33, 1], [0.66, 1]];
            },

            setState(name, value, item) {
                if (name === 'showAnchors') {
                    const anchorPoints = item.getContainer().findAll(element => element.get('name') === 'anchor-point');
                    anchorPoints.forEach(point => {
                        if (value || point.get('links') > 0) {
                            point.show();
                        } else {
                            point.hide();
                        }
                    })
                }

                if (name === 'noLabel') {
                    const group = item.getContainer();
                    const labelShape = group.find(element => element.get('name') === 'master-concept-node-name');

                    if (value === true) {
                        labelShape.hide();
                    } else {
                        labelShape.show();
                    }

                }

                if (name === 'selected') {
                    //     fill: '#03232b',
                    //     stroke: '#001d18',
                    //     strokeWidth: 1,
                    //     // opacity: 0.3,
                    //     fillOpacity: 0.1,
                    //     lineDash: 10
                    // },
                    // zIndex: 20,
                    // name: 'master-concept-node-circle-container',

                    const group = item.getContainer();
                    const containerShape = group.find(element => element.get("name") === 'master-concept-node-circle-container');


                    // console.log(containerShape);

                    if (value === true) {
                        containerShape.attr({
                            strokeWidth: 1,
                            fill: "#03232b",
                            stroke: '#98c1d9',
                            // strokeOpacity: 1,
                        });
                        // containerShape.draggable = true;
                        // containerShape.show();
                    } else {
                        containerShape.attr({
                            strokeWidth: undefined,
                            fill: undefined,
                            stroke: undefined,
                            // strokeOpacity: undefined,
                        });
                        // containerShape.draggable = false;
                        // containerShape.hide();
                    }
                }

                if (name === 'showTools') {
                    const group = item.getContainer();
                    const toolsGroup = group.findById("toolsGroup");

                    if (toolsGroup) {
                        const labelShape = group.find(element => element.get('name') === 'master-concept-node-name');
                        const fittingNameArray = labelShape.attr('text') ? labelShape.attr('text').split("\n") : [];
                        let y = GRAPH_SETTINGS.NODE_RADIUS + ((GRAPH_SETTINGS.MAIN_FONT_SIZE + 1) * fittingNameArray.length);

                        if (value === true) {
                            // console.log("SHOW", toolsGroup);

                            // Показываем инструменты
                            toolsGroup.show();

                            // Меняем отступ лейбла
                            labelShape.attr({ y });
                        } else {
                            // Прячем инструменты
                            toolsGroup.hide();

                            // Меняем отступ лейбла
                            y = y - 13;
                            labelShape.attr({ y });
                        }
                    }
                }

                // рабочий, но медленный
                // if (name === 'showTools__') {
                //     console.log(item.getContainer());
                //     const instItems = item.getContainer().findAll(element => element.get('name') === 'inst-path');
                //     console.log("🚀 ~ file: MasterConceptNode.js ~ line 290 ~ setState ~ anchorPoints", instItems);

                //     instItems.forEach(instItem => {
                //         if (value === true) {
                //             instItem.show();
                //         } else {
                //             console.log("HIDE", item, instItem)
                //             instItem.hide();
                //         }
                //     });
                // }

                // if (name === 'showToolTitle') {
                //     const titles = item.getContainer().findAll(element => element.get('name') === 'inst-title');

                //     if (this.currentTitle !== null && this.currentTitle !== value) {
                //         // titles[this.currentTitle].hide();
                //     }
                //     if (value !== false) {
                //         // titles[value].show();
                //         this.currentTitle = value;
                //     } else {
                //         titles.forEach((title, i) => {
                //             title.hide();
                //         });
                //     }
                // }
            },
            update: null,
        },
        'single-node',
    );
}
