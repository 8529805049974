import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Alert } from '@mui/material';

import { Loader } from "UI";

import { Symptoms, Result, Patient, RAS } from "features/pages";
import { Graph } from "features/graph";
// import { selectCurrentPage } from "features/pages/pagesSlice";
import { selectCurrentPage } from "features/main/mainSlice";

const ContentMemedComponent = () => {
    const currentPage = useSelector(selectCurrentPage);

    let ContentComponent = null;
    // let title = "";

    console.log(currentPage);
    switch (currentPage) {

        case "symptoms":
            ContentComponent = Symptoms;
            // title = TXT.symptoms;
            break;

        case "result":
            ContentComponent = Result;
            // title = TXT.result;
            break;

        case "graph":
            ContentComponent = Graph;
            // title = TXT.result;
            break;

        case "patient":
            ContentComponent = Patient;
            // title = TXT.result;
            break;

        case "RAS":
            ContentComponent = RAS;
            // title = TXT.result;
            break;

        default:
            ContentComponent = <Alert severity="info">Загрузка [ unknown module {currentPage}].</Alert>;
            break;
    }

    return <>

        {/* <Typography variant="h6" sx={{ ml: "1%" }}>{title}</Typography> */}
        <ContentComponent />


    </>;
}

export const Content = memo(ContentMemedComponent);
