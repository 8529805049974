
import red from '@mui/material/colors/red';
import purple from '@mui/material/colors/purple';
import blue from '@mui/material/colors/blue';
import teal from '@mui/material/colors/teal';
import orange from '@mui/material/colors/orange';
import indigo from '@mui/material/colors/indigo';
import cyan from '@mui/material/colors/cyan';
import green from '@mui/material/colors/green';

const colors = [
    red[500],
    blue[500],
    teal[500],
    purple[500],
    orange[500],
    cyan[500],
    green[500],
    indigo[500],
];

export default function (G6) {
    G6.registerNode(
        'concept-node',
        {
            draw(cfg, group) {
                let fanIn;

                if (group) {
                    let color = cfg?.data?.color || '#cccccc';

                    fanIn = group.addShape('circle', {
                        attrs: {
                            r: 20,
                            fill: color,
                            stroke: '#000000',
                            // strokeWidth: 50,
                            // opacity: 0.2,
                        },
                    });
                }

                return fanIn;
            },
        },
        'single-node',
    );
}
