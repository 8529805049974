export const tools = {
    "-1": { "id": -1, "icon": "link", "name": "Деление концепта", "color": "CD5C5C" },
    "-2": { "id": -2, "icon": "edit", "name": "Настройки концепта", "color": "CD5C5C" },
    "-3": { "id": -3, "icon": "edit", "name": "Описания", "color": "CD5C5C" },
    "-4": { "id": -4, "icon": "list-alt", "name": "Дополнительные свойства", "color": "CD5C5C" },
    "-5": { "id": -5, "icon": "th-list", "name": "Табличные данные", "color": "CD5C5C" },    
    "100": { "id": 681, "icon": "arrow-up", "name": "Generic Tool", "color": "CD5C5C" },
    "681": { "id": 681, "icon": "arrow-up", "name": "Parent (гипоним - Гипероним)", "color": "CD5C5C" },
    "680": { "id": 680, "icon": "arrow-down", "name": "Child (Гипероним - гипоним)", "color": "F08080" },
    "1609": { "id": 1609, "icon": "fa fa-quote-right", "name": "Эквивалентные концепты (Синонимия)", "color": "FA8072" },
    "1934": { "id": 1934, "icon": "fa fa-arrow-up", "name": "Parent (мероним - Холоним)", "color": "E9967A" },
    "1578": { "id": 1578, "icon": "fa fa-arrow-down", "name": "Child (Холоним - мероним)", "color": "FFA07A" },
    "1646": { "id": 1646, "icon": "resize-horizontal", "name": "Антонимия - концепты противоположные по смыслу", "color": "DC143C" },
    "1697": { "id": 1697, "icon": "fa fa-gears", "name": "Омонимия - концепты разные по значению, но одинаковые по звучанию", "color": "FF0000" },
    "1987": { "id": 1987, "icon": "fa fa-cut", "name": "Взаимоисключающие концепты", "color": "B22222" },
    "1720": { "id": 1720, "icon": "fa fa-share-alt-square", "name": "Групповая принадлежность", "color": "8B0000" },
    "1640": { "id": 1640, "icon": "fa fa-share-alt", "name": "Это есть сочетание признаков", "color": "FFC0CB" },
    "686": { "id": 686, "icon": "ok-sign", "name": "Характер", "color": "FFB6C1" },
    "1041": { "id": 1041, "icon": "resize-small", "name": "ПЭ - Уточняющий признак", "color": "FF69B4" },
    "1719": { "id": 1719, "icon": "fa fa-share-alt-square", "name": "Входит в перечень", "color": "FF1493" },
    "1999": { "id": 1999, "icon": "magnet", "name": "Условия при которых формируется группа", "color": "C71585" },
    "543": { "id": 543, "icon": "random", "name": "Латерализация", "color": "DB7093" },
    "724": { "id": 724, "icon": "resize-small", "name": "Binarization - Латерализация", "color": "ADFF2F" },
    "890": { "id": 890, "icon": "resize-small", "name": "Латерализация", "color": "7FFF00" },
    "1786": { "id": 1786, "icon": "resize-small", "name": "Binarization - Ориентация структурно-анатомического образования", "color": "7CFC00" },
    "1787": { "id": 1787, "icon": "resize-small", "name": "Binarization - Принадлежность структурно-анатомического признака", "color": "00FF00" },
    "1788": { "id": 1788, "icon": "resize-small", "name": "Binarization (технический) - Ориентация структурно-анатомического образования", "color": "32CD32" },
    "1789": { "id": 1789, "icon": "resize-small", "name": "Binarization (технический) - Принадлежность структурно-анатомического признака", "color": "98FB98" },
    "1693": { "id": 1693, "icon": "resize-small", "name": "Результат исследования - Патология", "color": "5db4cc" },
    "710": { "id": 710, "icon": "random", "name": "Возможные локализации структурного признака", "color": "00FA9A" },
    "711": { "id": 711, "icon": "resize-small", "name": "ПЭ - Структурные признаки", "color": "00FF7F" },
    "1944": { "id": 1944, "icon": "fa fa-cubes", "name": "Форма жизненного цикла", "color": "3CB371" },
    "1694": { "id": 1694, "icon": "resize-small", "name": "Результат исследования - Норма", "color": "2E8B57" },
    "773": { "id": 773, "icon": "th-list", "name": "Показатели", "color": "228B22" },
    "1673": { "id": 1673, "icon": "fa fa-table", "name": "Результаты исследования", "color": "008000" },
    "1762": { "id": 1762, "icon": "menu-hamburger", "name": "Название поля в протоколе исследования", "color": "006400" },
    "682": { "id": 682, "icon": "eye-open", "name": "Функции", "color": "9ACD32" },
    "1024": { "id": 1024, "icon": "heart-empty", "name": "Кровоснабжение", "color": "6B8E23" },
    "1857": { "id": 1857, "icon": "fa fa-tint", "name": "Лимфоотток", "color": "808000" },
    "1025": { "id": 1025, "icon": "flash", "name": "Иннервация", "color": "556B2F" },
    "1046": { "id": 1046, "icon": "headphones", "name": "Свойства", "color": "66CDAA" },
    "1880": { "id": 1880, "icon": "fa fa-skyatlas", "name": "Кодирование Белка или РНК", "color": "8FBC8F" },
    "1713": { "id": 1713, "icon": "fa fa-binoculars", "name": "Топография", "color": "20B2AA" },
    "1714": { "id": 1714, "icon": "fa fa-cubes", "name": "Пространственное отношение", "color": "008B8B" },
    "629": { "id": 629, "icon": "tasks", "name": "Заболеваемость (распространенность)", "color": "008080" },
    "1844": { "id": 1844, "icon": "magnet", "name": "Условия при которых распространенность заболевания имеет место быть", "color": "FFA07A" },
    "1727": { "id": 1727, "icon": "folder-open", "name": "Источник литературы", "color": "FF7F50" },
    "1985": { "id": 1985, "icon": "fa fa-plane", "name": "Регион", "color": "FF6347" },
    "631": { "id": 631, "icon": "tasks", "name": "Факторы риска", "color": "FF4500" },
    "805": { "id": 805, "icon": "ok-sign", "name": "Характер фактора риска", "color": "FF8C00" },
    "1838": { "id": 1838, "icon": "fa fa-flask", "name": "Уточнение показателя", "color": "FFA500" },
    "820": { "id": 820, "icon": "user", "name": "Персонализация отношения", "color": "FFD700" },
    "1834": { "id": 1834, "icon": "magnet", "name": "Условия при которых фактор риска имеет место быть", "color": "FFFF00" },
    "821": { "id": 821, "icon": "sort-by-attributes", "name": "Влияющие факторы", "color": "5ed3ff" },
    "1856": { "id": 1856, "icon": "fa fa-hand-o-right", "name": "Уточнение локализации патологического процесса", "color": "9ed563" },
    "1980": { "id": 1980, "icon": "time", "name": "Процесс в динамике", "color": "FAFAD2" },
    "1728": { "id": 1728, "icon": "folder-open", "name": "Источник литературы", "color": "FFEFD5" },
    "632": { "id": 632, "icon": "tasks", "name": "Этиология и патогенез", "color": "FFE4B5" },
    "844": { "id": 844, "icon": "ok-sign", "name": "Характер патогенного признака", "color": "FFDAB9" },
    "1839": { "id": 1839, "icon": "fa fa-flask", "name": "Уточнение показателя", "color": "EEE8AA" },
    "806": { "id": 806, "icon": "user", "name": "Персонализация отношения", "color": "F0E68C" },
    "1833": { "id": 1833, "icon": "magnet", "name": "Условия при которых этиологический фактор имеет место быть", "color": "BDB76B" },
    "845": { "id": 845, "icon": "time", "name": "Длительность воздействия патогенного фактора", "color": "E6E6FA" },
    "849": { "id": 849, "icon": "sort-by-attributes", "name": "Влияющие факторы", "color": "D8BFD8" },
    "1729": { "id": 1729, "icon": "folder-open", "name": "Источник литературы", "color": "DDA0DD" },
    "635": { "id": 635, "icon": "tasks", "name": "Клиническая картина и патогенез (причино-следственная связь)", "color": "EE82EE" },
    "825": { "id": 825, "icon": "ok-sign", "name": "Характер симптома", "color": "DA70D6" },
    "1791": { "id": 1791, "icon": "fa fa-flask", "name": "Уточнение показателя", "color": "FF00FF" },
    "799": { "id": 799, "icon": "user", "name": "Персонализация отношения", "color": "FF00FF" },
    "1832": { "id": 1832, "icon": "magnet", "name": "Условия при которых симптом проявляется", "color": "BA55D3" },
    "826": { "id": 826, "icon": "sort-by-attributes", "name": "Влияющие факторы", "color": "9370DB" },
    "827": { "id": 827, "icon": "time", "name": "Процесс в динамике", "color": "8A2BE2" },
    "1644": { "id": 1644, "icon": "fa fa-hand-o-right", "name": "Уточнение локализации патологического признака", "color": "9400D3" },
    "1730": { "id": 1730, "icon": "folder-open", "name": "Источник литературы", "color": "9932CC" },
    "792": { "id": 792, "icon": "tasks", "name": "Осложнения", "color": "8B008B" },
    "1731": { "id": 1731, "icon": "sort-by-attributes", "name": "Влияющие факторы", "color": "800080" },
    "1852": { "id": 1852, "icon": "magnet", "name": "Условия при которых осложнение проявляется", "color": "4B0082" },
    "1732": { "id": 1732, "icon": "user", "name": "Персонализация отношения", "color": "6A5ACD" },
    "1733": { "id": 1733, "icon": "folder-open", "name": "Источник литературы", "color": "483D8B" },
    "1877": { "id": 1877, "icon": "gift", "name": "Проявление мутации (возможные заболевания)", "color": "00FFFF" },
    "1993": { "id": 1993, "icon": "qrcode", "name": "Синтез изоформы белка", "color": "00FFFF" },
    "1884": { "id": 1884, "icon": "fa fa-joomla", "name": "Непереносимость лекарственных средств", "color": "E0FFFF" },
    "1885": { "id": 1885, "icon": "cutlery", "name": "Непереносимость продуктов питания и нутриентов", "color": "AFEEEE" },
    "1937": { "id": 1937, "icon": "fa fa-soundcloud", "name": "Недостаточность витаминов и микроэлементов", "color": "7FFFD4" },
    "1892": { "id": 1892, "icon": "fa fa-leaf", "name": "Изменение состояния кожи BEAUTY", "color": "40E0D0" },
    "1938": { "id": 1938, "icon": "fa fa-magic", "name": "Влияние на метаболизм", "color": "48D1CC" },
    "1939": { "id": 1939, "icon": "fa fa-spoon", "name": "Эффективность диеты", "color": "00CED1" },
    "1891": { "id": 1891, "icon": "fa fa-soccer-ball-o", "name": "Предрасположенности к физическим нагрузкам", "color": "5F9EA0" },
    "1896": { "id": 1896, "icon": "hand-up", "name": "Рекомендации", "color": "4682B4" },
    "675": { "id": 675, "icon": "tasks", "name": "Диагностика (методы исследования)", "color": "B0C4DE" },
    "1806": { "id": 1806, "icon": "fa fa-flask", "name": "Уточнить показатели", "color": "B0E0E6" },
    "1827": { "id": 1827, "icon": "magnet", "name": "Условия при которых диагностическая процедура применяется", "color": "ADD8E6" },
    "1873": { "id": 1873, "icon": "ok-sign", "name": "Объект или характер исследования", "color": "87CEEB" },
    "1981": { "id": 1981, "icon": "fa fa-hand-o-right", "name": "Уточнение области применения диагностического метода исследования", "color": "87CEFA" },
    "1739": { "id": 1739, "icon": "user", "name": "Персонализация отношения", "color": "00BFFF" },
    "1836": { "id": 1836, "icon": "fa fa-won", "name": "Уровень убедительности рекомендаций", "color": "1E90FF" },
    "1735": { "id": 1735, "icon": "folder-open", "name": "Источник литературы", "color": "6495ED" },
    "1779": { "id": 1779, "icon": "fa fa-thumbs-o-down", "name": "Показания и противопоказания по части питания", "color": "7B68EE" },
    "1783": { "id": 1783, "icon": "tasks", "name": "Характеристика продукта", "color": "4169E1" },
    "1780": { "id": 1780, "icon": "magnet", "name": "Условия приготовления пищи", "color": "0000FF" },
    "1781": { "id": 1781, "icon": "user", "name": "Персонализация отношения", "color": "0000CD" },
    "1782": { "id": 1782, "icon": "book", "name": "Источник литературы", "color": "00008B" },
    "1810": { "id": 1810, "icon": "fa fa-bar-chart", "name": "Оптимизация физиологических параметров", "color": "000080" },
    "676": { "id": 676, "icon": "tasks", "name": "Дифференциальная диагностика (заболевания, имеющие схожую клиническую картину)", "color": "191970" },
    "1741": { "id": 1741, "icon": "user", "name": "Персонализация отношения", "color": "FFF8DC" },
    "1858": { "id": 1858, "icon": "magnet", "name": "Условия для дифференциальной диагностики", "color": "FFEBCD" },
    "1734": { "id": 1734, "icon": "folder-open", "name": "Источник литературы", "color": "FFE4C4" },
    "677": { "id": 677, "icon": "fa fa-plus-square", "name": "Схема лечения, питания, профилактики и реабилитации (рекомендации)", "color": "FFDEAD" },
    "1823": { "id": 1823, "icon": "magnet", "name": "Условия при которых схема лечения применяется", "color": "00b652" },
    "1828": { "id": 1828, "icon": "fa fa-tachometer", "name": "Длительность приема", "color": "DEB887" },
    "1864": { "id": 1864, "icon": "asterisk", "name": "Кратность приема", "color": "D2B48C" },
    "1808": { "id": 1808, "icon": "fa fa-won", "name": "Уровень убедительности рекомендаций", "color": "BC8F8F" },
    "1991": { "id": 1991, "icon": "screenshot", "name": "Цель лечения, профилактики и реабилитации", "color": "F4A460" },
    "1652": { "id": 1652, "icon": "fa fa-medkit", "name": "Фармакотерапия или рацион питания", "color": "DAA520" },
    "1809": { "id": 1809, "icon": "magnet", "name": "Условия при которых терапия применяется", "color": "B8860B" },
    "1654": { "id": 1654, "icon": "fa fa-toggle-on", "name": "Лекарственная форма", "color": "CD853F" },
    "1653": { "id": 1653, "icon": "cutlery", "name": "Способ применения", "color": "D2691E" },
    "1655": { "id": 1655, "icon": "fa fa-sort-amount-asc", "name": "Доза/количество", "color": "8B4513" },
    "1656": { "id": 1656, "icon": "asterisk", "name": "Кратность приема", "color": "A0522D" },
    "1657": { "id": 1657, "icon": "fa fa-tachometer", "name": "Длительность приема", "color": "A52A2A" },
    "1929": { "id": 1929, "icon": "fa fa-calendar", "name": "Календарь приема (время, расписание, график)", "color": "800000" },
    "1703": { "id": 1703, "icon": "fa fa-exclamation-triangle", "name": "Эффективность и безопасность", "color": "CD5C5C" },
    "1807": { "id": 1807, "icon": "fa fa-won", "name": "Уровень убедительности рекомендаций", "color": "F08080" },
    "678": { "id": 678, "icon": "tasks", "name": "Профилактика (факторы протекции)", "color": "FA8072" },
    "1738": { "id": 1738, "icon": "user", "name": "Персонализация отношения", "color": "E9967A" },
    "1736": { "id": 1736, "icon": "book", "name": "Источник литературы", "color": "FFA07A" },
    "679": { "id": 679, "icon": "tasks", "name": "Прогноз и исход заболевания", "color": "DC143C" },
    "1740": { "id": 1740, "icon": "user", "name": "Персонализация отношения", "color": "FF0000" },
    "1737": { "id": 1737, "icon": "folder-open", "name": "Источник литературы", "color": "B22222" },
    "1022": { "id": 1022, "icon": "transfer", "name": "Межлекарственные взаимодействия", "color": "8B0000" },
    "1307": { "id": 1307, "icon": "magnet", "name": "Условия, при которых возникает взаимодействие", "color": "FFC0CB" },
    "1308": { "id": 1308, "icon": "fire", "name": "Клинический результат взаимодействия ЛС", "color": "FFB6C1" },
    "1277": { "id": 1277, "icon": "ok-sign", "name": "Характер взаимодействия ЛС", "color": "FF69B4" },
    "1309": { "id": 1309, "icon": "user", "name": "Персонализация отношения", "color": "FF1493" },
    "1310": { "id": 1310, "icon": "book", "name": "Источник литературы", "color": "C71585" },
    "1266": { "id": 1266, "icon": "ban-circle", "name": "Противопоказания и ограничения к применению ЛС", "color": "DB7093" },
    "1343": { "id": 1343, "icon": "magnet", "name": "Условия, при которых допускается ограничение", "color": "ADFF2F" },
    "1345": { "id": 1345, "icon": "ok-sign", "name": "Характер противопоказания ЛС", "color": "7FFF00" },
    "1344": { "id": 1344, "icon": "user", "name": "Персонализация отношения", "color": "7CFC00" },
    "1381": { "id": 1381, "icon": "fire", "name": "Клинический результат воздействия ЛС", "color": "00FF00" },
    "1342": { "id": 1342, "icon": "folder-open", "name": "Источник литературы", "color": "32CD32" },
    "1519": { "id": 1519, "icon": "apple", "name": "Побочные эффекты", "color": "98FB98" },
    "1575": { "id": 1575, "icon": "folder-open", "name": "Источник литературы", "color": "5db4cc" },
    "1699": { "id": 1699, "icon": "record", "name": "Таргетная фармакотерапия", "color": "00FA9A" },
    "1701": { "id": 1701, "icon": "magnet", "name": "Условия влияния", "color": "00FF7F" },
    "1702": { "id": 1702, "icon": "plus", "name": "Уточнение патологии", "color": "3CB371" },
    "1700": { "id": 1700, "icon": "folder-open", "name": "Источник литературы", "color": "2E8B57" },
    "1295": { "id": 1295, "icon": "pushpin", "name": "Способ введения ЛС", "color": "228B22" },
    "1764": { "id": 1764, "icon": "sort-by-attributes", "name": "Режим дозирования", "color": "008000" },
    "1848": { "id": 1848, "icon": "cutlery", "name": "Способ применения", "color": "006400" },
    "1849": { "id": 1849, "icon": "asterisk", "name": "Кратность приема", "color": "9ACD32" },
    "1850": { "id": 1850, "icon": "fa fa-tachometer", "name": "Длительность приема", "color": "6B8E23" },
    "1851": { "id": 1851, "icon": "fa fa-toggle-on", "name": "Лекарственная форма", "color": "808000" },
    "1982": { "id": 1982, "icon": "fa fa-calendar", "name": "Календарь приема (время, расписание, график)", "color": "556B2F" },
    "1765": { "id": 1765, "icon": "magnet", "name": "Условия, при которых допускается данный режим дозирования", "color": "66CDAA" },
    "1768": { "id": 1768, "icon": "fa fa-soccer-ball-o", "name": "Нутриенты", "color": "8FBC8F" },
    "1771": { "id": 1771, "icon": "fa fa-pie-chart", "name": "Код продукта, использованного для вычисления оставшихся незаполненными данных.", "color": "20B2AA" },
    "1772": { "id": 1772, "icon": "fa fa-mortar-board", "name": "Тип источника данных", "color": "008B8B" },
    "1773": { "id": 1773, "icon": "fa fa-lightbulb-o", "name": "Способ определения значения нутриена", "color": "008080" },
    "1774": { "id": 1774, "icon": "book", "name": "Источник литературы", "color": "FFA07A" },
    "1855": { "id": 1855, "icon": "fa fa-calendar", "name": "Время обследования или приема", "color": "FF7F50" },
    "1931": { "id": 1931, "icon": "fa fa-th", "name": "Состав продукта питания", "color": "FF6347" },
    "1932": { "id": 1932, "icon": "magnet", "name": "Условия приготовления пищи", "color": "FF4500" },
    "1933": { "id": 1933, "icon": "tasks", "name": "Характеристика продукта", "color": "FF8C00" },
    "1754": { "id": 1754, "icon": "fa fa-group", "name": "Авторы объекта", "color": "FFA500" },
    "1710": { "id": 1710, "icon": "fa fa-joomla", "name": "Показать все связи", "color": "FFD700" },
    "1689": { "id": 1689, "icon": "fa fa-chevron-up", "name": "Parent_Т (Техническая связь для группировки элементов)", "color": "FFFF00" },
    "1459": { "id": 1459, "icon": "fa fa-chevron-down", "name": "Переход_Т (Техническая связь для группировки элементов)", "color": "5ed3ff" },
    "1711": { "id": 1711, "icon": "ban-circle", "name": "Технический инструмент _Ограничения к применению и меры предосторожности", "color": "9ed563" },
    "1712": { "id": 1712, "icon": "folder-open", "name": "Источник литературы", "color": "FAFAD2" },
    "1835": { "id": 1835, "icon": "folder-open", "name": "Источник литературы", "color": "FFEFD5" },
    "1888": { "id": 1888, "icon": "fa fa-gift", "name": "Доступ к функционалу приложений и сервисов", "color": "FFE4B5" },
    "1870": { "id": 1870, "icon": "fa fa-th-list", "name": "Доступные Чекеры и Модули", "color": "FFDAB9" },
    "1861": { "id": 1861, "icon": "fa fa-code-fork", "name": "Доступные Тригеры/Страницы", "color": "EEE8AA" },
    "1865": { "id": 1865, "icon": "fa fa-magnet", "name": "Условия срабатывания тригера", "color": "F0E68C" },
    "2004": { "id": 2004, "icon": "download-alt", "name": "Запись данных в раздел или использовать инструмент онтологии для загрузки", "color": "BDB76B" },
    "1859": { "id": 1859, "icon": "fa fa-puzzle-piece", "name": "Сенсор", "color": "E6E6FA" },
    "1862": { "id": 1862, "icon": "fa fa-magnet", "name": "Условия срабатывания Сенсора и Записи данных", "color": "D8BFD8" },
    "1951": { "id": 1951, "icon": "fa fa-database", "name": "Доступные Разделы для поиска и идентификации концептов по названию, плечам и классификаторам", "color": "DDA0DD" },
    "1950": { "id": 1950, "icon": "fa fa-stack-overflow", "name": "Доступные Парсеры с указанием раздела в которым проводиться идентификация концептов", "color": "EE82EE" },
    "1971": { "id": 1971, "icon": "headphones", "name": "Доступные Разделы для поиска и идентификации концептов в том числе по сателитным полям", "color": "DA70D6" },
    "1953": { "id": 1953, "icon": "fa fa-file-code-o", "name": "Использовать регулярные выражения", "color": "FF00FF" },
    "1930": { "id": 1930, "icon": "download-alt", "name": "Запись данных в раздел или использовать инструмент онтологии для загрузки", "color": "FF00FF" },
    "1941": { "id": 1941, "icon": "fa fa-sitemap", "name": "Переход (Коллектор)", "color": "BA55D3" },
    "1942": { "id": 1942, "icon": "magnet", "name": "Условия при которых срабатывает коллектор", "color": "9370DB" },
    "1996": { "id": 1996, "icon": "fa fa-taxi", "name": "Уровень доступа к концепту", "color": "8A2BE2" },
    "1039": { "id": 1039, "icon": "flash", "name": "Область или орган иннервации", "color": "9400D3" },
    "1123": { "id": 1123, "icon": "оо", "name": "Параметры органа", "color": "9932CC" },
    "1967": { "id": 1967, "icon": "fa fa-long-arrow-right", "name": "Направление передачи сигнала (адресат)", "color": "8B008B" },
    "1968": { "id": 1968, "icon": "fa fa-creative-commons", "name": "Транзитный путь/пучок/тракт", "color": "800080" },
    "1969": { "id": 1969, "icon": "fa fa-creative-commons", "name": "Связи внутри пучка/тракта", "color": "4B0082" },
    "1917": { "id": 1917, "icon": "transfer", "name": "_Пути передачи сигнала_ на удаление", "color": "6A5ACD" },
    "1935": { "id": 1935, "icon": "fa fa-sitemap", "name": "Нейроны (цитоархитектоника)", "color": "483D8B" },
    "1918": { "id": 1918, "icon": "qrcode", "name": "Шаблон функционально-архитектурной единицы", "color": "00FFFF" },
    "1064": { "id": 1064, "icon": "qrcode", "name": "Является предшественником", "color": "00FFFF" },
    "719": { "id": 719, "icon": "random", "name": "Возможные функциональные признаки", "color": "E0FFFF" },
    "720": { "id": 720, "icon": "resize-small", "name": "ПЭ - Функциональные признаки", "color": "AFEEEE" },
    "1043": { "id": 1043, "icon": "resize-vertical", "name": "Классификация функционального признака", "color": "7FFFD4" },
    "1044": { "id": 1044, "icon": "resize-small", "name": "ПЭ - Классификация функционального признака", "color": "40E0D0" },
    "685": { "id": 685, "icon": "random", "name": "Возможные локализации патологического признака", "color": "48D1CC" },
    "703": { "id": 703, "icon": "resize-small", "name": "ПЭ - Патологические признаки", "color": "00CED1" },
    "687": { "id": 687, "icon": "resize-vertical", "name": "Классификация патологического признака", "color": "5F9EA0" },
    "863": { "id": 863, "icon": "resize-small", "name": "ПЭ - Классификация патологического признака", "color": "4682B4" },
    "1704": { "id": 1704, "icon": "fa fa-odnoklassniki", "name": "Модель пациента", "color": "B0C4DE" },
    "1721": { "id": 1721, "icon": "fa fa-female", "name": "Связь с манекеном", "color": "B0E0E6" },
    "712": { "id": 712, "icon": "random", "name": "Возможные параметрические признаки", "color": "ADD8E6" },
    "717": { "id": 717, "icon": "resize-small", "name": "ПЭ - Параметрические признаки", "color": "87CEEB" },
    "932": { "id": 932, "icon": "resize-vertical", "name": "Классификации параметрических признаков", "color": "87CEFA" },
    "933": { "id": 933, "icon": "resize-small", "name": "ПЭ - Классификация параметрического признака", "color": "00BFFF" },
    "1866": { "id": 1866, "icon": "fa fa-flask", "name": "Метод исследования (физико-химические условия исследования)", "color": "1E90FF" },
    "1869": { "id": 1869, "icon": "fa fa-compress", "name": "ПЭ - Отклонение показателя", "color": "6495ED" },
    "1868": { "id": 1868, "icon": "fa fa-eyedropper", "name": "Исследуемый биоматериал", "color": "7B68EE" },
    "1830": { "id": 1830, "icon": "resize-small", "name": "ПЭ - Объекты наблюдения", "color": "4169E1" },
    "767": { "id": 767, "icon": "random", "name": "Возможные внешние патогенные факторы", "color": "0000FF" },
    "768": { "id": 768, "icon": "resize-small", "name": "ПЭ - Внешние патогенные факторы", "color": "0000CD" },
    "856": { "id": 856, "icon": "random", "name": "Факторы в анамнезе", "color": "00008B" },
    "857": { "id": 857, "icon": "resize-small", "name": "ПЭ - Анамнезтические факторы", "color": "000080" },
    "1842": { "id": 1842, "icon": "fa fa-cube", "name": "Материал", "color": "191970" },
    "1631": { "id": 1631, "icon": "arrow-right", "name": "ГН -&gt; ДВ1, ДВ2 и т.д.", "color": "FFF8DC" },
    "1387": { "id": 1387, "icon": "arrow-right", "name": "ДВк -> ДВ1, ДВ2 и т.д.", "color": "FFEBCD" },
    "899": { "id": 899, "icon": "resize-horizontal", "name": "Фармакологическая группа (ФГ)", "color": "FFE4C4" },
    "1628": { "id": 1628, "icon": "resize-horizontal", "name": "Химическая группа (АТХ)", "color": "FFDEAD" },
    "1392": { "id": 1392, "icon": "cloud-upload", "name": "Тип регуляции метаболизма (Ферменты метаболизма)", "color": "00b652" },
    "1086": { "id": 1086, "icon": "resize-horizontal", "name": "Фармакологические действие (ФД)", "color": "DEB887" },
    "966": { "id": 966, "icon": "resize-horizontal", "name": "Торговые названия препаратов (ТН)", "color": "D2B48C" },
    "902": { "id": 902, "icon": "resize-horizontal", "name": "Нозологии в системе UMKB", "color": "BC8F8F" },
    "901": { "id": 901, "icon": "resize-horizontal", "name": "Нозологическая классификация (МКБ-10)", "color": "F4A460" },
    "1351": { "id": 1351, "icon": "resize-horizontal", "name": "Лекарственные формы", "color": "DAA520" },
    "1784": { "id": 1784, "icon": "education", "name": "Разрешение на использование ДВ в регионе", "color": "B8860B" },
    "1831": { "id": 1831, "icon": "resize-horizontal", "name": "Фармакологическая группа (ФГ)", "color": "CD853F" },
    "894": { "id": 894, "icon": "resize-horizontal", "name": "Действующее вещество (ДВ)", "color": "D2691E" },
    "1094": { "id": 1094, "icon": "resize-horizontal", "name": "Фармакологические препараты (ФП)", "color": "8B4513" },
    "1097": { "id": 1097, "icon": "resize-horizontal", "name": "Анатомо-терапевтическо-химическая классификация (АТХ)", "color": "A0522D" },
    "1352": { "id": 1352, "icon": "resize-horizontal", "name": "Лекарственная форма", "color": "A52A2A" },
    "1388": { "id": 1388, "icon": "arrow-right", "name": "Субстанция для приготовления ЛС", "color": "800000" },
    "1767": { "id": 1767, "icon": "th-list", "name": "Состав_ Активные ингредиенты", "color": "CD5C5C" },
    "1785": { "id": 1785, "icon": "education", "name": "Разрешение на использование ТН в регионе", "color": "F08080" },
    "1099": { "id": 1099, "icon": "resize-horizontal", "name": "Анатомо-терапевтическо-химическая классификация (АТХ)", "color": "FA8072" },
    "1095": { "id": 1095, "icon": "resize-horizontal", "name": "Фармакологические группы (ФГ)", "color": "E9967A" },
    "1093": { "id": 1093, "icon": "resize-horizontal", "name": "Действующее вещество (ДВ)", "color": "FFA07A" },
    "1346": { "id": 1346, "icon": "resize-horizontal", "name": "Торговое название (ТН)", "color": "DC143C" },
    "1114": { "id": 1114, "icon": "resize-horizontal", "name": "Лекарственная форма (ЛФ)", "color": "FF0000" },
    "1574": { "id": 1574, "icon": "resize-horizontal", "name": "Упаковка лекарственного средства (УП)", "color": "B22222" },
    "1717": { "id": 1717, "icon": "fa fa-flask", "name": "Производитель ЛС", "color": "8B0000" },
    "1718": { "id": 1718, "icon": "fa fa-diamond", "name": "Упаковщик ЛС", "color": "FFC0CB" },
    "1526": { "id": 1526, "icon": "arrow-right", "name": "Страна регистрации ЛС", "color": "FFB6C1" },
    "967": { "id": 967, "icon": "resize-horizontal", "name": "Действующие вещества (ДВ)", "color": "FF69B4" },
    "1332": { "id": 1332, "icon": "resize-horizontal", "name": "Действующие вещества (ДВ)", "color": "FF1493" },
    "1347": { "id": 1347, "icon": "resize-horizontal", "name": "Торговые названия", "color": "C71585" },
    "969": { "id": 969, "icon": "resize-horizontal", "name": "Фармакологические препараты (ФП)", "color": "DB7093" },
    "1265": { "id": 1265, "icon": "resize-horizontal", "name": "Действующее вещество", "color": "ADFF2F" },
    "1409": { "id": 1409, "icon": "random", "name": "Ферменты метаболизма", "color": "7FFF00" },
    "1410": { "id": 1410, "icon": "cloud", "name": "ПЭ - Ферменты метаболизма", "color": "7CFC00" },
    "1390": { "id": 1390, "icon": "cloud", "name": "Типы взаимодействия с ферментом", "color": "00FF00" },
    "1391": { "id": 1391, "icon": "cloud-upload", "name": "Вещества, влияющие на фермент метаболизма", "color": "32CD32" },
    "1395": { "id": 1395, "icon": "folder-open", "name": "Источник литературы", "color": "98FB98" },
    "1406": { "id": 1406, "icon": "resize-horizontal", "name": "Вспомогательные вещества", "color": "5db4cc" },
    "1291": { "id": 1291, "icon": "cutlery", "name": "Способ применения", "color": "00FA9A" },
    "1742": { "id": 1742, "icon": "resize-horizontal", "name": "ФП инструмент для контроля", "color": "00FF7F" },
    "1576": { "id": 1576, "icon": "resize-horizontal", "name": "ФП инструмент для контроля", "color": "3CB371" },
    "1327": { "id": 1327, "icon": "random", "name": "Уточнение способа применения", "color": "2E8B57" },
    "1328": { "id": 1328, "icon": "resize-small", "name": "ПЭ - Способы применения ЛС", "color": "228B22" },
    "1348": { "id": 1348, "icon": "random", "name": "Изменение функции", "color": "008000" },
    "1349": { "id": 1349, "icon": "resize-small", "name": "ПЭ - Изменение функции", "color": "006400" },
    "1329": { "id": 1329, "icon": "resize-vertical", "name": "Специфические особенности", "color": "9ACD32" },
    "1330": { "id": 1330, "icon": "resize-small", "name": "ПЭ - Специфические особенности", "color": "6B8E23" },
    "1334": { "id": 1334, "icon": "resize-small", "name": "ПЭ - эффект ФД", "color": "808000" },
    "1292": { "id": 1292, "icon": "cutlery", "name": "Лекарственная форма", "color": "556B2F" },
    "1619": { "id": 1619, "icon": "arrow-right", "name": "Владелец регистрационного удостоверения (РУ)", "color": "66CDAA" },
    "1353": { "id": 1353, "icon": "resize-horizontal", "name": "Дозировка", "color": "8FBC8F" },
    "1354": { "id": 1354, "icon": "resize-horizontal", "name": "связь с ДВ", "color": "20B2AA" },
    "1997": { "id": 1997, "icon": "resize-horizontal", "name": "Химическая группа (АТХ)", "color": "008B8B" },
    "1878": { "id": 1878, "icon": "asterisk", "name": "Объекты книги", "color": "008080" },
    "1945": { "id": 1945, "icon": "check", "name": "Условия срабатывания и исключения", "color": "FFA07A" },
    "1909": { "id": 1909, "icon": "fa fa-ge", "name": "Гены", "color": "FF7F50" },
    "1875": { "id": 1875, "icon": "fa fa-optin-monster", "name": "Мутации", "color": "FF6347" },
    "1908": { "id": 1908, "icon": "fa fa-soccer-ball-o", "name": "Позиция (Хромосома, Плечо, Сиквенс)", "color": "FF4500" },
    "1907": { "id": 1907, "icon": "fa fa-sliders", "name": "Мутации", "color": "FF8C00" },
    "1910": { "id": 1910, "icon": "fa fa-pie-chart", "name": "Тип мутации", "color": "FFA500" },
    "1906": { "id": 1906, "icon": "fa fa-empire", "name": "Локализация (Ген или Хромосома)", "color": "FFD700" },
    "1911": { "id": 1911, "icon": "fa fa-mars-stroke", "name": "Гаплотипы - аллели вариаций", "color": "FFFF00" },
    "1921": { "id": 1921, "icon": "fa fa-venus-mars", "name": "Генотипы - бинарные комбинации гаплотипов", "color": "5ed3ff" },
    "1887": { "id": 1887, "icon": "fa fa-opencart", "name": "Варианты мутаций в аллелях", "color": "9ed563" },
    "1920": { "id": 1920, "icon": "camera", "name": "Проявление мутации", "color": "FAFAD2" },
    "1901": { "id": 1901, "icon": "tag", "name": "Маркировка мутации по аллельям", "color": "FFEFD5" },
    "1900": { "id": 1900, "icon": "tags", "name": "Маркеры мутации", "color": "FFE4B5" },
    "1897": { "id": 1897, "icon": "fa fa-globe", "name": "Регион возникновения", "color": "FFDAB9" },
    "1898": { "id": 1898, "icon": "fa fa-users", "name": "Ареал распространения гаплогруппы", "color": "EEE8AA" },
    "1899": { "id": 1899, "icon": "fa fa-user", "name": "Известные носители гаплогруппы", "color": "F0E68C" },
    "1998": { "id": 1998, "icon": "qrcode", "name": "Кодирует аминокислоту", "color": "BDB76B" },
    "1992": { "id": 1992, "icon": "fa fa-lastfm", "name": "Мутация гена", "color": "E6E6FA" },
    "1876": { "id": 1876, "icon": "fa fa-lastfm", "name": "Мутация гена (старая принцип моделирования, после слияния инструмент будет удален)", "color": "D8BFD8" },
    "1074": { "id": 1074, "icon": "random", "name": "Возможные химические соединения", "color": "DDA0DD" },
    "1075": { "id": 1075, "icon": "resize-small", "name": "ПЭ - Химические соединения", "color": "EE82EE" },
    "1894": { "id": 1894, "icon": "cutlery", "name": "Продукты с высоким содержанием", "color": "DA70D6" },
    "1843": { "id": 1843, "icon": "fa fa-cubes", "name": "Химический элемент", "color": "FF00FF" },
    "1769": { "id": 1769, "icon": "resize-horizontal", "name": "Продукты питания", "color": "FF00FF" },
    "1973": { "id": 1973, "icon": "fa fa-barcode", "name": "Образцы продуктов питания с указанным производителем и штрихкодом", "color": "BA55D3" },
    "1775": { "id": 1775, "icon": "fa fa-balance-scale", "name": "Эквивалентность с FNDDS", "color": "9370DB" },
    "1770": { "id": 1770, "icon": "resize-horizontal", "name": "Групповая принадлежность", "color": "8A2BE2" },
    "1972": { "id": 1972, "icon": "tree-deciduous", "name": "Привязка продукта питания к ключевому концепту в классификаторе", "color": "9400D3" },
    "1974": { "id": 1974, "icon": "fa fa-gift", "name": "Производитель Продукта питания", "color": "9932CC" },
    "1990": { "id": 1990, "icon": "fa fa-area-chart", "name": "Энергетическая ценность", "color": "8B008B" },
    "1890": { "id": 1890, "icon": "cutlery", "name": "Продукты с высоким содержанием", "color": "800080" },
    "1776": { "id": 1776, "icon": "heart", "name": "Нозологии", "color": "4B0082" },
    "1777": { "id": 1777, "icon": "user", "name": "Персонализация отношения", "color": "6A5ACD" },
    "1778": { "id": 1778, "icon": "book", "name": "Источник литературы", "color": "483D8B" },
    "1893": { "id": 1893, "icon": "cutlery", "name": "Режим питания", "color": "00FFFF" },
    "1954": { "id": 1954, "icon": "fa fa-code-fork", "name": "Доступные сценарии с правилами", "color": "00FFFF" },
    "1695": { "id": 1695, "icon": "fa fa-ellipsis-v", "name": "Если так ...", "color": "E0FFFF" },
    "1696": { "id": 1696, "icon": "fa fa-hand-o-right", "name": "То так ...", "color": "AFEEEE" },
    "1716": { "id": 1716, "icon": "link", "name": "Формирование связи", "color": "7FFFD4" },
    "1793": { "id": 1793, "icon": "fa fa-sitemap", "name": "Полисемия", "color": "40E0D0" },
    "1792": { "id": 1792, "icon": "equalizer", "name": "Грамматические категории", "color": "48D1CC" },
    "1632": { "id": 1632, "icon": "fa fa-share", "name": "Производные единицы измерения", "color": "00CED1" },
    "1636": { "id": 1636, "icon": "fa fa-mail-reply", "name": "Первообразная единицы измерения", "color": "5F9EA0" },
    "1637": { "id": 1637, "icon": "fa fa-superscript", "name": "Математическое выражение", "color": "4682B4" },
    "1905": { "id": 1905, "icon": "user", "name": "Частота обращений к данному специалисту", "color": "B0C4DE" },
    "1960": { "id": 1960, "icon": "magnet", "name": "Условия при которых обращаются", "color": "B0E0E6" },
    "1904": { "id": 1904, "icon": "fa fa-heartbeat", "name": "Заболевания входящие в профиль данного специалиста", "color": "ADD8E6" },
    "1958": { "id": 1958, "icon": "magnet", "name": "Условия при которых заболевания входят в профиль", "color": "87CEEB" },
    "1965": { "id": 1965, "icon": "user", "name": "Персонализация отношения", "color": "87CEFA" },
    "1946": { "id": 1946, "icon": "fa fa-user-plus", "name": "Жалобы по профилю специалиста", "color": "00BFFF" },
    "1959": { "id": 1959, "icon": "magnet", "name": "Условия при которых", "color": "1E90FF" },
    "1964": { "id": 1964, "icon": "user", "name": "Персонализация отношения", "color": "6495ED" },
    "1947": { "id": 1947, "icon": "fa fa-user-times", "name": "Анамнез по профилю специалиста", "color": "7B68EE" },
    "1957": { "id": 1957, "icon": "magnet", "name": "Условия при которых опрос применяется", "color": "4169E1" },
    "1963": { "id": 1963, "icon": "user", "name": "Персонализация отношения", "color": "0000FF" },
    "1949": { "id": 1949, "icon": "fa fa-flask", "name": "Лабораторные методы исследования", "color": "0000CD" },
    "1955": { "id": 1955, "icon": "magnet", "name": "Условия при которых лабораторный метод применяется", "color": "00008B" },
    "1962": { "id": 1962, "icon": "user", "name": "Персонализация отношения", "color": "000080" },
    "1948": { "id": 1948, "icon": "fa fa-laptop", "name": "Инструментальные методы исследования", "color": "191970" },
    "1956": { "id": 1956, "icon": "magnet", "name": "Условия при которых инструментальный метод применяется", "color": "FFF8DC" },
    "1961": { "id": 1961, "icon": "user", "name": "Персонализация отношения", "color": "FFEBCD" },
    "1601": { "id": 1601, "icon": "-", "name": "ПЭ - концепт", "color": "FFE4C4" },
    "1874": { "id": 1874, "icon": "fa fa-map-o", "name": "Дорожная карта", "color": "FFDEAD" },
    "1053": { "id": 1053, "icon": "random", "name": "Возможные свойства", "color": "00b652" },
    "1054": { "id": 1054, "icon": "resize-small", "name": "ПЭ - Свойства", "color": "DEB887" },
    "1115": { "id": 1115, "icon": "random", "name": "Возможные внешние факторы", "color": "D2B48C" },
    "1117": { "id": 1117, "icon": "resize-small", "name": "ПЭ - Внешние факторы", "color": "BC8F8F" },
    "1840": { "id": 1840, "icon": "fa fa-th-list", "name": "Поле таблицы или название рубрики в документе", "color": "F4A460" },
    "1994": { "id": 1994, "icon": "fa fa-sitemap", "name": "Children", "color": "DAA520" },
    "1100": { "id": 1100, "icon": "resize-horizontal", "name": "Торговое название препаратов МКБ-10", "color": "B8860B" },
    "1101": { "id": 1101, "icon": "resize-horizontal", "name": "Фармакологические препараты МКБ-10", "color": "CD853F" },
    "968": { "id": 968, "icon": "resize-horizontal", "name": "Действующее вещество", "color": "D2691E" },
    "1804": { "id": 1804, "icon": "fa fa-graduation-cap", "name": "Профессиональные медицинские организации разработчики", "color": "8B4513" },
    "1805": { "id": 1805, "icon": "option-vertical", "name": "Кодирование по МКБ-10", "color": "A0522D" },
    "1826": { "id": 1826, "icon": "fa fa-flickr", "name": "Эквивалентная нозология в UMKB", "color": "A52A2A" },
    "1825": { "id": 1825, "icon": "align-left", "name": "Классификация", "color": "800000" },
    "1903": { "id": 1903, "icon": "fa fa-share-alt", "name": "Входит в перечень", "color": "CD5C5C" },
    "1743": { "id": 1743, "icon": "fa fa-medkit", "name": "Фармакотерапия", "color": "F08080" },
    "1744": { "id": 1744, "icon": "fa fa-toggle-on", "name": "Лекарственная форма", "color": "FA8072" },
    "1745": { "id": 1745, "icon": "cutlery", "name": "Способ применения", "color": "E9967A" },
    "1746": { "id": 1746, "icon": "fa fa-sort-amount-asc", "name": "Дозировки", "color": "FFA07A" },
    "1747": { "id": 1747, "icon": "asterisk", "name": "Кратность приема", "color": "DC143C" },
    "1748": { "id": 1748, "icon": "fa fa-tachometer", "name": "Длительность приема", "color": "FF0000" },
    "1752": { "id": 1752, "icon": "fa fa-sort-numeric-asc", "name": "Параметрические показатели", "color": "B22222" },
    "1757": { "id": 1757, "icon": "th", "name": "События", "color": "8B0000" },
    "1698": { "id": 1698, "icon": "fa fa-code-fork", "name": "Атрибуты ключевых концептов", "color": "FFC0CB" },
    "1841": { "id": 1841, "icon": "fa fa-desktop", "name": "Авторизованные устройства", "color": "FFB6C1" },
    "1763": { "id": 1763, "icon": "fa fa-key", "name": "Лицензионные ключи (доступ к сервису)", "color": "FF69B4" },
    "1837": { "id": 1837, "icon": "fa fa-graduation-cap", "name": "Права доступа к шлюзам (LIB, TOOL, FIELD, LEVEL, BEL)", "color": "FF1493" },
    "1725": { "id": 1725, "icon": "fa fa-users", "name": "Роли", "color": "C71585" },
    "1912": { "id": 1912, "icon": "fa fa-graduation-cap", "name": "Образование", "color": "DB7093" },
    "1913": { "id": 1913, "icon": "fa fa-facebook-official", "name": "Факультет", "color": "ADFF2F" },
    "1914": { "id": 1914, "icon": "fa fa-university", "name": "Место прохождения обучения", "color": "7FFF00" },
    "1936": { "id": 1936, "icon": "education", "name": "Специализация", "color": "7CFC00" },
    "1886": { "id": 1886, "icon": "education", "name": "Специальность присвоенная в рамках сертификации", "color": "00FF00" },
    "1915": { "id": 1915, "icon": "fa fa-bank", "name": "Место сертификации", "color": "32CD32" },
    "1940": { "id": 1940, "icon": "fa fa-book", "name": "Научная деятельность", "color": "98FB98" },
    "1943": { "id": 1943, "icon": "book", "name": "Комплексная тема", "color": "5db4cc" },
    "1766": { "id": 1766, "icon": "fa fa-institution", "name": "Место работы (ЛПУ)", "color": "00FA9A" },
    "1922": { "id": 1922, "icon": "fa fa-tv", "name": "Рабочее место (кабинет)", "color": "00FF7F" },
    "1923": { "id": 1923, "icon": "fa fa-user-md", "name": "Занимаемая должность по месту работы", "color": "3CB371" },
    "1726": { "id": 1726, "icon": "user", "name": "Мои пациенты", "color": "2E8B57" },
    "1758": { "id": 1758, "icon": "th-list", "name": "Мои назначения и протоколы событий", "color": "228B22" },
    "1756": { "id": 1756, "icon": "th-list", "name": "Протокол события", "color": "008000" },
    "1975": { "id": 1975, "icon": "ruble", "name": "Льготы", "color": "006400" },
    "1976": { "id": 1976, "icon": "magnet", "name": "Условия льготы", "color": "9ACD32" },
    "1882": { "id": 1882, "icon": "fa fa-cubes", "name": "Структурно-функциональная единица учреждения", "color": "6B8E23" },
    "1883": { "id": 1883, "icon": "fa fa-street-view", "name": "Сотрудник", "color": "808000" },
    "1761": { "id": 1761, "icon": "fa fa-user-plus", "name": "Врачи и Пациенты", "color": "556B2F" },
    "1759": { "id": 1759, "icon": "th-list", "name": "Назначения и протоколы событий", "color": "66CDAA" },
    "1722": { "id": 1722, "icon": "fa fa-list-ol", "name": "Препараты в наличии", "color": "8FBC8F" },
    "1724": { "id": 1724, "icon": "fa fa-clock-o", "name": "Режим работы", "color": "20B2AA" },
    "1723": { "id": 1723, "icon": "apple", "name": "Компания", "color": "008B8B" },
    "1881": { "id": 1881, "icon": "fa fa-shopping-cart", "name": "Продукция", "color": "008080" },
    "1811": { "id": 1811, "icon": "fa fa-flask", "name": "Производитель ЛС", "color": "FFA07A" },
    "1812": { "id": 1812, "icon": "fa fa-diamond", "name": "Упаковщик ЛС", "color": "FF7F50" },
    "1989": { "id": 1989, "icon": "fa fa-graduation-cap", "name": "Доступ к библиотеке (доступ к сервису)", "color": "FF6347" },
    "1986": { "id": 1986, "icon": "fa fa-graduation-cap", "name": "Права доступа к шлюзам (LIB, TOOL, FIELD, LEVEL, BEL)", "color": "FF4500" },
    "1705": { "id": 1705, "icon": "fa fa-graduation-cap", "name": "Права доступа на level", "color": "FF8C00" },
    "1": { "id": 1, "icon": "indent-left", "name": "Перемещение объектов", "color": "FFA500" },
    "3": { "id": 3, "icon": "eye-open", "name": "Распределение ограниченного доступа", "color": "FFD700" },
    "1970": { "id": 1970, "icon": "fa fa-users", "name": "Связь с ролью пользователя", "color": "FFFF00" },
    "1706": { "id": 1706, "icon": "fa fa-list-ol", "name": "Объекты экспертизы", "color": "5ed3ff" },
    "1707": { "id": 1707, "icon": "fa fa-street-view", "name": "Эксперты", "color": "9ed563" },
    "1794": { "id": 1794, "icon": "tower", "name": "Типы серонов", "color": "FAFAD2" },
    "1795": { "id": 1795, "icon": "fa fa-chain", "name": "Сцепленные таблицы", "color": "FFEFD5" },
    "1800": { "id": 1800, "icon": "wrench", "name": "Инструменты онтологии", "color": "FFE4B5" },
    "1818": { "id": 1818, "icon": "fa fa-list-ul", "name": "Рубрики (descriptions)", "color": "FFDAB9" },
    "1819": { "id": 1819, "icon": "fa fa-filter", "name": "Уточнение типа концепта для которого рубрика актуальна", "color": "EEE8AA" },
    "1820": { "id": 1820, "icon": "fa fa-navicon", "name": "Дополнительный поля (additional)", "color": "F0E68C" },
    "1821": { "id": 1821, "icon": "fa fa-filter", "name": "Уточнение типа концепта для которого рубрика актуальна 2", "color": "BDB76B" },
    "1822": { "id": 1822, "icon": "fa fa-sliders", "name": "Поля динамических таблиц", "color": "E6E6FA" },
    "1803": { "id": 1803, "icon": "fa fa-arrow-right", "name": "=&gt;", "color": "D8BFD8" },
    "2003": { "id": 2003, "icon": "list-alt", "name": "Заголовок поля таблицы", "color": "DDA0DD" },
    "1919": { "id": 1919, "icon": "fa fa-align-center", "name": "Доступ к Контексту", "color": "EE82EE" },
    "1979": { "id": 1979, "icon": "fa fa-stack-overflow", "name": "Доступ к парсеру для извлечения концептов из текста", "color": "DA70D6" },
    "1798": { "id": 1798, "icon": "fa fa-save", "name": "Белоид", "color": "FF00FF" },
    "1796": { "id": 1796, "icon": "bitcoin", "name": "Базовый сероид", "color": "FF00FF" },
    "1797": { "id": 1797, "icon": "asterisk", "name": "Ключевой сероид", "color": "BA55D3" },
    "1799": { "id": 1799, "icon": "fa fa-text-width", "name": "Тип связи", "color": "9370DB" },
    "1801": { "id": 1801, "icon": "fa fa-hand-o-left", "name": "Левое плечо", "color": "8A2BE2" },
    "1802": { "id": 1802, "icon": "hand-right", "name": "Правое плечо", "color": "9400D3" },
    "1928": { "id": 1928, "icon": "fa fa-vimeo", "name": "Значения ребра - value_a,b,c,d", "color": "9932CC" },
    "1995": { "id": 1995, "icon": "Б", "name": "Присвоение балльной оценки", "color": "8B008B" },
    "1872": { "id": 1872, "icon": "book", "name": "Доступные Документом", "color": "800080" },
    "1871": { "id": 1871, "icon": "file", "name": "Доступная область интерфейса", "color": "4B0082" },
    "1860": { "id": 1860, "icon": "fa fa-sitemap", "name": "Переход - Коллектор", "color": "6A5ACD" },
    "1867": { "id": 1867, "icon": "fa fa-magnet", "name": "Условия срабатывания коллектора", "color": "483D8B" },
    "1863": { "id": 1863, "icon": "fa fa-heart", "name": "Доступные Нозологии или вывод на Результат опроса", "color": "00FFFF" },
    "1814": { "id": 1814, "icon": "fa fa-book", "name": "Источник из которого извлекается информация", "color": "00FFFF" },
    "1816": { "id": 1816, "icon": "fa fa-eye-slash", "name": "Поле или рубрика из которого извлекается информация", "color": "E0FFFF" },
    "1879": { "id": 1879, "icon": "fa fa-stack-overflow", "name": "Доступные Парсеры или Разделы по которым проводиться идентификация концептов", "color": "AFEEEE" },
    "1854": { "id": 1854, "icon": "fa fa-list-ol", "name": "Доступные Сценарии", "color": "7FFFD4" },
    "1846": { "id": 1846, "icon": "fa fa-key", "name": "Доступные Шаблоны", "color": "40E0D0" },
    "1845": { "id": 1845, "icon": "text-width", "name": "Доступные Типы концептов для извлечения сущностей", "color": "48D1CC" },
    "1902": { "id": 1902, "icon": "fa fa-database", "name": "Доступные Разделы для поиска концептов", "color": "00CED1" },
    "1813": { "id": 1813, "icon": "fa fa-wrench", "name": "Доступные Инструменты онтологий и поля сателитных таблиц для укладки данных", "color": "5F9EA0" },
    "1853": { "id": 1853, "icon": "fa fa-asterisk", "name": "Доступные Концепты для извлечения", "color": "4682B4" },
    "1790": { "id": 1790, "icon": "console", "name": "Обращение к методу", "color": "B0C4DE" },
    "1817": { "id": 1817, "icon": "fa fa-file-code-o", "name": "Использовать регулярные выражения", "color": "B0E0E6" },
    "1977": { "id": 1977, "icon": "fa fa-user", "name": "Пользователи сервиса", "color": "ADD8E6" },
    "1978": { "id": 1978, "icon": "fa fa-graduation-cap", "name": "Права доступа к шлюзам LIB, TOOL и FIELD", "color": "87CEEB" },
    "1988": { "id": 1988, "icon": "pencil", "name": "Разделы по которым отслеживаются изменения СУБД", "color": "87CEFA" },
    "1952": { "id": 1952, "icon": "fa fa-database", "name": "Доступный раздел, инструменты и сателлиты для выгрузки и индексации", "color": "00BFFF" },
    "2": { "id": 2, "icon": "", "name": "Кругозор инструментов", "color": "1E90FF" },
    "1645": { "id": 1645, "icon": "fa fa-flag-checkered", "name": "Факторы риска для выявления правонорушений", "color": "6495ED" },
};