import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Typography,
} from '@mui/material';

import { Loader } from "UI";

import { setCurrentPage } from "features/main/mainSlice";

import  GraphBlock  from "./engine/GraphBlock";

import {
    selectLoading,
} from "features/graph/graphSlice";

const GraphComponent = () => {
    const dispatch = useDispatch();
    const { graph: loading } = useSelector(selectLoading);

    const handleClickBack = () => {
        dispatch(setCurrentPage("result"));
    }


    return <>
        <Loader loading={loading}>
            <Box sx={{ height: "calc(100vh - 126px)", overflow: "auto", width: '100%' }}>
                <GraphBlock/>
            </Box>

            <ButtonGroup
                fullWidth
                size="large"
                aria-label="button group"
            >
                <Button
                    fullWidth
                    disabled={false}
                    onClick={handleClickBack}
                >Назад к результатам диагностики</Button>
                {/* <Button
                    disabled={false}
                    onClick={handleClickRecommendations}
                    sx={{
                        width: "50%"
                    }}>Рекомендации</Button> */}
            </ButtonGroup>
        </Loader>
    </>;
}

export const Graph = memo(GraphComponent);
