import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    selectCurrentPatientDisease,
    removePatientEditClarificationById,
} from "features/pages/pagesSlice";

import { DiseaseDetailsItem } from "./DiseaseDetailsItem"; // ServiceInfo

const colors = {
    "Localization": "#f5b726",
    "Details": "#e172e0",
    "DiseaseDetails": "#e172e0",
};

const getColorByType = (type) => {
    return colors[type] || "lightgray";
}

const DiseaseDetailsListComponent = ({ type }) => {
    const dispatch = useDispatch();

    let clarificationsList = [];
    const currentPatientDisease = useSelector(selectCurrentPatientDisease);
    
    if (currentPatientDisease?.clarifications?.length) {
        clarificationsList = currentPatientDisease.clarifications;
        clarificationsList = clarificationsList.filter(item => item.type === type);
    }

    const handleClickRemove = (clarificationId) => {
        dispatch(removePatientEditClarificationById({ item: currentPatientDisease, clarificationId }))
    }

    const color = getColorByType(type);

    return <>
        {clarificationsList.map((item) =>
            <DiseaseDetailsItem
                key={item.id}
                sx={{ backgroundColor: color }}
                label={item.name}
                onDelete={() => handleClickRemove(item.id)}
            />
        )}
    </>;
}

export const DiseaseDetailsList = memo(DiseaseDetailsListComponent);
