import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
    Alert,
    Box,
    List,
} from "@mui/material";

import { Loader } from "UI";

import {
    selectLoading,
    selectSymptomsList,
} from "features/pages/pagesSlice";

import { SymptomItem } from "features/pages"; // ServiceInfo

const SymptomsListComponent = ({ id }) => {
    const { serviceList: loading } = useSelector(selectLoading);
    const symptomsList = useSelector(selectSymptomsList);

    return <>
        <Box sx={{
            height: "calc(100vh - 250px)",
            width: "100%",
            overflow: "auto"
        }}>
            <Loader loading={loading} variant="replace">
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                    }}>
                    {symptomsList.length ? symptomsList.map((item, index) => (
                        <SymptomItem item={item} key={`${index}_${item.id}`} />
                    )) : <Alert severity="info" sx={{ m: 5 }}>Добавьте симптомы через поисковую строку наверху</Alert>}
                </List>
            </Loader>
        </Box>
    </>;
}

export const SymptomsList = memo(SymptomsListComponent);
