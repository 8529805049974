import React from 'react';
import { useDispatch } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom"; // useNavigate

import { appStorage } from "helpers";

import { Restricted } from './features/auth';
import { Main } from './features/main';

import { ThemeProvider } from "@mui/material/styles";


import {
  setSettings,
} from "features/pages/pagesSlice";

import theme from "./theme";
import './App.css';

function App() {
  const dispatch = useDispatch();
  const url = new URL(window.location);

  if (url.pathname) {
    const pathArr = url.pathname.split("/");

    if (pathArr[1] === "welcome" && pathArr[2]) {
      appStorage.setItem("welcomeId", pathArr[2]);
    }
  }

  const localSettings = appStorage.getItem("settings");

  if (localSettings) {
    dispatch(setSettings(localSettings));
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Restricted>
            <Routes>
              <Route path="*" element={<Main />} />
              <Route path="paymentAnswer" element={<Main />}>
              </Route>
            </Routes>
          </Restricted>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
