import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    selectCurrentPatientLifesAnamnez,
    removePatientEditLifesAnamnezClarificationById,
} from "features/pages/pagesSlice";

import { LifesAnamnezDetailsItem } from "./LifesAnamnezDetailsItem"; // ServiceInfo

const colors = {
    "Localization": "#f5b726",
    "Details": "#e172e0",
    "LifesAnamnezDetails": "#e172e0",
};

const getColorByType = (type) => {
    return colors[type] || "lightgray";
}

const LifesAnamnezDetailsListComponent = ({ type }) => {
    const dispatch = useDispatch();

    let clarificationsList = [];
    const currentPatientLifesAnamnez = useSelector(selectCurrentPatientLifesAnamnez);
    
    if (currentPatientLifesAnamnez?.clarifications?.length) {
        clarificationsList = currentPatientLifesAnamnez.clarifications;
        clarificationsList = clarificationsList.filter(item => item.type === type);
    }

    const handleClickRemove = (clarificationId) => {
        dispatch(removePatientEditLifesAnamnezClarificationById({ item: currentPatientLifesAnamnez, clarificationId }))
    }

    const color = getColorByType(type);

    return <>
        {clarificationsList.map((item) =>
            <LifesAnamnezDetailsItem
                key={item.id}
                sx={{ backgroundColor: color }}
                label={item.name}
                onDelete={() => handleClickRemove(item.id)}
            />
        )}
    </>;
}

export const LifesAnamnezDetailsList = memo(LifesAnamnezDetailsListComponent);
