import React, { memo } from "react";

import {
    Paper,
} from '@mui/material';

const PaperBlockComponent = ({
    children,
}) => <Paper
    elevation={1}
    sx={{
        backgroundColor: "#ceedff",
        p: 2,
        pl: 4,
        mt: 2,
    }}
>{children}</Paper>;

export const PaperBlock = memo(PaperBlockComponent);
