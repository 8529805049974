import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { appStorage } from "helpers";

import {
    Box,
    Button,
    CircularProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import LogoutIcon from '@mui/icons-material/Logout';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Settings } from "features/pages";

import {
    selectCurrentMenuItem,
    setCurrentMenuItem,
    selectLoading,
    sendClearCache,
} from "features/main/mainSlice";


export const SideMenu = () => {
    const dispatch = useDispatch();

    const currentMenuItem = useSelector(selectCurrentMenuItem);
    const { clearCache: loading } = useSelector(selectLoading);

    const handleClickSettings = () => {
        dispatch(setCurrentMenuItem("settings"));
    }

    const handleClickBack = () => {
        dispatch(setCurrentMenuItem("home"));
    }

    const handleClickClearCache = () => {
        dispatch(sendClearCache());
    }

    const handleClickLogOut = () => {
        appStorage.removeItem("authkey");
        appStorage.removeItem("authKey");
        appStorage.removeItem("key");
        window.location = "/";
    }


    if (currentMenuItem === "settings") {
        return <Box>
            <Button
                variant="outlined"
                size="small"
                sx={{
                    mt: 3,
                    ml: 3,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={handleClickBack}
            >
                Вернуться в меню
            </Button>
            <Settings />
        </Box>;
    } else {

        return <Box>
            <List component="nav" aria-label="secondary mailbox folder">
                <ListItemButton
                    name="settings"
                    onClick={handleClickSettings}
                >
                    <ListItemIcon>
                        <PsychologyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Настройки анализа" />
                </ListItemButton>

                <ListItemButton
                    disabled={loading}
                    onClick={handleClickClearCache}
                >
                    <ListItemIcon>
                        <CleaningServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Сбросить кэш" />
                    {loading && <ListItemIcon>
                        <CircularProgress color="inherit" sx={{
                            width: "20px !important",
                            height: "20px !important",
                        }} />
                    </ListItemIcon>}
                </ListItemButton>


                <ListItemButton
                    onClick={handleClickLogOut}
                >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Выход" />
                </ListItemButton>

            </List>
        </Box>;
    }
}
