// import { GRAPH_SETTINGS } from 'constants';

// import { fixColor, getIcon } from '../../../../helpers';


// const ANCHOR_RADIUS = GRAPH_SETTINGS.NODE_RADIUS + 10;
// const ANCHOR_SHIFTED = ANCHOR_RADIUS - (GRAPH_SETTINGS.ANCHOR_SIZE / 2);

export default function MasterConcept(G6) {
  G6.registerNode(
    'master-concept-node-animated',
    {
      afterDraw(cfg, group) {
        cfg.color = "#40a9ff";
        const r = 32;
        const r2 = 14;
        const duration = 3200;

        const back0 = group.addShape('circle', {
          zIndex: -4,
          attrs: {
            x: 0,
            y: 0,
            r,
            fill: cfg.color,
            opacity: 0.6,
          },
          name: 'back1-shape',
        });

        const back1 = group.addShape('circle', {
          zIndex: -3,
          attrs: {
            x: 0,
            y: 0,
            r,
            fill: cfg.color,
            opacity: 0.6,
          },
          name: 'back1-shape',
        });

        const back2 = group.addShape('circle', {
          zIndex: -2,
          attrs: {
            x: 0,
            y: 0,
            r,
            fill: cfg.color,
            opacity: 0.6,
          },
          name: 'back2-shape',
        });

        const back3 = group.addShape('circle', {
          zIndex: -1,
          attrs: {
            x: 0,
            y: 0,
            r,
            fill: cfg.color,
            opacity: 0.6,
          },
          name: 'back3-shape',
        });

        group.sort(); // Sort according to the zIndex

        back0.animate(
          {
            r: r + r2,
            opacity: 0.1,
          },
          {
            duration: duration,
            easing: 'easeCubic',
            delay: 2400,
            repeat: true,
          },
        );

        back1.animate(
          {
            r: r + r2,
            opacity: 0.1,
          },
          {
            duration: duration,
            easing: 'easeCubic',
            delay: 0,
            repeat: true,
          },
        );

        back2.animate(
          {
            r: r + r2,
            opacity: 0.1,
          },
          {
            duration: duration,
            easing: 'easeCubic',
            delay: 800,
            repeat: true,
          },
        );

        back3.animate(
          {
            r: r + r2,
            opacity: 0.1,
          },
          {
            duration: duration,
            easing: 'easeCubic',
            delay: 1600,
            repeat: true,
          },
        );
      },

      update: null,
    },
    'master-concept-node',
  );
}
