import React, { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Button,
    ButtonGroup,
    Divider,
} from '@mui/material';

import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

import { useDidMountEffect } from "helpers";

import { Loader, Dialog } from "UI";

import { Search, SymptomsList, Clarifications } from "features/pages";

import {
    selectLoading,
    selectSymptomsList,
    selectResultData,
    selectCurrentSymptom,
    resetCurrentSymptom,
    resetSymptomsList,
    sendGetDiagnostic,
} from "features/pages/pagesSlice";
import { setCurrentPage } from "features/main/mainSlice";


const SymptomsComponent = () => {
    const dispatch = useDispatch();

    const result = useSelector(selectResultData);

    const currentSymptom = useSelector(selectCurrentSymptom);
    const { diagnostic: loading } = useSelector(selectLoading);
    const symptomsList = useSelector(selectSymptomsList);

    const [mode, setMode] = useState(result?.guid ? "view" : "analys"); // view analys

    const handleCloseClarifications = () => {
        dispatch(resetCurrentSymptom());
    }

    const handleClickReset = () => {
        dispatch(resetSymptomsList());
    }

    const handleClickDiagnostic = () => {
        if (mode === "view") {
            dispatch(setCurrentPage("result"));
        } else {
            dispatch(sendGetDiagnostic({
                success: () => dispatch(setCurrentPage("result"))
            }));
        }
    }

    let resetDisabled = !symptomsList.length;
    let diagnosticDisabled = !symptomsList.length;

    useDidMountEffect(() => {
        if (mode !== "analys") {
            setMode("analys");
        }
    }, [mode, symptomsList]);

    return <>
        <Loader loading={loading}>
            <Box sx={{ height: "calc(100vh - 82px)", width: '100%' }}>
                <Search
                    variant="parse"
                    type="Symptoms"
                    label={"Укажите симптомы (жалобы)"}
                    itemIcon={<OfflineBoltIcon sx={{ color: 'text.secondary' }} />}
                    inputColor="#3977c629"
                    resultItemProps={{
                        sx: {
                            display: "inline-block",
                            "&:first-letter": {
                                textTransform: "uppercase"
                            }
                        }
                    }}
                />
                <Divider
                sx={{
                    m: 2
                }}/>
                <Search
                    variant="parse"
                    type="Diagnostic"
                    label={"Результаты диагностических исследований"}
                    itemIcon={<OfflineBoltIcon sx={{ color: 'text.secondary' }} />}
                    inputColor="#aa12bf29"
                    resultItemProps={{
                        sx: {
                            display: "inline-block",
                            "&:first-letter": {
                                textTransform: "uppercase"
                            }
                        }
                    }}
                />

                <SymptomsList />

                <ButtonGroup
                    fullWidth
                    size="large"
                    aria-label="large button group"
                >
                    <Button
                        disabled={resetDisabled}
                        onClick={handleClickReset}
                    >Сбросить</Button>
                    <Button
                        disabled={diagnosticDisabled}
                        onClick={handleClickDiagnostic}
                    >{mode === "view" ? "Диагноз" : "Диагностировать"}</Button>
                </ButtonGroup>

            </Box>

            <Dialog
                open={!!currentSymptom}
                onClose={handleCloseClarifications}
            >
                <Clarifications />
            </Dialog>
        </Loader>
    </>;
}

export const Symptoms = memo(SymptomsComponent);
