import React, { memo, useState } from "react";

import {
    Box,
    InputLabel,
    FormControl,
    Select,
    Stack,
    Paper,
    MenuItem,
} from '@mui/material';

const Selector1Component = ({
    sensor,
    id,
    onChange,
    children,
}) => {

    // const [value, setValue] = useState(sensor.valueResponse || "");

    const value = sensor.valueResponse || "";

    const options = sensor.oprionsResponse || sensor.optionsResponse || {};


    const handleChange = (event) => {
        console.log({ id: id, value: event.target.value });
        onChange && onChange({ id: id, value: event.target.value });
    }

    return <Box>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
                borderBottom: "1px solid lightgray",
                // height: "40px",
            }}
        >
            {sensor.name}
            <FormControl
                size="small"
                variant="filled"
                sx={{
                    // m: 1,
                    minWidth: 120,
                }}
            >

                {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                <Select
                    labelId="simple-select-standard-label"
                    id="simple-select-standard"
                    value={value||""}
                    onChange={handleChange}
                    defaultValue={""}
                // label="Age"
                >
                    <MenuItem value="">
                        <em>---</em>
                    </MenuItem>
                    {Object.keys(options).map(optionKey => <MenuItem value={optionKey} key={optionKey}>{options[optionKey]}</MenuItem>)}
                </Select>
            </FormControl>
        </Stack>
    </Box>;
}

export const Selector1 = memo(Selector1Component);
