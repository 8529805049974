
// import clickSelected from './clickSelected';
// import deleteItem from './deleteItem';
// import dragNode from './dragNode';
// import createEdge from './createEdge';
// import dragPanelItemAddNode from './dragPanelItemAddNode';
// import itemAlign from './itemAlign';
// import zoomCanvas from './zoomCanvas';
// import dragCanvas from './dragCanvas';
// import brushSelect from './brushSelect';
// import dragGraph from './dragGraph';
// import clickGraph from './clickGraph';
import hoverTool from './hoverTool';
import selectNeighbours from './selectNeighbours';
// import touchEdge from './touchEdge';

export default function (G6) {
  hoverTool(G6);
  selectNeighbours(G6);
  // touchEdge(G6);
  // clickSelected(G6);
  // deleteItem(G6);
  // dragNode(G6);
  // createEdge(G6);
  // dragPanelItemAddNode(G6);
  // itemAlign(G6);
  // zoomCanvas(G6);
  // dragCanvas(G6);
  // brushSelect(G6);
  // dragGraph(G6);
  // clickGraph(G6);
}
