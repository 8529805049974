export const levels = {
    "1": { "id": 1, "name": "Заголовок списка концептов", "color": "A0522D" },
    "2": { "id": 2, "name": "Список нераспределенных концептов", "color": "F08080" },
    "3": { "id": 3, "name": "Список уточнений", "color": "FA8072" },
    "4": { "id": 4, "name": "---", "color": "E9967A" },
    "6": { "id": 6, "name": "bel", "color": "FFA07A" },
    "7": { "id": 7, "name": " FIELD", "color": "DC143C" },
    "8": { "id": 8, "name": "LIB", "color": "FF0000" },
    "9": { "id": 9, "name": "descriptions", "color": "B22222" },
    "10": { "id": 10, "name": "LEVEL", "color": "8B0000" },
    "11": { "id": 11, "name": "Элемент образует промежуточный признак", "color": "FFC0CB" },
    "12": { "id": 12, "name": "Элемент может образовать промежуточный признак", "color": "FFB6C1" },
    "13": { "id": 13, "name": "Группа элементов и/или условия сочетания", "color": "FF69B4" },
    "15": { "id": 15, "name": "Часть элемента", "color": "FF1493" },
    "17": { "id": 17, "name": "символ", "color": "C71585" },
    "18": { "id": 18, "name": "Пара элемента", "color": "DB7093" },
    "19": { "id": 19, "name": "Элемент как образец", "color": "ADFF2F" },
    "20": { "id": 20, "name": "Словоформа", "color": "7FFF00" },
    "21": { "id": 21, "name": "Междометие", "color": "7CFC00" },
    "22": { "id": 22, "name": "Союз", "color": "00FF00" },
    "23": { "id": 23, "name": "Окончание (флексия)", "color": "32CD32" },
    "24": { "id": 24, "name": "Предлог", "color": "98FB98" },
    "25": { "id": 25, "name": "Суфикс", "color": "5db4cc" },
    "26": { "id": 26, "name": "Спецсимволы HTML", "color": "00FA9A" },
    "27": { "id": 27, "name": "Теги HTML", "color": "00FF7F" },
    "28": { "id": 28, "name": "link", "color": "3CB371" },
    "29": { "id": 29, "name": "Знаки препинания", "color": "2E8B57" },
    "30": { "id": 30, "name": "Приставка", "color": "228B22" },
    "31": { "id": 31, "name": "varchar", "color": "008000" },
    "32": { "id": 32, "name": "double", "color": "006400" },
    "33": { "id": 33, "name": "date", "color": "9ACD32" },
    "34": { "id": 34, "name": "value", "color": "6B8E23" },
    "35": { "id": 35, "name": "boolean", "color": "808000" },
    "36": { "id": 36, "name": "files", "color": "556B2F" },
    "37": { "id": 37, "name": "names", "color": "66CDAA" },
    "38": { "id": 38, "name": "formula", "color": "8FBC8F" },
    "39": { "id": 39, "name": "dyn_", "color": "20B2AA" },
    "40": { "id": 40, "name": "Составная часть сложных слов", "color": "008B8B" },
    "41": { "id": 41, "name": "Инструмент онтологии (Переходы) - формирует родо-видовые отношения", "color": "008080" },
    "42": { "id": 42, "name": "Инструмент онтологии (ПЭ) - формирует промежуточные концепты", "color": "FFA07A" },
    "43": { "id": 43, "name": "Ключевой инструмент онтологии - формирует смысловые графы", "color": "FF7F50" },
    "44": { "id": 44, "name": "Уточняющие инструменты онтологии - вспомогательный инструмент формирующий боковые связи", "color": "FF6347" },
    "47": { "id": 47, "name": "Языки", "color": "FF4500" },
    "49": { "id": 49, "name": "Оценки связей", "color": "FF8C00" },
    "50": { "id": 50, "name": "Прикрепить файл", "color": "FFA500" },
    "51": { "id": 51, "name": "Названия полей и других объектов пользовательского интерфейса", "color": "FFD700" },
    "52": { "id": 52, "name": "Названия кнопок пользовательского интерфейса", "color": "FFFF00" },
    "53": { "id": 53, "name": "Текст в формате HTML для пользовательского интерфейса", "color": "5ed3ff" },
    "60": { "id": 60, "name": "Заголовок протокола/поля в электронной карте пациента", "color": "9ed563" },
    "61": { "id": 61, "name": "Паспортные данные", "color": "5F9EA0" },
    "62": { "id": 62, "name": "Социальный фактор", "color": "FFEFD5" },
    "63": { "id": 63, "name": "Организационно – методические мероприятия", "color": "FFE4B5" },
    "64": { "id": 64, "name": "Заключение организационно – методические мероприятия", "color": "FFDAB9" },
    "65": { "id": 65, "name": "Название поля в протоколе исследования", "color": "EEE8AA" },
    "70": { "id": 70, "name": "Грамматическая категория", "color": "F0E68C" },
    "71": { "id": 71, "name": "Граммема", "color": "BDB76B" },
    "72": { "id": 72, "name": "Член предложения", "color": "E6E6FA" },
    "73": { "id": 73, "name": "Синтаксические связи", "color": "D8BFD8" },
    "74": { "id": 74, "name": null, "color": "DDA0DD" },
    "75": { "id": 75, "name": "Спец. объект в тексте (строка извлекаемая регулярным выражением)", "color": "EE82EE" },
    "79": { "id": 79, "name": "Значение слова (концепт)", "color": "DA70D6" },
    "80": { "id": 80, "name": "Продукционное правило", "color": "FF00FF" },
    "81": { "id": 81, "name": "Если так ...", "color": "FF00FF" },
    "82": { "id": 82, "name": "То так ...", "color": "BA55D3" },
    "83": { "id": 83, "name": "Формировать связь", "color": "9370DB" },
    "84": { "id": 84, "name": null, "color": "8A2BE2" },
    "99": { "id": 99, "name": "Неприличные слова и приколы", "color": "9400D3" },
    "100": { "id": 100, "name": "Анатомический признак", "color": "9932CC" },
    "101": { "id": 101, "name": "Часть тела", "color": "8B008B" },
    "102": { "id": 102, "name": "Область тела", "color": "800080" },
    "103": { "id": 103, "name": "Дерматомы", "color": "4B0082" },
    "104": { "id": 104, "name": "Сосудисто-нервный пучок", "color": "6A5ACD" },
    "105": { "id": 105, "name": "Межфасциальное пространство", "color": "483D8B" },
    "106": { "id": 106, "name": "Некая область внутри тела", "color": "00FFFF" },
    "107": { "id": 107, "name": "Система органов и тканей", "color": "00FFFF" },
    "108": { "id": 108, "name": "Органо-комплекс", "color": "E0FFFF" },
    "109": { "id": 109, "name": "Артерия", "color": "AFEEEE" },
    "110": { "id": 110, "name": "Часть артерии", "color": "7FFFD4" },
    "111": { "id": 111, "name": "Группа артерий", "color": "40E0D0" },
    "112": { "id": 112, "name": "Вена", "color": "48D1CC" },
    "113": { "id": 113, "name": "Часть вены", "color": "00CED1" },
    "114": { "id": 114, "name": "Группа вен", "color": "5F9EA0" },
    "115": { "id": 115, "name": "Нерв", "color": "4682B4" },
    "116": { "id": 116, "name": "Ветвь нерва", "color": "B0C4DE" },
    "117": { "id": 117, "name": "Группа нервов и ганглиев", "color": "B0E0E6" },
    "118": { "id": 118, "name": "Ганглия", "color": "ADD8E6" },
    "119": { "id": 119, "name": "Сплетение", "color": "87CEEB" },
    "120": { "id": 120, "name": "Лимфатический сосуд", "color": "87CEFA" },
    "121": { "id": 121, "name": "Лимфатический узел", "color": "00BFFF" },
    "122": { "id": 122, "name": "Кость", "color": "1E90FF" },
    "123": { "id": 123, "name": "Структурно-функциональный элемент кости или сустава", "color": "6495ED" },
    "124": { "id": 124, "name": "Группа костей", "color": "7B68EE" },
    "125": { "id": 125, "name": "Орган как образец", "color": "4169E1" },
    "126": { "id": 126, "name": "Мышца", "color": "0000FF" },
    "127": { "id": 127, "name": "Группа мышц", "color": "0000CD" },
    "128": { "id": 128, "name": "Головка мышцы", "color": "00008B" },
    "129": { "id": 129, "name": "Брюшко мышцы", "color": "000080" },
    "130": { "id": 130, "name": "Сухожилье", "color": "191970" },
    "131": { "id": 131, "name": "Связка", "color": "FFF8DC" },
    "132": { "id": 132, "name": "Сустав", "color": "FFEBCD" },
    "133": { "id": 133, "name": "Атрибут структурного признака", "color": "FFE4C4" },
    "134": { "id": 134, "name": "Рецептор", "color": "FFDEAD" },
    "135": { "id": 135, "name": "Ветви нерва", "color": "00b652" },
    "136": { "id": 136, "name": "Орган", "color": "DEB887" },
    "137": { "id": 137, "name": "Группа или пара органов", "color": "D2B48C" },
    "138": { "id": 138, "name": "Структурно-функциональный отдел органа", "color": "BC8F8F" },
    "139": { "id": 139, "name": "Функциональная единица", "color": "F4A460" },
    "140": { "id": 140, "name": "Кожа", "color": "DAA520" },
    "141": { "id": 141, "name": "Слизистая оболочка", "color": "B8860B" },
    "142": { "id": 142, "name": "Фасция", "color": "CD853F" },
    "143": { "id": 143, "name": "Апоневроз", "color": "D2691E" },
    "144": { "id": 144, "name": "Брюшина", "color": "8B4513" },
    "145": { "id": 145, "name": "Плевра", "color": "A0522D" },
    "146": { "id": 146, "name": "Надкостница", "color": "A52A2A" },
    "147": { "id": 147, "name": "Синовиальное влагалище", "color": "800000" },
    "148": { "id": 148, "name": "Подкожная жировая клетчатка", "color": "A0522D" },
    "149": { "id": 149, "name": "Группа связок", "color": "F08080" },
    "150": { "id": 150, "name": "Термин используется в гистологии", "color": "FA8072" },
    "151": { "id": 151, "name": "Термин используется в эмбриологии", "color": "E9967A" },
    "152": { "id": 152, "name": "Хрящи", "color": "FFA07A" },
    "153": { "id": 153, "name": "Волосы", "color": "DC143C" },
    "154": { "id": 154, "name": "Ногти", "color": "FF0000" },
    "155": { "id": 155, "name": "Ткань", "color": "B22222" },
    "156": { "id": 156, "name": "Клетка", "color": "8B0000" },
    "157": { "id": 157, "name": "Структурный элемент клетки", "color": "FFC0CB" },
    "158": { "id": 158, "name": "Органелла", "color": "FFB6C1" },
    "159": { "id": 159, "name": "Синовиальная сумка", "color": "FF69B4" },
    "160": { "id": 160, "name": "Пол", "color": "FF1493" },
    "161": { "id": 161, "name": "Возраст", "color": "C71585" },
    "162": { "id": 162, "name": "Период 1 типа", "color": "DB7093" },
    "163": { "id": 163, "name": "Период 2 типа", "color": "ADFF2F" },
    "164": { "id": 164, "name": "Расовая группа", "color": "7FFF00" },
    "165": { "id": 165, "name": "Расовая подгруппа", "color": "7CFC00" },
    "166": { "id": 166, "name": "Физикальный метод исследования", "color": "00FF00" },
    "167": { "id": 167, "name": "Инструментальный метод исследования", "color": "32CD32" },
    "168": { "id": 168, "name": "Лабораторный метод исследования", "color": "98FB98" },
    "169": { "id": 169, "name": "Результат осмотра", "color": "5db4cc" },
    "170": { "id": 170, "name": "Антропометрический признак", "color": "00FA9A" },
    "171": { "id": 171, "name": "Параметр антропометрического признака", "color": "00FF7F" },
    "172": { "id": 172, "name": "Персональный признак", "color": "3CB371" },
    "173": { "id": 173, "name": "Группа клеток в органе", "color": "2E8B57" },
    "174": { "id": 174, "name": "Пространственные отношения", "color": "228B22" },
    "175": { "id": 175, "name": "Топографическа область", "color": "008000" },
    "176": { "id": 176, "name": "sumkb", "color": "006400" },
    "177": { "id": 177, "name": "Результат исследования", "color": "9ACD32" },
    "178": { "id": 178, "name": "Метод исследования", "color": "6B8E23" },
    "179": { "id": 179, "name": "Диета (лечебный стол)", "color": "808000" },
    "180": { "id": 180, "name": "Анамнестический фактор", "color": "556B2F" },
    "181": { "id": 181, "name": "Анамнезтический фактор_Э", "color": "66CDAA" },
    "182": { "id": 182, "name": "Класс заболевания", "color": "8FBC8F" },
    "183": { "id": 183, "name": "Группа заболеваний", "color": "20B2AA" },
    "184": { "id": 184, "name": "Заболевание/Синдром", "color": "20B2AA" },
    "185": { "id": 185, "name": "Заболевание", "color": "20B2AA" },
    "186": { "id": 186, "name": "Метод профилактики, исключительно", "color": "FFA07A" },
    "187": { "id": 187, "name": "Давность процесса", "color": "FF7F50" },
    "188": { "id": 188, "name": "Метод лечения, профилактики и реабилитации", "color": "FF6347" },
    "189": { "id": 189, "name": "ФГ - Фармакологическая группа", "color": "FF4500" },
    "190": { "id": 190, "name": "Единица измерения", "color": "FF8C00" },
    "199": { "id": 199, "name": "Диагностические шкалы", "color": "FFA500" },
    "201": { "id": 201, "name": "Группа лимфатических узлов", "color": "FFD700" },
    "202": { "id": 202, "name": "Группа суставов", "color": "FFFF00" },
    "203": { "id": 203, "name": "Действие, влияние, воздействие и т.п.", "color": "5ed3ff" },
    "204": { "id": 204, "name": "Группа лимфатических сосудов", "color": "9ed563" },
    "208": { "id": 208, "name": "Проводящий путь/тракт/пучок", "color": "5F9EA0" },
    "209": { "id": 209, "name": "Анатомическое образование", "color": "FFEFD5" },
    "210": { "id": 210, "name": "Механизм", "color": "FFE4B5" },
    "215": { "id": 215, "name": " Консультация", "color": "FFDAB9" },
    "216": { "id": 216, "name": null, "color": "EEE8AA" },
    "220": { "id": 220, "name": "Рефлекс", "color": "F0E68C" },
    "230": { "id": 230, "name": "Органические соединения", "color": "BDB76B" },
    "231": { "id": 231, "name": "Неорганические соединения", "color": "E6E6FA" },
    "232": { "id": 232, "name": "Химический элемент", "color": "D8BFD8" },
    "233": { "id": 233, "name": "Химическое соединение", "color": "DDA0DD" },
    "234": { "id": 234, "name": "Вспомогательное вещество", "color": "EE82EE" },
    "235": { "id": 235, "name": "Группа химических элементов или соединений", "color": "DA70D6" },
    "236": { "id": 236, "name": "СЛС - Субстанция лекарственного средства", "color": "FF00FF" },
    "237": { "id": 237, "name": "Гомеопатическое средство", "color": "FF00FF" },
    "238": { "id": 238, "name": "Лекарственное растительное сырье", "color": "BA55D3" },
    "239": { "id": 239, "name": "ГНДВ - Группировочное название ЛС", "color": "9370DB" },
    "240": { "id": 240, "name": "ТН - Торговое название препарата", "color": "8A2BE2" },
    "241": { "id": 241, "name": "ДВ - Действующее вещество", "color": "9400D3" },
    "242": { "id": 242, "name": "Анатомо-терапевтическо-химическая группа (АТХ)", "color": "9932CC" },
    "243": { "id": 243, "name": "ФД - Фармакологическое действие", "color": "8B008B" },
    "244": { "id": 244, "name": "ФП - Фармакологический препарат", "color": "800080" },
    "245": { "id": 245, "name": "Лекарственная форма (ЛФ)", "color": "4B0082" },
    "246": { "id": 246, "name": "ДВБАД - Действующее вещество входящий в состав биоактивной добавки", "color": "6A5ACD" },
    "247": { "id": 247, "name": "БАД - Биологически активная добавка", "color": "483D8B" },
    "248": { "id": 248, "name": "ДВК - Комбинированное действующее вещество", "color": "00FFFF" },
    "249": { "id": 249, "name": "ГДВ - Гомеопатическое действующее вещество", "color": "00FFFF" },
    "250": { "id": 250, "name": "Анатомический класс", "color": "E0FFFF" },
    "251": { "id": 251, "name": "Фармгруппа на уровне терапевтического эффекта (ФГт)", "color": "AFEEEE" },
    "252": { "id": 252, "name": "Фармгруппа на уровне химической формулы [Химическая группа] (ФГх)", "color": "7FFFD4" },
    "253": { "id": 253, "name": "СТЕНА", "color": "40E0D0" },
    "254": { "id": 254, "name": "ДВР - Действующее вещество растительного лекарственного средства", "color": "48D1CC" },
    "255": { "id": 255, "name": "Тип взаимодействия ЛС", "color": "00CED1" },
    "256": { "id": 256, "name": "Способ применения ЛС", "color": "5F9EA0" },
    "257": { "id": 257, "name": "Кратность приема ЛС", "color": "4682B4" },
    "258": { "id": 258, "name": "Время приема ЛС", "color": "B0C4DE" },
    "259": { "id": 259, "name": "Длительность приема ЛС", "color": "B0E0E6" },
    "260": { "id": 260, "name": "Тип дозировки ЛС", "color": "ADD8E6" },
    "261": { "id": 261, "name": "Элемент изменения ФД", "color": "87CEEB" },
    "262": { "id": 262, "name": "Изменение эффекта ЛС", "color": "87CEFA" },
    "263": { "id": 263, "name": "Механизм действия ЛС", "color": "00BFFF" },
    "264": { "id": 264, "name": "Побочная реакция", "color": "1E90FF" },
    "265": { "id": 265, "name": "Механизм взаимодействия между ЛС", "color": "6495ED" },
    "266": { "id": 266, "name": "Изменение фармакологического действия", "color": "7B68EE" },
    "267": { "id": 267, "name": "Тип регуляции метаболизма", "color": "4169E1" },
    "268": { "id": 268, "name": "Упаковка", "color": "0000FF" },
    "269": { "id": 269, "name": "АТХ - Терапевтическая подгруппа", "color": "0000CD" },
    "270": { "id": 270, "name": "Состояние организма", "color": "00008B" },
    "271": { "id": 271, "name": "Состояние, имеет определенную локализацию или функцию", "color": "000080" },
    "272": { "id": 272, "name": "Состояние, может иметь локализацию или функцию", "color": "191970" },
    "273": { "id": 273, "name": "Раздел медицинских услуг", "color": "FFF8DC" },
    "274": { "id": 274, "name": "Тип медицинской услуги", "color": "FFEBCD" },
    "275": { "id": 275, "name": "Класс медицинской услуги", "color": "FFE4C4" },
    "276": { "id": 276, "name": "Медицинская услуга", "color": "FFDEAD" },
    "277": { "id": 277, "name": "Группа методов лечения, профилактики и реабилитации", "color": "00b652" },
    "278": { "id": 278, "name": "Тип действия, совершаемого при осуществлении медицинского вмешательства", "color": "DEB887" },
    "279": { "id": 279, "name": "Клиническая рекомендация", "color": "D2B48C" },
    "280": { "id": 280, "name": "Лекарственное средство", "color": "BC8F8F" },
    "281": { "id": 281, "name": "Смесь лекарственных препаратов", "color": "F4A460" },
    "282": { "id": 282, "name": "ФП верхнего уровня", "color": "DAA520" },
    "283": { "id": 283, "name": "Фармгруппа на уровне мишени (ФГм)", "color": "B8860B" },
    "288": { "id": 288, "name": "Лекарственные травы", "color": "CD853F" },
    "289": { "id": 289, "name": "Способ приготовления ЛС", "color": "D2691E" },
    "290": { "id": 290, "name": "Комплектующий предмет", "color": "8B4513" },
    "294": { "id": 294, "name": "Мутация замены одного нуклеатида", "color": "A0522D" },
    "295": { "id": 295, "name": "Мутация замены более одного нуклеотида", "color": "A52A2A" },
    "296": { "id": 296, "name": "Вставка более одного нуклеотида", "color": "800000" },
    "297": { "id": 297, "name": "Выпадение одного нуклеотида", "color": "A0522D" },
    "298": { "id": 298, "name": "Вставка одного нуклеотида", "color": "F08080" },
    "299": { "id": 299, "name": "Выпадение более одного нуклеотида", "color": "FA8072" },
    "300": { "id": 300, "name": "Мутация", "color": "E9967A" },
    "301": { "id": 301, "name": "Хромосома", "color": "FFA07A" },
    "302": { "id": 302, "name": "Геном/Сиквенс", "color": "DC143C" },
    "303": { "id": 303, "name": " Участок гена отвечающий за синтез матричной РНК", "color": "FF0000" },
    "304": { "id": 304, "name": "Участок гена отвечающий за синтез белка", "color": "B22222" },
    "305": { "id": 305, "name": "Плечо", "color": "8B0000" },
    "306": { "id": 306, "name": "Тип аллели", "color": "FFC0CB" },
    "307": { "id": 307, "name": "Аллель", "color": "FFB6C1" },
    "308": { "id": 308, "name": "Тип гена", "color": "FF69B4" },
    "309": { "id": 309, "name": "Ген (unknown)", "color": "FF1493" },
    "310": { "id": 310, "name": "Жидкая среда организма", "color": "C71585" },
    "311": { "id": 311, "name": "Образец биологического материала", "color": "DB7093" },
    "312": { "id": 312, "name": "Ген кодирующий белок (protein-coding)", "color": "ADFF2F" },
    "313": { "id": 313, "name": "Ген не кодирующий ядерный РНК (ncRNA)", "color": "7FFF00" },
    "314": { "id": 314, "name": "Ген кодирующий транспортный РНК (tRNA)", "color": "7CFC00" },
    "315": { "id": 315, "name": "Ген кодирующий малые ядрышковые РНК (snoRNA)", "color": "00FF00" },
    "316": { "id": 316, "name": "Ген кодирующий малый ядерные РНК (snRNA)", "color": "32CD32" },
    "317": { "id": 317, "name": "Ген кодирующий матричный РНК (rRNA)", "color": "98FB98" },
    "318": { "id": 318, "name": "Инженерный ген (scRNA)", "color": "5db4cc" },
    "319": { "id": 319, "name": "Псевдо ген (pseudo)", "color": "00FA9A" },
    "320": { "id": 320, "name": "Элемент генома (biological-region)", "color": "00FF7F" },
    "321": { "id": 321, "name": "Цитохром", "color": "3CB371" },
    "322": { "id": 322, "name": "Семейство ферментов/белков", "color": "2E8B57" },
    "323": { "id": 323, "name": "Подсемейство ферментов/белков", "color": "228B22" },
    "324": { "id": 324, "name": "Изоформа ферментов/белков", "color": "008000" },
    "325": { "id": 325, "name": "Фермент", "color": "006400" },
    "340": { "id": 340, "name": "ТНСуб - Торговое название субстанции", "color": "9ACD32" },
    "341": { "id": 341, "name": "ТНР - Торговое наименование растительного ЛС", "color": "6B8E23" },
    "342": { "id": 342, "name": "ТНГ - Торговое наименование гомеопатического ЛС", "color": "808000" },
    "343": { "id": 343, "name": "ТНБАД - Торговое наименование биоактивной добавки", "color": "556B2F" },
    "350": { "id": 350, "name": "Нелекарственный аптечный товар", "color": "66CDAA" },
    "351": { "id": 351, "name": "Лечебная косметика", "color": "8FBC8F" },
    "352": { "id": 352, "name": "Лечебно-диагностические средства", "color": "20B2AA" },
    "353": { "id": 353, "name": "Парафармацевтика", "color": "008B8B" },
    "354": { "id": 354, "name": "Ветеринария", "color": "008080" },
    "355": { "id": 355, "name": "Детские товары", "color": "FFA07A" },
    "356": { "id": 356, "name": "Детское питание", "color": "FF7F50" },
    "357": { "id": 357, "name": "Изделия медицинского назначения", "color": "FF6347" },
    "358": { "id": 358, "name": "Материалы", "color": "FF4500" },
    "359": { "id": 359, "name": " Медицинская Техника", "color": "FF8C00" },
    "360": { "id": 360, "name": "Минеральная вода", "color": "FFA500" },
    "361": { "id": 361, "name": "Оптика", "color": "FFD700" },
    "362": { "id": 362, "name": "Продукты питания", "color": "FFFF00" },
    "363": { "id": 363, "name": "Средства ухода", "color": "5ed3ff" },
    "364": { "id": 364, "name": "Товары для ЗОЖ", "color": "9ed563" },
    "365": { "id": 365, "name": "Полиграфическая продукция", "color": "5F9EA0" },
    "366": { "id": 366, "name": "Блюда", "color": "FFEFD5" },
    "399": { "id": 399, "name": "Продукт жизнедеятельности", "color": "FFE4B5" },
    "400": { "id": 400, "name": "Нормальное состояние организма, наблюдаемое при исследовании", "color": "FFDAB9" },
    "501": { "id": 501, "name": "Патологические реакции", "color": "EEE8AA" },
    "502": { "id": 502, "name": "Патологический процесс", "color": "F0E68C" },
    "503": { "id": 503, "name": "Патологическое состояние", "color": "BDB76B" },
    "600": { "id": 600, "name": "Внешний патогенный фактор", "color": "E6E6FA" },
    "601": { "id": 601, "name": "Элементарный внешний патогенный фактор", "color": "D8BFD8" },
    "602": { "id": 602, "name": "Элементарный внешний фактор", "color": "DDA0DD" },
    "608": { "id": 608, "name": "Термин используется в биологии", "color": "EE82EE" },
    "609": { "id": 609, "name": "Питательная среда", "color": "DA70D6" },
    "610": { "id": 610, "name": "Ранги таксонов в биологии", "color": "FF00FF" },
    "611": { "id": 611, "name": "наддомен", "color": "FF00FF" },
    "612": { "id": 612, "name": "надцарство [домен] / superkingdom [domain]", "color": "BA55D3" },
    "613": { "id": 613, "name": "поддомен / subdomain", "color": "9370DB" },
    "614": { "id": 614, "name": "царство / kingdom", "color": "8A2BE2" },
    "615": { "id": 615, "name": "подцарство / subkingdom", "color": "9400D3" },
    "616": { "id": 616, "name": "надтип / superphylum", "color": "9932CC" },
    "617": { "id": 617, "name": "инфротип / infraphylum", "color": "8B008B" },
    "618": { "id": 618, "name": "тип [отдел] / phylum [divisio]", "color": "800080" },
    "619": { "id": 619, "name": "подтип [подотдел] / subphylum", "color": "4B0082" },
    "620": { "id": 620, "name": "надкласс / superclass", "color": "6A5ACD" },
    "621": { "id": 621, "name": "класс / class", "color": "483D8B" },
    "622": { "id": 622, "name": "подкласс / subclass", "color": "00FFFF" },
    "623": { "id": 623, "name": "инфракласс / infraclass", "color": "00FFFF" },
    "624": { "id": 624, "name": "магнотряд / magnordo", "color": "E0FFFF" },
    "625": { "id": 625, "name": "надотряд / superorder", "color": "AFEEEE" },
    "626": { "id": 626, "name": "грандотряд / grandordo", "color": "7FFFD4" },
    "627": { "id": 627, "name": "миротряд / mirordo", "color": "40E0D0" },
    "628": { "id": 628, "name": "отряд / order", "color": "48D1CC" },
    "629": { "id": 629, "name": "подотряд / suborder", "color": "00CED1" },
    "630": { "id": 630, "name": "инфраотряд / infraorder", "color": "5F9EA0" },
    "631": { "id": 631, "name": "парвотряд / parvorder", "color": "4682B4" },
    "632": { "id": 632, "name": "надсемейство / superfamily", "color": "B0C4DE" },
    "633": { "id": 633, "name": "семейство / family", "color": "B0E0E6" },
    "634": { "id": 634, "name": "подсемейство / subfamily", "color": "ADD8E6" },
    "635": { "id": 635, "name": "надтриба [надколено] / supertribe", "color": "87CEEB" },
    "636": { "id": 636, "name": "триба [колено] / tribe", "color": "87CEFA" },
    "637": { "id": 637, "name": "подтриба [подколено] / subtribe", "color": "00BFFF" },
    "638": { "id": 638, "name": "род / genus", "color": "1E90FF" },
    "639": { "id": 639, "name": "подрод / subgenus", "color": "6495ED" },
    "640": { "id": 640, "name": "Внешняя среда", "color": "7B68EE" },
    "641": { "id": 641, "name": "надсекция / supersectio", "color": "4169E1" },
    "642": { "id": 642, "name": "Внешний фактор", "color": "0000FF" },
    "643": { "id": 643, "name": "секция / sectio", "color": "0000CD" },
    "644": { "id": 644, "name": "подсекция / subsectio", "color": "00008B" },
    "645": { "id": 645, "name": "ряд [серия] / series", "color": "000080" },
    "646": { "id": 646, "name": "подряд [подсерия] / subseries", "color": "191970" },
    "647": { "id": 647, "name": "ВИД / species", "color": "FFF8DC" },
    "648": { "id": 648, "name": "подвид / subspecies", "color": "FFEBCD" },
    "649": { "id": 649, "name": "сорт [порода] / sorte", "color": "FFE4C4" },
    "650": { "id": 650, "name": "разновидность [инфравид] / infraspecies", "color": "FFDEAD" },
    "651": { "id": 651, "name": "подразновидность / subvarietas", "color": "00b652" },
    "652": { "id": 652, "name": "форма / forma", "color": "DEB887" },
    "653": { "id": 653, "name": "подформа / subforma", "color": "D2B48C" },
    "654": { "id": 654, "name": "вариетет / varietas", "color": "BC8F8F" },
    "655": { "id": 655, "name": "Форма жизненного цикла", "color": "F4A460" },
    "656": { "id": 656, "name": "--- Серотип", "color": "DAA520" },
    "657": { "id": 657, "name": "--- Штамм", "color": "B8860B" },
    "658": { "id": 658, "name": "--- Изолят", "color": "CD853F" },
    "659": { "id": 659, "name": "no rank", "color": "D2691E" },
    "660": { "id": 660, "name": "Гаплогруппа", "color": "8B4513" },
    "661": { "id": 661, "name": "Гаплотип", "color": "A0522D" },
    "662": { "id": 662, "name": "Маркер мутации", "color": "A52A2A" },
    "663": { "id": 663, "name": "Происхождение аллели", "color": "800000" },
    "664": { "id": 664, "name": "Клиническое значение аллели", "color": "A0522D" },
    "665": { "id": 665, "name": "Национальность", "color": "F08080" },
    "666": { "id": 666, "name": "Религия", "color": "FA8072" },
    "667": { "id": 667, "name": "Прочие гены", "color": "E9967A" },
    "668": { "id": 668, "name": "Проект по исследованию генома", "color": "FFA07A" },
    "680": { "id": 680, "name": "Кодон", "color": "DC143C" },
    "696": { "id": 696, "name": "Прочие объекты", "color": "FF0000" },
    "699": { "id": 699, "name": "Знаменитость - носитель гаплогруппы", "color": "B22222" },
    "700": { "id": 700, "name": "Свойства", "color": "8B0000" },
    "701": { "id": 701, "name": "Элемент свойства", "color": "FFC0CB" },
    "740": { "id": 740, "name": "Мера дозирования", "color": "FFB6C1" },
    "741": { "id": 741, "name": "Тип источника данных", "color": "FF69B4" },
    "742": { "id": 742, "name": "Метод предоставления данных", "color": "FF1493" },
    "750": { "id": 750, "name": "Нутриент", "color": "C71585" },
    "751": { "id": 751, "name": "Макронутриент", "color": "DB7093" },
    "752": { "id": 752, "name": "Mикpoнутpиeнт", "color": "ADFF2F" },
    "759": { "id": 759, "name": "Многокомпонентный витаминный и/или минеральный комплекс", "color": "7FFF00" },
    "760": { "id": 760, "name": "Продукт питания (элемент классификатора)", "color": "7CFC00" },
    "761": { "id": 761, "name": "Атрибут продукта питания", "color": "00FF00" },
    "762": { "id": 762, "name": "Название продукта питания", "color": "32CD32" },
    "763": { "id": 763, "name": " Способ приготовления пищи", "color": "98FB98" },
    "765": { "id": 765, "name": "Режим питания", "color": "5db4cc" },
    "766": { "id": 766, "name": "Характер продукта питания", "color": "00FA9A" },
    "770": { "id": 770, "name": "Группа продуктов питания", "color": "00FF7F" },
    "771": { "id": 771, "name": "Классификация по коду LanguaL", "color": "3CB371" },
    "777": { "id": 777, "name": "Часть продукта", "color": "2E8B57" },
    "779": { "id": 779, "name": "Продукт питания (образец продукта с уточнением производителя)", "color": "228B22" },
    "800": { "id": 800, "name": "Функциональный признак или физиологический процесс", "color": "008000" },
    "801": { "id": 801, "name": "Функциональный признак (базовый)", "color": "006400" },
    "802": { "id": 802, "name": "--- Изменение функционального признака", "color": "9ACD32" },
    "820": { "id": 820, "name": "Параметрический признак", "color": "6B8E23" },
    "821": { "id": 821, "name": "Параметрический признак (базовый)", "color": "808000" },
    "822": { "id": 822, "name": "Дискретный признака", "color": "556B2F" },
    "830": { "id": 830, "name": "Бактерия", "color": "66CDAA" },
    "831": { "id": 831, "name": "Вирус", "color": "8FBC8F" },
    "832": { "id": 832, "name": "Животные", "color": "20B2AA" },
    "900": { "id": 900, "name": "Источник информации - Научная публикация", "color": "008B8B" },
    "901": { "id": 901, "name": "Источник информации (локальный - descriptions)", "color": "008080" },
    "902": { "id": 902, "name": "Источник данных - Таблица", "color": "FFA07A" },
    "903": { "id": 903, "name": " Поле таблицы источника информации", "color": "FF7F50" },
    "904": { "id": 904, "name": "Источник информации - Справочник", "color": "FF6347" },
    "905": { "id": 905, "name": "Название рубрики в справочнике", "color": "FF4500" },
    "906": { "id": 906, "name": " Внешние источник (ресурсы прослеживающийся автоматически)", "color": "FF8C00" },
    "907": { "id": 907, "name": "Название рубрики в научной публикации", "color": "FFA500" },
    "910": { "id": 910, "name": "Диапазон времени", "color": "FFD700" },
    "911": { "id": 911, "name": "Прогноз", "color": "FFFF00" },
    "912": { "id": 912, "name": "Исход", "color": "5ed3ff" },
    "919": { "id": 919, "name": "Синдром (симптомокомплекс)", "color": "9ed563" },
    "920": { "id": 920, "name": "Патологический признак", "color": "5F9EA0" },
    "921": { "id": 921, "name": "Запускающий патологический признак", "color": "FFEFD5" },
    "922": { "id": 922, "name": "Фактор риска", "color": "FFE4B5" },
    "925": { "id": 925, "name": "Вид товара", "color": "FFDAB9" },
    "929": { "id": 929, "name": "Материал", "color": "EEE8AA" },
    "930": { "id": 930, "name": "Отклонение от нормы, выявленные при наблюдении", "color": "F0E68C" },
    "931": { "id": 931, "name": "Изделия медицинского назначения", "color": "BDB76B" },
    "932": { "id": 932, "name": "Медицинское оборудование", "color": "E6E6FA" },
    "933": { "id": 933, "name": "Цифровое устройство", "color": "D8BFD8" },
    "934": { "id": 934, "name": "Имплантат", "color": "DDA0DD" },
    "937": { "id": 937, "name": "Штат/Край/Область", "color": "EE82EE" },
    "938": { "id": 938, "name": "Населенный пункт", "color": "DA70D6" },
    "939": { "id": 939, "name": "Регион", "color": "FF00FF" },
    "940": { "id": 940, "name": "Страна", "color": "FF00FF" },
    "941": { "id": 941, "name": "Части света", "color": "BA55D3" },
    "942": { "id": 942, "name": "Фармкомпания", "color": "9370DB" },
    "943": { "id": 943, "name": "Медицинское учреждение", "color": "8A2BE2" },
    "944": { "id": 944, "name": "Аптека", "color": "9400D3" },
    "945": { "id": 945, "name": "Аптечный пункт", "color": "9932CC" },
    "946": { "id": 946, "name": "Аптечный киоск", "color": "8B008B" },
    "947": { "id": 947, "name": "Аптечный магазин", "color": "800080" },
    "948": { "id": 948, "name": "Аптечная сеть", "color": "4B0082" },
    "949": { "id": 949, "name": "Отделение ЛПУ (экземпляр)", "color": "6A5ACD" },
    "950": { "id": 950, "name": "Лечебно-профилактическое учреждение", "color": "483D8B" },
    "951": { "id": 951, "name": "Пользователь", "color": "00FFFF" },
    "952": { "id": 952, "name": "Врач", "color": "00FFFF" },
    "953": { "id": 953, "name": "Провизор", "color": "E0FFFF" },
    "954": { "id": 954, "name": "Медсестра", "color": "AFEEEE" },
    "955": { "id": 955, "name": "Специальность", "color": "7FFFD4" },
    "956": { "id": 956, "name": "Специалист привязанный к кабинету (экземпляр)", "color": "40E0D0" },
    "957": { "id": 957, "name": "Специализация", "color": "48D1CC" },
    "958": { "id": 958, "name": "Должность", "color": "00CED1" },
    "959": { "id": 959, "name": "Производитель медицинского изделия", "color": "5F9EA0" },
    "960": { "id": 960, "name": "Ассоциация", "color": "4682B4" },
    "961": { "id": 961, "name": "Сообщество", "color": "B0C4DE" },
    "962": { "id": 962, "name": "Общественная организация", "color": "B0E0E6" },
    "963": { "id": 963, "name": "Группа компаний", "color": "ADD8E6" },
    "964": { "id": 964, "name": "Компания", "color": "87CEEB" },
    "965": { "id": 965, "name": "Департамент", "color": "87CEFA" },
    "966": { "id": 966, "name": "Отдел", "color": "00BFFF" },
    "967": { "id": 967, "name": "Организация здравоохранения", "color": "1E90FF" },
    "968": { "id": 968, "name": "Тип учреждения", "color": "6495ED" },
    "969": { "id": 969, "name": "Тип отделения/подразделения/кабинета/блока лечебного учреждения", "color": "7B68EE" },
    "970": { "id": 970, "name": "ВУЗ", "color": "4169E1" },
    "971": { "id": 971, "name": "Образование", "color": "0000FF" },
    "972": { "id": 972, "name": "Факультет", "color": "0000CD" },
    "973": { "id": 973, "name": "Место прохождения обучения/специализации/написание научной работы", "color": "00008B" },
    "974": { "id": 974, "name": "Занимаемая должность привязанная к месту работы", "color": "000080" },
    "975": { "id": 975, "name": "Связь с научной работой", "color": "191970" },
    "976": { "id": 976, "name": "Научная работа", "color": "FFF8DC" },
    "977": { "id": 977, "name": "Комплексные темы", "color": "FFEBCD" },
    "978": { "id": 978, "name": "Связь с комплексной темой", "color": "FFE4C4" },
    "980": { "id": 980, "name": "Лаборатория", "color": "FFDEAD" },
    "990": { "id": 990, "name": "Уточняющий признак концепта (характер)", "color": "00b652" },
    "991": { "id": 991, "name": "Элемент уточняющего признака", "color": "DEB887" },
    "1000": { "id": 1000, "name": "Синонимия - отношение эквивалентности между концептми", "color": "D2B48C" },
    "1001": { "id": 1001, "name": "Антонимия - концепты противоположные по смыслу", "color": "BC8F8F" },
    "1002": { "id": 1002, "name": "Омонимия - концепты разные по значению, но одинаковые по звучанию и написанию", "color": "F4A460" },
    "1003": { "id": 1003, "name": "Полисемия", "color": "DAA520" },
    "1004": { "id": 1004, "name": "Взаимоисключающие пары или группы", "color": "B8860B" },
    "1009": { "id": 1009, "name": "Связь с граммемой", "color": "CD853F" },
    "1010": { "id": 1010, "name": "Отношение категоризации", "color": "D2691E" },
    "1041": { "id": 1041, "name": "---", "color": "8B4513" },
    "1050": { "id": 1050, "name": "Часть-целое (Гипероним-гипоним)", "color": "A0522D" },
    "1051": { "id": 1051, "name": "Гипероним - гипоним", "color": "A52A2A" },
    "1052": { "id": 1052, "name": "Холоним - мероним", "color": "800000" },
    "1053": { "id": 1053, "name": "Переход_Т (техническая связь для группировки элементов)", "color": "A0522D" },
    "1054": { "id": 1054, "name": "Кругозор инструмента онтологии", "color": "F08080" },
    "1055": { "id": 1055, "name": "Права доступа к концептам, разделам, инструментам и типам", "color": "FA8072" },
    "1056": { "id": 1056, "name": "Расспределение задач экспертной группе", "color": "E9967A" },
    "1060": { "id": 1060, "name": "---", "color": "FFA07A" },
    "1061": { "id": 1061, "name": "Гипероним-гипоним (с переходом на образец)", "color": "DC143C" },
    "1062": { "id": 1062, "name": "Возможные промежуточные признаки (переход)", "color": "FF0000" },
    "1063": { "id": 1063, "name": "Возможные характеристики (переход)", "color": "B22222" },
    "1064": { "id": 1064, "name": "Возможные классификации (переход)", "color": "8B0000" },
    "1065": { "id": 1065, "name": "Возможные атрибуты ключевых концептов (для SofTrust)", "color": "FFC0CB" },
    "1070": { "id": 1070, "name": "это есть", "color": "FFB6C1" },
    "1071": { "id": 1071, "name": "где/чего", "color": "FF69B4" },
    "1072": { "id": 1072, "name": "---", "color": "FF1493" },
    "1073": { "id": 1073, "name": "---", "color": "C71585" },
    "1074": { "id": 1074, "name": "---", "color": "DB7093" },
    "1075": { "id": 1075, "name": "---", "color": "ADFF2F" },
    "1076": { "id": 1076, "name": "---", "color": "7FFF00" },
    "1077": { "id": 1077, "name": "---", "color": "7CFC00" },
    "1080": { "id": 1080, "name": "---", "color": "00FF00" },
    "1100": { "id": 1100, "name": "Это есть сочетание признаков", "color": "32CD32" },
    "1101": { "id": 1101, "name": "Групповая принадлежность", "color": "98FB98" },
    "1102": { "id": 1102, "name": "Патологический признак _Т20", "color": "5db4cc" },
    "1103": { "id": 1103, "name": "---", "color": "00FA9A" },
    "1104": { "id": 1104, "name": "Патологический признак_Т40", "color": "00FF7F" },
    "1105": { "id": 1105, "name": "Внешние патогенные факторы _Т20", "color": "3CB371" },
    "1106": { "id": 1106, "name": "Внешний фактор_Т20", "color": "2E8B57" },
    "1107": { "id": 1107, "name": "Физикальный метод исследования_Т20", "color": "228B22" },
    "1120": { "id": 1120, "name": "Патологический признак_Т30", "color": "008000" },
    "1200": { "id": 1200, "name": "SNOMED CT", "color": "006400" },
    "1201": { "id": 1201, "name": "Структурно-анатомический признака_Т20", "color": "9ACD32" },
    "1202": { "id": 1202, "name": "Анатомический признак латерализованный", "color": "6B8E23" },
    "1203": { "id": 1203, "name": "Структурно-анатомический признака с указанной принадлежностью_Т10", "color": "808000" },
    "1204": { "id": 1204, "name": "Структурно-анатомический признака с указанной ориентацией_Т5", "color": "556B2F" },
    "1300": { "id": 1300, "name": "Связь с источником литературы", "color": "66CDAA" },
    "1301": { "id": 1301, "name": "---", "color": "8FBC8F" },
    "1302": { "id": 1302, "name": "Функциональный признака с уточнением", "color": "20B2AA" },
    "1310": { "id": 1310, "name": "Связь с исследуемым биоматериалом", "color": "008B8B" },
    "1311": { "id": 1311, "name": "Связь с методом исследования", "color": "008080" },
    "1312": { "id": 1312, "name": "Параметрический признака с уточнением", "color": "FFA07A" },
    "1320": { "id": 1320, "name": "Анамнезтический фактор_Т20", "color": "FF7F50" },
    "1350": { "id": 1350, "name": "Кодирование по МКБ-10", "color": "FF6347" },
    "1351": { "id": 1351, "name": "оценить уровень клинической рекомендации", "color": "FF4500" },
    "1352": { "id": 1352, "name": "оптимизация параметра", "color": "FF8C00" },
    "1400": { "id": 1400, "name": "Уточнение локализации патологического признака", "color": "FFA500" },
    "1401": { "id": 1401, "name": "Характер отношения или условия развития", "color": "FFD700" },
    "1402": { "id": 1402, "name": "Влияние", "color": "FFFF00" },
    "1403": { "id": 1403, "name": "Распространенность", "color": "5ed3ff" },
    "1404": { "id": 1404, "name": "Уточнение (персонализация)", "color": "9ed563" },
    "1405": { "id": 1405, "name": "Динамика процесса", "color": "5F9EA0" },
    "1406": { "id": 1406, "name": "Описание топографических отношений между структурными признаками", "color": "FFEFD5" },
    "1407": { "id": 1407, "name": "Связь с параметрическими признаками", "color": "FFE4B5" },
    "1408": { "id": 1408, "name": "Функция", "color": "FFDAB9" },
    "1409": { "id": 1409, "name": "Кровоснабжение", "color": "EEE8AA" },
    "1410": { "id": 1410, "name": "Иннервация", "color": "F0E68C" },
    "1411": { "id": 1411, "name": "Свойства _связь", "color": "BDB76B" },
    "1412": { "id": 1412, "name": "Предшественник", "color": "E6E6FA" },
    "1413": { "id": 1413, "name": "Связь с манекеном", "color": "D8BFD8" },
    "1414": { "id": 1414, "name": "Условия развития, возникновения, проявления или применения", "color": "DDA0DD" },
    "1415": { "id": 1415, "name": "Лимфоотток", "color": "EE82EE" },
    "1416": { "id": 1416, "name": "Поток сигнала", "color": "DA70D6" },
    "1417": { "id": 1417, "name": "Связь с функциональной единицей (с шаблоном)", "color": "FF00FF" },
    "1418": { "id": 1418, "name": "Связь с нейронами (цитоархитектоника)", "color": "FF00FF" },
    "1419": { "id": 1419, "name": "Связь с пучком/трактом", "color": "BA55D3" },
    "1500": { "id": 1500, "name": "Причинно-следственная связь", "color": "9370DB" },
    "1501": { "id": 1501, "name": "Причинно-следственная связь (Фактор риска)", "color": "8A2BE2" },
    "1502": { "id": 1502, "name": "Причинно-следственная связь (Клиническая картина, проявления заболевания)", "color": "9400D3" },
    "1503": { "id": 1503, "name": "Причинно-следственная связь (Патологическая анатомия)", "color": "9932CC" },
    "1504": { "id": 1504, "name": "Причинно-следственная связь (влияние на предрасположенности к изменению кожных покровов)", "color": "8B008B" },
    "1505": { "id": 1505, "name": "Причинно-следственная связь (Осложнение)", "color": "800080" },
    "1506": { "id": 1506, "name": "Причинно-следственная связь (следствие мутации)", "color": "4B0082" },
    "1507": { "id": 1507, "name": "Причинно-следственная связь (непереносимость лекарственных средств)", "color": "6A5ACD" },
    "1508": { "id": 1508, "name": "Причинно-следственная связь (непереносимость продуктов питания и нутриентов)", "color": "483D8B" },
    "1509": { "id": 1509, "name": "Причинно-следственная связь (влияние на предрасположенности к физическим нагрузкам)", "color": "00FFFF" },
    "1510": { "id": 1510, "name": "Побочный эффект", "color": "00FFFF" },
    "1511": { "id": 1511, "name": "Причинно-следственная связь, проявление мутации (аллель - фенотип)", "color": "E0FFFF" },
    "1512": { "id": 1512, "name": "Причинно-следственная связь (недостаточность витаминов и микроэлементов)", "color": "AFEEEE" },
    "1513": { "id": 1513, "name": "Влияние на метаболизм", "color": "7FFFD4" },
    "1514": { "id": 1514, "name": "Причинно-следственная связь (Этиология-Патогенез)", "color": "40E0D0" },
    "1520": { "id": 1520, "name": "Диагностика", "color": "48D1CC" },
    "1530": { "id": 1530, "name": "Дифференциальная диагностика", "color": "00CED1" },
    "1540": { "id": 1540, "name": "Схема лечения", "color": "5F9EA0" },
    "1541": { "id": 1541, "name": "Цель лечения", "color": "4682B4" },
    "1550": { "id": 1550, "name": "Профилактика (факторы протекции)", "color": "B0C4DE" },
    "1551": { "id": 1551, "name": "Эффективность диеты", "color": "B0E0E6" },
    "1560": { "id": 1560, "name": "Осложнения", "color": "ADD8E6" },
    "1565": { "id": 1565, "name": "Присвоение балльной оценки ", "color": "87CEEB" },
    "1570": { "id": 1570, "name": "Прогноз заболевания", "color": "87CEFA" },
    "1575": { "id": 1575, "name": "Связь специальности с патологическим признаком", "color": "00BFFF" },
    "1576": { "id": 1576, "name": "Связь специальности с пол-возрастными группами", "color": "1E90FF" },
    "1577": { "id": 1577, "name": " Связь с жалобами", "color": "6495ED" },
    "1578": { "id": 1578, "name": "Связь с анамнестическими данными", "color": "7B68EE" },
    "1579": { "id": 1579, "name": "Связь с инструментальными методами исследования ", "color": "4169E1" },
    "1580": { "id": 1580, "name": "Классификация клинической рекомендации как патологического признака", "color": "0000FF" },
    "1581": { "id": 1581, "name": "Связь с лабораторными методами исследования", "color": "0000CD" },
    "1582": { "id": 1582, "name": "Связь с результатами исследования", "color": "00008B" },
    "1600": { "id": 1600, "name": "действующее вещество - торговое название (ДВ-ТН)", "color": "000080" },
    "1601": { "id": 1601, "name": "ДВ --- ФГ", "color": "191970" },
    "1602": { "id": 1602, "name": "нозологическая группа - действующее вещество (МКБ10-ДВ)", "color": "FFF8DC" },
    "1603": { "id": 1603, "name": "Связь между ДВ и нозологической группой", "color": "FFEBCD" },
    "1604": { "id": 1604, "name": "ГН --- ДВ", "color": "FFE4C4" },
    "1605": { "id": 1605, "name": "ДВк --- ДВ", "color": "FFDEAD" },
    "1606": { "id": 1606, "name": "действующие вещества - фарм действия (ДВ-ФД)", "color": "00b652" },
    "1607": { "id": 1607, "name": "фарм препараты - действующие вещества (ФП - ДВ)", "color": "DEB887" },
    "1608": { "id": 1608, "name": "торговое название - фарм препараты (ТН-ФП)", "color": "D2B48C" },
    "1609": { "id": 1609, "name": "фарм препараты - фармгруппа (ФП-ФГ)", "color": "BC8F8F" },
    "1610": { "id": 1610, "name": "ДВ-Типы дозировок", "color": "F4A460" },
    "1611": { "id": 1611, "name": "торговое название - АТХ (ТН-АТХ)", "color": "DAA520" },
    "1612": { "id": 1612, "name": "торговое название - фармгруппа (ТН-ФГ)", "color": "B8860B" },
    "1613": { "id": 1613, "name": "фарм препараты - АТХ (ФП - АТХ)", "color": "CD853F" },
    "1614": { "id": 1614, "name": "нозологическая группа - торговое название (МКБ10-ТН)", "color": "D2691E" },
    "1615": { "id": 1615, "name": "нозологическая группа - фармгруппа (МКБ10-ФП)", "color": "8B4513" },
    "1616": { "id": 1616, "name": "фармпрепарат - лекарственная форма (ФП-ЛФ)", "color": "A0522D" },
    "1617": { "id": 1617, "name": "взаимодействие между ЛС", "color": "A52A2A" },
    "1618": { "id": 1618, "name": "механизм взаимодействия ЛС", "color": "800000" },
    "1619": { "id": 1619, "name": "Регуляция фермента метаболизма (Рег-&gt;CYP)", "color": "A0522D" },
    "1620": { "id": 1620, "name": "действующие вещества - регуляция метаболизма (ДВ-&gt;Рег--CYP)", "color": "F08080" },
    "1621": { "id": 1621, "name": "ДВ --- АТХ", "color": "FA8072" },
    "1622": { "id": 1622, "name": "Состав_Активные ингредиенты", "color": "E9967A" },
    "1623": { "id": 1623, "name": "противопоказания ДВ/ФГ/АТХ-НП", "color": "FFA07A" },
    "1624": { "id": 1624, "name": "Таргетная фармакотерапия", "color": "DC143C" },
    "1625": { "id": 1625, "name": "Тип лабораторного метода исследования", "color": "FF0000" },
    "1626": { "id": 1626, "name": "уточнение типа взаимодействия ЛС", "color": "B22222" },
    "1627": { "id": 1627, "name": "способ применения лекарственной формы ЛФ-СП", "color": "8B0000" },
    "1628": { "id": 1628, "name": "путь введения действующего вещества (ДВ-СП)", "color": "FFC0CB" },
    "1629": { "id": 1629, "name": "ДВ или ТН -&gt; ЛФ", "color": "FFB6C1" },
    "1630": { "id": 1630, "name": "ТН-ЛФ ---- дозировка", "color": "FF69B4" },
    "1631": { "id": 1631, "name": "связь с субстанциями для приготовления ЛС", "color": "FF1493" },
    "1632": { "id": 1632, "name": "ФП -&gt; УП", "color": "C71585" },
    "1640": { "id": 1640, "name": "ФП -&gt; Страна регистрации =&gt; лицензия", "color": "DB7093" },
    "1641": { "id": 1641, "name": "лицензия -&gt; фармкомпния", "color": "ADFF2F" },
    "1642": { "id": 1642, "name": "Производитель (ФП/Продукт -> Юр.лицо)", "color": "7FFF00" },
    "1643": { "id": 1643, "name": "Упаковщик ФП (ФА-&gt;Юр.лицо)", "color": "7CFC00" },
    "1644": { "id": 1644, "name": "Разрешение на использование ЛС", "color": "00FF00" },
    "1700": { "id": 1700, "name": "Цена и наличие лекарственного средства (Аптека-ФП)", "color": "32CD32" },
    "1730": { "id": 1730, "name": "Мутация -> Тип мутации", "color": "98FB98" },
    "1732": { "id": 1732, "name": "Генотип - бинарная комбинация из гаплотипов", "color": "5db4cc" },
    "1735": { "id": 1735, "name": "Кодон -> Аминокислота (кодирование аминокислоты)", "color": "00FA9A" },
    "1738": { "id": 1738, "name": "Панель -> Ген", "color": "00FF7F" },
    "1739": { "id": 1739, "name": "Мутация -> Изоформа белка", "color": "3CB371" },
    "1740": { "id": 1740, "name": "Мутация -> Ген", "color": "2E8B57" },
    "1741": { "id": 1741, "name": "Ген -> Белок/РНК (кодирование белка или РНК)", "color": "228B22" },
    "1742": { "id": 1742, "name": "Гаплотип - аллель вариации", "color": "008000" },
    "1743": { "id": 1743, "name": "Рекомендации (связь)", "color": "006400" },
    "1744": { "id": 1744, "name": "Регион возникновения (Гаплогруппа -> Регион/Страна)", "color": "9ACD32" },
    "1745": { "id": 1745, "name": "Ареал распространения носителей гаплогруппы (Гаплогруппа -> Регион/Страна)", "color": "6B8E23" },
    "1746": { "id": 1746, "name": "Известные носители гаплогруппы (Гаплогруппа -> Известный носитель)", "color": "808000" },
    "1747": { "id": 1747, "name": "Маркеры мутаций (Гаплогруппа -> Маркер мцтации)", "color": "556B2F" },
    "1748": { "id": 1748, "name": "Маркировка мутаций/аллелей (Маркер мутации -> Мутация-Аллель)", "color": "66CDAA" },
    "1749": { "id": 1749, "name": "Ген -> Хромосома/Плечо/Сиквенс", "color": "8FBC8F" },
    "1750": { "id": 1750, "name": "Продукт---Нутриент (значение)", "color": "20B2AA" },
    "1751": { "id": 1751, "name": "Продукт --- Группа", "color": "008B8B" },
    "1752": { "id": 1752, "name": "(Продукт---Нутриент) ---&gt;  (Продукт)", "color": "008080" },
    "1753": { "id": 1753, "name": "(Стол---Продукт) показания/противопоказания", "color": "FFA07A" },
    "1754": { "id": 1754, "name": "Нутриент - ключевой продукт питания", "color": "FF7F50" },
    "1755": { "id": 1755, "name": "Связь с режимом питания", "color": "FF6347" },
    "1756": { "id": 1756, "name": "Энергетическая ценность", "color": "FF4500" },
    "1758": { "id": 1758, "name": "Продукт питания (производитель) --- Продукт питания (классификатор)", "color": "FF8C00" },
    "1759": { "id": 1759, "name": "Продукт питания --- Ингредиенты (состав)", "color": "FFA500" },
    "1760": { "id": 1760, "name": "материал входящий в состав импланта", "color": "FFD700" },
    "1761": { "id": 1761, "name": "химический элемент входящий в состав материала", "color": "FFFF00" },
    "1800": { "id": 1800, "name": "Книга", "color": "5ed3ff" },
    "1801": { "id": 1801, "name": "Объект книги/документа", "color": "9ed563" },
    "1802": { "id": 1802, "name": "Шаблон формата объекта в книге", "color": "5F9EA0" },
    "1803": { "id": 1803, "name": "Настройки книги и входные данные", "color": "FFEFD5" },
    "1804": { "id": 1804, "name": "Условия срабатывания и исключения", "color": "FFE4B5" },
    "1850": { "id": 1850, "name": "Связь Книга-Объект", "color": "FFDAB9" },
    "1851": { "id": 1851, "name": "Связь с условием срабатывания или исключения", "color": "EEE8AA" },
    "1900": { "id": 1900, "name": "Система единиц измерения", "color": "F0E68C" },
    "1901": { "id": 1901, "name": "Преобразование единиц измерения", "color": "BDB76B" },
    "1902": { "id": 1902, "name": "Математическое выражение", "color": "E6E6FA" },
    "1903": { "id": 1903, "name": "Задача для экспертизы", "color": "D8BFD8" },
    "1904": { "id": 1904, "name": "разработал", "color": "DDA0DD" },
    "1905": { "id": 1905, "name": "Регулярное выражение", "color": "EE82EE" },
    "1909": { "id": 1909, "name": "Тег (id тега  используется для разметки в программном коде)", "color": "DA70D6" },
    "1910": { "id": 1910, "name": "Скрипт", "color": "FF00FF" },
    "1911": { "id": 1911, "name": "Обращение к методу", "color": "FF00FF" },
    "1912": { "id": 1912, "name": "Класс", "color": "BA55D3" },
    "1913": { "id": 1913, "name": "Свойства класса или ключ", "color": "9370DB" },
    "1914": { "id": 1914, "name": "Правило", "color": "8A2BE2" },
    "1915": { "id": 1915, "name": "Сценарий", "color": "9400D3" },
    "1916": { "id": 1916, "name": "Парсер", "color": "9932CC" },
    "1917": { "id": 1917, "name": "Шаблон (ключ)", "color": "8B008B" },
    "1918": { "id": 1918, "name": "Чекер", "color": "800080" },
    "1919": { "id": 1919, "name": "Триггер/Страница", "color": "4B0082" },
    "1920": { "id": 1920, "name": "Использовать инструмент онтологии", "color": "6A5ACD" },
    "1921": { "id": 1921, "name": "Извлекает знания из источника", "color": "483D8B" },
    "1922": { "id": 1922, "name": "Извлекает знания из поля или рубрики", "color": "00FFFF" },
    "1923": { "id": 1923, "name": "Указывает на условия обработки текста", "color": "00FFFF" },
    "1924": { "id": 1924, "name": "Указывает на название поля таблицы или рубрику в публикации", "color": "E0FFFF" },
    "1925": { "id": 1925, "name": "Анкета", "color": "AFEEEE" },
    "1929": { "id": 1929, "name": "Связь с заголовком поля таблицы", "color": "7FFFD4" },
    "1930": { "id": 1930, "name": "Связь с типом концепта", "color": "40E0D0" },
    "1931": { "id": 1931, "name": "Связь с концептом", "color": "48D1CC" },
    "1932": { "id": 1932, "name": "Связь со сценарием", "color": "00CED1" },
    "1933": { "id": 1933, "name": "Связь с шаблоном", "color": "5F9EA0" },
    "1934": { "id": 1934, "name": "Связь с регулярным выражением", "color": "4682B4" },
    "1935": { "id": 1935, "name": "Связь с сенсором", "color": "B0C4DE" },
    "1936": { "id": 1936, "name": "Переход/Коллектор", "color": "B0E0E6" },
    "1937": { "id": 1937, "name": "Связь с триггером/страницей", "color": "ADD8E6" },
    "1938": { "id": 1938, "name": "Условия срабатывания сенсора", "color": "87CEEB" },
    "1939": { "id": 1939, "name": "Связь с нозологией", "color": "87CEFA" },
    "1940": { "id": 1940, "name": "Связь с чекером", "color": "00BFFF" },
    "1941": { "id": 1941, "name": "Связь со страницей интерфейса", "color": "1E90FF" },
    "1942": { "id": 1942, "name": "Связь с документом", "color": "6495ED" },
    "1943": { "id": 1943, "name": "Обращение к разделу для поиска концепта по техническим полям (id, code, varchar и т.п.)", "color": "7B68EE" },
    "1944": { "id": 1944, "name": "Обращение к разделу для поиска концепта по названию", "color": "4169E1" },
    "1945": { "id": 1945, "name": "Обращение к парсеру для извлечения концептов из текста", "color": "0000FF" },
    "1946": { "id": 1946, "name": "Запись данных в раздел", "color": "0000CD" },
    "1969": { "id": 1969, "name": "Связь с контекстом", "color": "00008B" },
    "1970": { "id": 1970, "name": "Связь библиотеки с типом концепта (Lib - Livel)", "color": "000080" },
    "1971": { "id": 1971, "name": "Связь со сцепленными разделами (Lib - Lib)", "color": "191970" },
    "1972": { "id": 1972, "name": "Связь с БАЗОВЫМ Сероидом", "color": "FFF8DC" },
    "1973": { "id": 1973, "name": "Связь с КЛЮЧЕВЫМ Сероидом", "color": "FFEBCD" },
    "1974": { "id": 1974, "name": "Связь с Белоидом", "color": "FFE4C4" },
    "1975": { "id": 1975, "name": "Связь с типом концепта", "color": "FFDEAD" },
    "1976": { "id": 1976, "name": "Связь от левого плеча белона", "color": "00b652" },
    "1977": { "id": 1977, "name": "Связь от правого плеча белона", "color": "DEB887" },
    "1978": { "id": 1978, "name": "Связь с описанием (description)", "color": "D2B48C" },
    "1979": { "id": 1979, "name": "Уточнение типа концепта", "color": "BC8F8F" },
    "1980": { "id": 1980, "name": "Связь с дополнительными полями (additional)", "color": "F4A460" },
    "1981": { "id": 1981, "name": "Связь с динамическими полями (dyn_)", "color": "DAA520" },
    "1988": { "id": 1988, "name": "Разделы по которым отслеживаются изменения СУБД", "color": "B8860B" },
    "1989": { "id": 1989, "name": "Доступные разделы, сателиты и инструменты для выгрузки и индексации", "color": "CD853F" },
    "1990": { "id": 1990, "name": " Связь с значениями ребра", "color": "D2691E" },
    "1991": { "id": 1991, "name": "Связь с первым значением ребра - value_a", "color": "8B4513" },
    "1992": { "id": 1992, "name": "Связь со вторым значением ребра - value_b", "color": "A0522D" },
    "1993": { "id": 1993, "name": "Связь с третьим значением ребра - value_c", "color": "A52A2A" },
    "1994": { "id": 1994, "name": "Связь с четвертым значением ребра - value_d", "color": "800000" },
    "2000": { "id": 2000, "name": "Документ, карта, книга и т.п.", "color": "A0522D" },
    "2001": { "id": 2001, "name": "Модуль приложения или сервиса", "color": "F08080" },
    "2050": { "id": 2050, "name": "Сценарий маршрута", "color": "FA8072" },
    "2051": { "id": 2051, "name": "Кабинет", "color": "E9967A" },
    "2052": { "id": 2052, "name": "Маршрут", "color": "FFA07A" },
    "2100": { "id": 2100, "name": "Технологическая карта", "color": "DC143C" },
    "3000": { "id": 3000, "name": "Модель пациента (типовой клинический случай)", "color": "FF0000" },
    "3001": { "id": 3001, "name": "Пациент", "color": "B22222" },
    "3002": { "id": 3002, "name": "Протокол события", "color": "8B0000" },
    "3003": { "id": 3003, "name": "Связь с автором протокола", "color": "FFC0CB" },
    "3004": { "id": 3004, "name": "Событие не параметрическое", "color": "FFB6C1" },
    "3005": { "id": 3005, "name": "Показатель параметрический", "color": "FF69B4" },
    "3006": { "id": 3006, "name": "Льгота", "color": "FF1493" },
    "3009": { "id": 3009, "name": "Календарь приема", "color": "C71585" },
    "3010": { "id": 3010, "name": "Фармакотерапия/Рацион", "color": "DB7093" },
    "3011": { "id": 3011, "name": "Доза", "color": "ADFF2F" },
    "3012": { "id": 3012, "name": "связь с лекарственной формой", "color": "7FFF00" },
    "3013": { "id": 3013, "name": "Способ применения", "color": "7CFC00" },
    "3014": { "id": 3014, "name": "Кратность приема", "color": "00FF00" },
    "3015": { "id": 3015, "name": "Длительность приема", "color": "32CD32" },
    "3019": { "id": 3019, "name": "Связь указывает на календарь (дату)", "color": "98FB98" },
    "5003": { "id": 5003, "name": "finding", "color": "5db4cc" },
    "5004": { "id": 5004, "name": "disorder", "color": "00FA9A" },
    "5005": { "id": 5005, "name": "procedure", "color": "00FF7F" },
    "5006": { "id": 5006, "name": "qualifier value", "color": "3CB371" },
    "5007": { "id": 5007, "name": "organism", "color": "2E8B57" },
    "5008": { "id": 5008, "name": "ethnic group", "color": "228B22" },
    "5009": { "id": 5009, "name": "observable entity", "color": "008000" },
    "5010": { "id": 5010, "name": "body structure", "color": "006400" },
    "5011": { "id": 5011, "name": "occupation", "color": "9ACD32" },
    "5012": { "id": 5012, "name": "substance", "color": "6B8E23" },
    "5013": { "id": 5013, "name": "physical force", "color": "808000" },
    "5014": { "id": 5014, "name": "morphologic abnormality", "color": "556B2F" },
    "5015": { "id": 5015, "name": "product", "color": "66CDAA" },
    "5016": { "id": 5016, "name": "cell", "color": "8FBC8F" },
    "5017": { "id": 5017, "name": "physical object", "color": "20B2AA" },
    "5018": { "id": 5018, "name": "environment", "color": "008B8B" },
    "5019": { "id": 5019, "name": "situation", "color": "008080" },
    "5020": { "id": 5020, "name": "event", "color": "FFA07A" },
    "5021": { "id": 5021, "name": "religion/philosophy", "color": "FF7F50" },
    "5022": { "id": 5022, "name": "cell structure", "color": "FF6347" },
    "5023": { "id": 5023, "name": "attribute", "color": "FF4500" },
    "5024": { "id": 5024, "name": "navigational concept", "color": "FF8C00" },
    "5025": { "id": 5025, "name": "regime/therapy", "color": "FFA500" },
    "5026": { "id": 5026, "name": "social concept", "color": "FFD700" },
    "5027": { "id": 5027, "name": "person", "color": "FFFF00" },
    "5028": { "id": 5028, "name": "life style", "color": "5ed3ff" },
    "5029": { "id": 5029, "name": "linkage concept", "color": "9ed563" },
    "5030": { "id": 5030, "name": "staging scale", "color": "5F9EA0" },
    "5031": { "id": 5031, "name": "tumor staging", "color": "FFEFD5" },
    "5032": { "id": 5032, "name": "specimen", "color": "FFE4B5" },
    "5033": { "id": 5033, "name": "record artifact", "color": "FFDAB9" },
    "5034": { "id": 5034, "name": "assessment scale", "color": "EEE8AA" },
    "5035": { "id": 5035, "name": "SNOMED RT+CTV3", "color": "F0E68C" },
    "5036": { "id": 5036, "name": "geographic location", "color": "BDB76B" },
    "5037": { "id": 5037, "name": "environment / location", "color": "E6E6FA" },
    "5038": { "id": 5038, "name": "inactive concept", "color": "D8BFD8" },
    "5039": { "id": 5039, "name": "special concept", "color": "DDA0DD" },
    "5040": { "id": 5040, "name": "namespace concept", "color": "EE82EE" },
    "5041": { "id": 5041, "name": "racial group", "color": "DA70D6" },
    "5042": { "id": 5042, "name": "link assertion", "color": "FF00FF" },
    "5043": { "id": 5043, "name": "foundation metadata concept", "color": "FF00FF" },
    "5044": { "id": 5044, "name": "core metadata concept", "color": "BA55D3" },
    "5045": { "id": 5045, "name": "metadata", "color": "9370DB" },
    "5100": { "id": 5100, "name": "Системно-органный класс", "color": "8A2BE2" },
    "5101": { "id": 5101, "name": "Групповой термин верхнего уровня", "color": "9400D3" },
    "5102": { "id": 5102, "name": "Термин верхнего уровня", "color": "9932CC" },
    "5103": { "id": 5103, "name": "Предпочтительный термин", "color": "8B008B" },
    "5104": { "id": 5104, "name": "Термин нижнего уровня", "color": "800080" },
    "5200": { "id": 5200, "name": "Chemicals & Drugs", "color": "4B0082" },
    "5201": { "id": 5201, "name": "Genes & Molecular Sequences", "color": "6A5ACD" },
    "5202": { "id": 5202, "name": "Living Beings", "color": "483D8B" },
    "5203": { "id": 5203, "name": "Anatomy", "color": "00FFFF" },
    "5204": { "id": 5204, "name": "Physiology", "color": "00FFFF" },
    "5205": { "id": 5205, "name": "Disorders", "color": "E0FFFF" },
    "5206": { "id": 5206, "name": "Procedures", "color": "AFEEEE" },
    "5207": { "id": 5207, "name": "Phenomena", "color": "7FFFD4" },
    "5208": { "id": 5208, "name": "Activities & Behaviors", "color": "40E0D0" },
    "5209": { "id": 5209, "name": "Objects", "color": "48D1CC" },
    "5210": { "id": 5210, "name": "Devices", "color": "00CED1" },
    "5211": { "id": 5211, "name": "Concepts & Ideas", "color": "5F9EA0" },
    "5212": { "id": 5212, "name": "Geographic Areas", "color": "4682B4" },
    "5213": { "id": 5213, "name": "Occupations", "color": "B0C4DE" },
    "5214": { "id": 5214, "name": "Organizations", "color": "B0E0E6" },
    "5215": { "id": 5215, "name": "Organism", "color": "ADD8E6" },
    "5216": { "id": 5216, "name": "Plant", "color": "87CEEB" },
    "5217": { "id": 5217, "name": null, "color": "87CEFA" },
    "5218": { "id": 5218, "name": "Fungus", "color": "00BFFF" },
    "5219": { "id": 5219, "name": "Virus", "color": "1E90FF" },
    "5220": { "id": 5220, "name": null, "color": "6495ED" },
    "5221": { "id": 5221, "name": "Bacterium", "color": "7B68EE" },
    "5222": { "id": 5222, "name": "Animal", "color": "4169E1" },
    "5223": { "id": 5223, "name": null, "color": "0000FF" },
    "5224": { "id": 5224, "name": "Vertebrate", "color": "0000CD" },
    "5225": { "id": 5225, "name": "Amphibian", "color": "00008B" },
    "5226": { "id": 5226, "name": "Bird", "color": "000080" },
    "5227": { "id": 5227, "name": "Fish", "color": "191970" },
    "5228": { "id": 5228, "name": "Reptile", "color": "FFF8DC" },
    "5229": { "id": 5229, "name": "Mammal", "color": "FFEBCD" },
    "5230": { "id": 5230, "name": "Human", "color": "FFE4C4" },
    "5231": { "id": 5231, "name": "Anatomical Structure", "color": "FFDEAD" },
    "5232": { "id": 5232, "name": "Embryonic Structure", "color": "00b652" },
    "5233": { "id": 5233, "name": "Congenital Abnormality", "color": "DEB887" },
    "5234": { "id": 5234, "name": "Acquired Abnormality", "color": "D2B48C" },
    "5235": { "id": 5235, "name": "Fully Formed Anatomical Structure", "color": "BC8F8F" },
    "5236": { "id": 5236, "name": "Body System", "color": "F4A460" },
    "5237": { "id": 5237, "name": "Body Part, Organ, or Organ Component", "color": "DAA520" },
    "5238": { "id": 5238, "name": "Tissue", "color": "B8860B" },
    "5239": { "id": 5239, "name": "Cell", "color": "CD853F" },
    "5240": { "id": 5240, "name": "Cell Component", "color": "D2691E" },
    "5241": { "id": 5241, "name": null, "color": "8B4513" },
    "5242": { "id": 5242, "name": "Gene or Genome", "color": "A0522D" },
    "5243": { "id": 5243, "name": "Body Location or Region", "color": "A52A2A" },
    "5244": { "id": 5244, "name": "Body Space or Junction", "color": "800000" },
    "5245": { "id": 5245, "name": "Body Substance", "color": "A0522D" },
    "5246": { "id": 5246, "name": "Organism Attribute", "color": "F08080" },
    "5247": { "id": 5247, "name": "Finding", "color": "FA8072" },
    "5248": { "id": 5248, "name": "Laboratory or Test Result", "color": "E9967A" },
    "5249": { "id": 5249, "name": null, "color": "FFA07A" },
    "5250": { "id": 5250, "name": null, "color": "DC143C" },
    "5251": { "id": 5251, "name": "Injury or Poisoning", "color": "FF0000" },
    "5252": { "id": 5252, "name": "Biologic Function", "color": "B22222" },
    "5253": { "id": 5253, "name": "Physiologic Function", "color": "8B0000" },
    "5254": { "id": 5254, "name": "Organism Function", "color": "FFC0CB" },
    "5255": { "id": 5255, "name": "Mental Process", "color": "FFB6C1" },
    "5256": { "id": 5256, "name": "Organ or Tissue Function", "color": "FF69B4" },
    "5257": { "id": 5257, "name": "Cell Function", "color": "FF1493" },
    "5258": { "id": 5258, "name": "Molecular Function", "color": "C71585" },
    "5259": { "id": 5259, "name": "Genetic Function", "color": "DB7093" },
    "5260": { "id": 5260, "name": "Pathologic Function", "color": "ADFF2F" },
    "5261": { "id": 5261, "name": "Disease or Syndrome", "color": "7FFF00" },
    "5262": { "id": 5262, "name": "Mental or Behavioral Dysfunction", "color": "7CFC00" },
    "5263": { "id": 5263, "name": "Cell or Molecular Dysfunction", "color": "00FF00" },
    "5264": { "id": 5264, "name": "Experimental Model of Disease", "color": "32CD32" },
    "5265": { "id": 5265, "name": "Event", "color": "98FB98" },
    "5266": { "id": 5266, "name": "Activity", "color": "5db4cc" },
    "5267": { "id": 5267, "name": "Behavior", "color": "00FA9A" },
    "5268": { "id": 5268, "name": "Social Behavior", "color": "00FF7F" },
    "5269": { "id": 5269, "name": "Individual Behavior", "color": "3CB371" },
    "5270": { "id": 5270, "name": "Daily or Recreational Activity", "color": "2E8B57" },
    "5271": { "id": 5271, "name": "Occupational Activity", "color": "228B22" },
    "5272": { "id": 5272, "name": "Health Care Activity", "color": "008000" },
    "5273": { "id": 5273, "name": "Laboratory Procedure", "color": "006400" },
    "5274": { "id": 5274, "name": "Diagnostic Procedure", "color": "9ACD32" },
    "5275": { "id": 5275, "name": "Therapeutic or Preventive Procedure", "color": "6B8E23" },
    "5276": { "id": 5276, "name": "Research Activity", "color": "808000" },
    "5277": { "id": 5277, "name": "Molecular Biology Research Technique", "color": "556B2F" },
    "5278": { "id": 5278, "name": "Governmental or Regulatory Activity", "color": "66CDAA" },
    "5279": { "id": 5279, "name": "Educational Activity", "color": "8FBC8F" },
    "5280": { "id": 5280, "name": "Machine Activity", "color": "20B2AA" },
    "5281": { "id": 5281, "name": "Phenomenon or Process", "color": "008B8B" },
    "5282": { "id": 5282, "name": "Human-caused Phenomenon or Process", "color": "008080" },
    "5283": { "id": 5283, "name": "Environmental Effect of Humans", "color": "FFA07A" },
    "5284": { "id": 5284, "name": "Natural Phenomenon or Process", "color": "FF7F50" },
    "5285": { "id": 5285, "name": "Entity", "color": "FF6347" },
    "5286": { "id": 5286, "name": "Physical Object", "color": "FF4500" },
    "5287": { "id": 5287, "name": "Manufactured Object", "color": "FF8C00" },
    "5288": { "id": 5288, "name": "Medical Device", "color": "FFA500" },
    "5289": { "id": 5289, "name": "Research Device", "color": "FFD700" },
    "5290": { "id": 5290, "name": null, "color": "FFFF00" },
    "5291": { "id": 5291, "name": "Conceptual Entity", "color": "5ed3ff" },
    "5292": { "id": 5292, "name": "Idea or Concept", "color": "9ed563" },
    "5293": { "id": 5293, "name": "Temporal Concept", "color": "5F9EA0" },
    "5294": { "id": 5294, "name": "Qualitative Concept", "color": "FFEFD5" },
    "5295": { "id": 5295, "name": "Quantitative Concept", "color": "FFE4B5" },
    "5296": { "id": 5296, "name": "Spatial Concept", "color": "FFDAB9" },
    "5297": { "id": 5297, "name": "Geographic Area", "color": "EEE8AA" },
    "5298": { "id": 5298, "name": null, "color": "F0E68C" },
    "5299": { "id": 5299, "name": "Molecular Sequence", "color": "BDB76B" },
    "5300": { "id": 5300, "name": "Nucleotide Sequence", "color": "E6E6FA" },
    "5301": { "id": 5301, "name": "Amino Acid Sequence", "color": "D8BFD8" },
    "5302": { "id": 5302, "name": "Carbohydrate Sequence", "color": "DDA0DD" },
    "5303": { "id": 5303, "name": "Regulation or Law", "color": "EE82EE" },
    "5304": { "id": 5304, "name": "Occupation or Discipline", "color": "DA70D6" },
    "5305": { "id": 5305, "name": "Biomedical Occupation or Discipline", "color": "FF00FF" },
    "5306": { "id": 5306, "name": "Organization", "color": "FF00FF" },
    "5307": { "id": 5307, "name": "Health Care Related Organization", "color": "BA55D3" },
    "5308": { "id": 5308, "name": "Professional Society", "color": "9370DB" },
    "5309": { "id": 5309, "name": "Self-help or Relief Organization", "color": "8A2BE2" },
    "5310": { "id": 5310, "name": "Group", "color": "9400D3" },
    "5311": { "id": 5311, "name": "Professional or Occupational Group", "color": "9932CC" },
    "5312": { "id": 5312, "name": "Population Group", "color": "8B008B" },
    "5313": { "id": 5313, "name": "Family Group", "color": "800080" },
    "5314": { "id": 5314, "name": "Age Group", "color": "4B0082" },
    "5315": { "id": 5315, "name": "Patient or Disabled Group", "color": "6A5ACD" },
    "5316": { "id": 5316, "name": "Group Attribute", "color": "483D8B" },
    "5317": { "id": 5317, "name": "Chemical", "color": "00FFFF" },
    "5318": { "id": 5318, "name": "Chemical Viewed Structurally", "color": "00FFFF" },
    "5319": { "id": 5319, "name": null, "color": "E0FFFF" },
    "5320": { "id": 5320, "name": null, "color": "AFEEEE" },
    "5321": { "id": 5321, "name": null, "color": "7FFFD4" },
    "5322": { "id": 5322, "name": null, "color": "40E0D0" },
    "5323": { "id": 5323, "name": "Organic Chemical", "color": "48D1CC" },
    "5324": { "id": 5324, "name": null, "color": "00CED1" },
    "5325": { "id": 5325, "name": null, "color": "5F9EA0" },
    "5326": { "id": 5326, "name": null, "color": "4682B4" },
    "5327": { "id": 5327, "name": null, "color": "B0C4DE" },
    "5328": { "id": 5328, "name": "Nucleic Acid, Nucleoside, or Nucleotide", "color": "B0E0E6" },
    "5329": { "id": 5329, "name": null, "color": "ADD8E6" },
    "5330": { "id": 5330, "name": "Amino Acid, Peptide, or Protein", "color": "87CEEB" },
    "5331": { "id": 5331, "name": null, "color": "87CEFA" },
    "5332": { "id": 5332, "name": null, "color": "00BFFF" },
    "5333": { "id": 5333, "name": null, "color": "1E90FF" },
    "5334": { "id": 5334, "name": "Chemical Viewed Functionally", "color": "6495ED" },
    "5335": { "id": 5335, "name": "Pharmacologic Substance", "color": "7B68EE" },
    "5336": { "id": 5336, "name": "Biomedical or Dental Material", "color": "4169E1" },
    "5337": { "id": 5337, "name": "Biologically Active Substance", "color": "0000FF" },
    "5338": { "id": 5338, "name": null, "color": "0000CD" },
    "5339": { "id": 5339, "name": "Hormone", "color": "00008B" },
    "5340": { "id": 5340, "name": "Enzyme", "color": "000080" },
    "5341": { "id": 5341, "name": "Vitamin", "color": "191970" },
    "5342": { "id": 5342, "name": null, "color": "FFF8DC" },
    "5343": { "id": 5343, "name": "Immunologic Factor", "color": "FFEBCD" },
    "5344": { "id": 5344, "name": "Indicator, Reagent, or Diagnostic Aid", "color": "FFE4C4" },
    "5345": { "id": 5345, "name": "Hazardous or Poisonous Substance", "color": "FFDEAD" },
    "5346": { "id": 5346, "name": "physically_related_to", "color": "00b652" },
    "5347": { "id": 5347, "name": "part_of", "color": "DEB887" },
    "5348": { "id": 5348, "name": "contains", "color": "D2B48C" },
    "5349": { "id": 5349, "name": "location_of", "color": "BC8F8F" },
    "5350": { "id": 5350, "name": "temporally_related_to", "color": "F4A460" },
    "5351": { "id": 5351, "name": "co-occurs_with", "color": "DAA520" },
    "5352": { "id": 5352, "name": "precedes", "color": "B8860B" },
    "5353": { "id": 5353, "name": "functionally_related_to", "color": "CD853F" },
    "5354": { "id": 5354, "name": "process_of", "color": "D2691E" },
    "5355": { "id": 5355, "name": "carries_out", "color": "8B4513" },
    "5356": { "id": 5356, "name": "interacts_with", "color": "A0522D" },
    "5357": { "id": 5357, "name": "practices", "color": "A52A2A" },
    "5358": { "id": 5358, "name": "produces", "color": "800000" },
    "5359": { "id": 5359, "name": "exhibits", "color": "A0522D" },
    "5360": { "id": 5360, "name": "disrupts", "color": "F08080" },
    "5361": { "id": 5361, "name": "causes", "color": "FA8072" },
    "5362": { "id": 5362, "name": "prevents", "color": "E9967A" },
    "5363": { "id": 5363, "name": "complicates", "color": "FFA07A" },
    "5364": { "id": 5364, "name": "manifestation_of", "color": "DC143C" },
    "5365": { "id": 5365, "name": "affects", "color": "FF0000" },
    "5366": { "id": 5366, "name": "occurs_in", "color": "B22222" },
    "5367": { "id": 5367, "name": "manages", "color": "8B0000" },
    "5368": { "id": 5368, "name": "treats", "color": "FFC0CB" },
    "5369": { "id": 5369, "name": "uses", "color": "FFB6C1" },
    "5370": { "id": 5370, "name": "indicates", "color": "FF69B4" },
    "5371": { "id": 5371, "name": "result_of", "color": "FF1493" },
    "5372": { "id": 5372, "name": "conceptually_related_to", "color": "C71585" },
    "5373": { "id": 5373, "name": "property_of", "color": "DB7093" },
    "5374": { "id": 5374, "name": "conceptual_part_of", "color": "ADFF2F" },
    "5375": { "id": 5375, "name": "evaluation_of", "color": "7FFF00" },
    "5376": { "id": 5376, "name": "measures", "color": "7CFC00" },
    "5377": { "id": 5377, "name": "diagnoses", "color": "00FF00" },
    "5378": { "id": 5378, "name": "assesses_effect_of", "color": "32CD32" },
    "5379": { "id": 5379, "name": "issue_in", "color": "98FB98" },
    "5380": { "id": 5380, "name": "associated_with", "color": "5db4cc" },
    "5381": { "id": 5381, "name": "Substance", "color": "00FA9A" },
    "5382": { "id": 5382, "name": "Food", "color": "00FF7F" },
    "5383": { "id": 5383, "name": "Functional Concept", "color": "3CB371" },
    "5384": { "id": 5384, "name": "Intellectual Product", "color": "2E8B57" },
    "5385": { "id": 5385, "name": "Language", "color": "228B22" },
    "5386": { "id": 5386, "name": "consists_of", "color": "008000" },
    "5387": { "id": 5387, "name": "adjacent_to", "color": "006400" },
    "5388": { "id": 5388, "name": "connected_to", "color": "9ACD32" },
    "5389": { "id": 5389, "name": "interconnects", "color": "6B8E23" },
    "5390": { "id": 5390, "name": "surrounds", "color": "808000" },
    "5391": { "id": 5391, "name": "traverses", "color": "556B2F" },
    "5392": { "id": 5392, "name": "derivative_of", "color": "66CDAA" },
    "5393": { "id": 5393, "name": "developmental_form_of", "color": "8FBC8F" },
    "5394": { "id": 5394, "name": "degree_of", "color": "20B2AA" },
    "5395": { "id": 5395, "name": null, "color": "008B8B" },
    "5396": { "id": 5396, "name": "measurement_of", "color": "008080" },
    "5397": { "id": 5397, "name": "method_of", "color": "FFA07A" },
    "5398": { "id": 5398, "name": "Sign or Symptom", "color": "FF7F50" },
    "5399": { "id": 5399, "name": "Classification", "color": "FF6347" },
    "5400": { "id": 5400, "name": "isa", "color": "FF4500" },
    "5401": { "id": 5401, "name": "brings_about", "color": "FF8C00" },
    "5402": { "id": 5402, "name": "performs", "color": "FFA500" },
    "5403": { "id": 5403, "name": "spatially_related_to", "color": "FFD700" },
    "5404": { "id": 5404, "name": "Anatomical Abnormality", "color": "FFFF00" },
    "5405": { "id": 5405, "name": "Neoplastic Process", "color": "5ed3ff" },
    "5406": { "id": 5406, "name": "Receptor", "color": "9ed563" },
    "5407": { "id": 5407, "name": "analyzes", "color": "5F9EA0" },
    "5408": { "id": 5408, "name": "Archaeon", "color": "FFEFD5" },
    "5409": { "id": 5409, "name": "Antibiotic", "color": "FFE4B5" },
    "5410": { "id": 5410, "name": "Element, Ion, or Isotope", "color": "FFDAB9" },
    "5411": { "id": 5411, "name": "Inorganic Chemical", "color": "EEE8AA" },
    "5412": { "id": 5412, "name": "branch_of", "color": "F0E68C" },
    "5413": { "id": 5413, "name": "tributary_of", "color": "BDB76B" },
    "5414": { "id": 5414, "name": "Clinical Drug", "color": "E6E6FA" },
    "5415": { "id": 5415, "name": "Clinical Attribute", "color": "D8BFD8" },
    "5416": { "id": 5416, "name": "ingredient_of", "color": "DDA0DD" },
    "5417": { "id": 5417, "name": "Drug Delivery Device", "color": "EE82EE" },
    "5418": { "id": 5418, "name": "Eukaryote", "color": "DA70D6" },
    "7000": { "id": 7000, "name": "is a", "color": "FF00FF" },
    "7001": { "id": 7001, "name": "Geographic location", "color": "FF00FF" },
    "7002": { "id": 7002, "name": "Inactive concept", "color": "BA55D3" },
    "7003": { "id": 7003, "name": "Has reason", "color": "9370DB" },
    "7004": { "id": 7004, "name": "Special concept", "color": "8A2BE2" },
    "7005": { "id": 7005, "name": "Is etiology for", "color": "9400D3" },
    "7006": { "id": 7006, "name": "Namespace concept", "color": "9932CC" },
    "7007": { "id": 7007, "name": "Has explanation", "color": "8B008B" },
    "7008": { "id": 7008, "name": "Racial group", "color": "800080" },
    "7009": { "id": 7009, "name": "Has problem name", "color": "4B0082" },
    "7010": { "id": 7010, "name": "Link assertion", "color": "6A5ACD" },
    "7011": { "id": 7011, "name": "Has support", "color": "483D8B" },
    "7012": { "id": 7012, "name": "Foundation metadata concept", "color": "00FFFF" },
    "7013": { "id": 7013, "name": "Has problem member", "color": "00FFFF" },
    "7014": { "id": 7014, "name": "Core metadata concept", "color": "E0FFFF" },
    "7015": { "id": 7015, "name": "Is manifestation of", "color": "AFEEEE" },
    "7016": { "id": 7016, "name": "Unapproved attribute", "color": "7FFFD4" },
    "7017": { "id": 7017, "name": "Resulting in", "color": "40E0D0" },
    "7018": { "id": 7018, "name": "Material", "color": "48D1CC" },
    "7019": { "id": 7019, "name": "Stoma material", "color": "00CED1" },
    "7020": { "id": 7020, "name": "Reline material", "color": "5F9EA0" },
    "7021": { "id": 7021, "name": "Bridge material", "color": "4682B4" },
    "7022": { "id": 7022, "name": "Bonding material", "color": "B0C4DE" },
    "7023": { "id": 7023, "name": "Cup material", "color": "B0E0E6" },
    "7024": { "id": 7024, "name": "Impression material", "color": "ADD8E6" },
    "7025": { "id": 7025, "name": "Repair material", "color": "87CEEB" },
    "7026": { "id": 7026, "name": "Embolization material", "color": "87CEFA" },
    "7027": { "id": 7027, "name": "Obturation material", "color": "00BFFF" },
    "7028": { "id": 7028, "name": "Interposition material", "color": "1E90FF" },
    "7029": { "id": 7029, "name": "Graft material", "color": "6495ED" },
    "7030": { "id": 7030, "name": "Foreign body material", "color": "7B68EE" },
    "7031": { "id": 7031, "name": "Additional material", "color": "4169E1" },
    "7032": { "id": 7032, "name": "Urethral lengthening material", "color": "0000FF" },
    "7033": { "id": 7033, "name": "Pack material", "color": "0000CD" },
    "7034": { "id": 7034, "name": "Material removed", "color": "00008B" },
    "7035": { "id": 7035, "name": "Lens material", "color": "000080" },
    "7036": { "id": 7036, "name": "Impact-resistant lens", "color": "191970" },
    "7037": { "id": 7037, "name": "High-index glass lens", "color": "FFF8DC" },
    "7038": { "id": 7038, "name": "Plastic lens", "color": "FFEBCD" },
    "7039": { "id": 7039, "name": "Polycarbonate lens", "color": "FFE4C4" },
    "7040": { "id": 7040, "name": "Treated with", "color": "FFDEAD" },
    "7041": { "id": 7041, "name": "With staging", "color": "00b652" },
    "7042": { "id": 7042, "name": "Staging of procedure", "color": "DEB887" },
    "7043": { "id": 7043, "name": "Staging of disease", "color": "D2B48C" },
    "7044": { "id": 7044, "name": "FIGO Stage", "color": "BC8F8F" },
    "7045": { "id": 7045, "name": "M - Distant metastasis stage", "color": "F4A460" },
    "7046": { "id": 7046, "name": "T - Tumor stage", "color": "DAA520" },
    "7047": { "id": 7047, "name": "International Union Against Cancer stage grouping", "color": "B8860B" },
    "7048": { "id": 7048, "name": "Tumor-node-metastasis (TNM) staging classification", "color": "CD853F" },
    "7049": { "id": 7049, "name": "Additional staging descriptors", "color": "D2691E" },
    "7050": { "id": 7050, "name": "N - Regional lymph node stage", "color": "8B4513" },
    "7051": { "id": 7051, "name": "Staging system", "color": "A0522D" },
    "7052": { "id": 7052, "name": "Type", "color": "A52A2A" },
    "7053": { "id": 7053, "name": "Type of prosthesis", "color": "800000" },
    "7054": { "id": 7054, "name": "Type of valve prosthesis", "color": "A0522D" },
    "7055": { "id": 7055, "name": "Type of contaminant", "color": "F08080" },
    "7056": { "id": 7056, "name": "Type of denture construction", "color": "FA8072" },
    "7057": { "id": 7057, "name": "Type of orthodontic appliance component", "color": "E9967A" },
    "7058": { "id": 7058, "name": "Type of keratoplasty", "color": "FFA07A" },
    "7059": { "id": 7059, "name": "Type of spectacle", "color": "DC143C" },
    "7060": { "id": 7060, "name": "Type of tray", "color": "FF0000" },
    "7061": { "id": 7061, "name": "Type of spring", "color": "B22222" },
    "7062": { "id": 7062, "name": "Type of episiotomy", "color": "8B0000" },
    "7063": { "id": 7063, "name": "Type of telescope", "color": "FFC0CB" },
    "7064": { "id": 7064, "name": "Type of specimen", "color": "FFB6C1" },
    "7065": { "id": 7065, "name": "Type of embryo", "color": "FF69B4" },
    "7066": { "id": 7066, "name": "Type of meal", "color": "FF1493" },
    "7067": { "id": 7067, "name": "Type of amputation", "color": "C71585" },
    "7068": { "id": 7068, "name": "Type of pouch", "color": "DB7093" },
    "7069": { "id": 7069, "name": "Type of constraint", "color": "ADFF2F" },
    "7070": { "id": 7070, "name": "Type of flap", "color": "7FFF00" },
    "7071": { "id": 7071, "name": "Type of partial denture connector", "color": "7CFC00" },
    "7072": { "id": 7072, "name": "Type of impression", "color": "00FF00" },
    "7073": { "id": 7073, "name": "Type of flow", "color": "32CD32" },
    "7074": { "id": 7074, "name": "Type of donor organ", "color": "98FB98" },
    "7075": { "id": 7075, "name": "Type of catheter", "color": "5db4cc" },
    "7076": { "id": 7076, "name": "Type of elastic", "color": "00FA9A" },
    "7077": { "id": 7077, "name": "Type of mounting", "color": "00FF7F" },
    "7078": { "id": 7078, "name": "Type of activity", "color": "3CB371" },
    "7079": { "id": 7079, "name": "Type of injury", "color": "2E8B57" },
    "7080": { "id": 7080, "name": "Type of food", "color": "228B22" },
    "7081": { "id": 7081, "name": "Type of plot", "color": "008000" },
    "7082": { "id": 7082, "name": "Type of drug", "color": "006400" },
    "7083": { "id": 7083, "name": "Type of gastrostomy", "color": "9ACD32" },
    "7084": { "id": 7084, "name": "Type of foreign body", "color": "6B8E23" },
    "7085": { "id": 7085, "name": "Type of bur", "color": "808000" },
    "7086": { "id": 7086, "name": "Type of veneer", "color": "556B2F" },
    "7087": { "id": 7087, "name": "Type of cast", "color": "66CDAA" },
    "7088": { "id": 7088, "name": "Type of plate", "color": "8FBC8F" },
    "7089": { "id": 7089, "name": "Type of hemodialysis", "color": "20B2AA" },
    "7090": { "id": 7090, "name": "Type of circuit", "color": "008B8B" },
    "7091": { "id": 7091, "name": "Type of foreign material", "color": "008080" },
    "7092": { "id": 7092, "name": "Type of appliance", "color": "FFA07A" },
    "7093": { "id": 7093, "name": "Type of right", "color": "FF7F50" },
    "7094": { "id": 7094, "name": "Type of pacing", "color": "FF6347" },
    "7095": { "id": 7095, "name": "Type of stoma", "color": "FF4500" },
    "7096": { "id": 7096, "name": "Type of collection", "color": "FF8C00" },
    "7097": { "id": 7097, "name": "Type of oxygenator", "color": "FFA500" },
    "7098": { "id": 7098, "name": "Type of patch", "color": "FFD700" },
    "7099": { "id": 7099, "name": "Type of prolapse", "color": "FFFF00" },
    "7100": { "id": 7100, "name": "Type of papilla", "color": "5ed3ff" },
    "7101": { "id": 7101, "name": "Type of tourniquet", "color": "9ed563" },
    "7102": { "id": 7102, "name": "Type of magnifier", "color": "5F9EA0" },
    "7103": { "id": 7103, "name": "Type of obturator", "color": "FFEFD5" },
    "7104": { "id": 7104, "name": "Type of arthroplasty", "color": "FFE4B5" },
    "7105": { "id": 7105, "name": "Type of language", "color": "FFDAB9" },
    "7106": { "id": 7106, "name": "Type of antibody", "color": "EEE8AA" },
    "7107": { "id": 7107, "name": "Type of nail", "color": "F0E68C" },
    "7108": { "id": 7108, "name": "Type of sensation", "color": "BDB76B" },
    "7109": { "id": 7109, "name": "Type of occlusion", "color": "E6E6FA" },
    "7110": { "id": 7110, "name": "Type of stenosis", "color": "D8BFD8" },
    "7111": { "id": 7111, "name": "Type of monitor", "color": "DDA0DD" },
    "7112": { "id": 7112, "name": "Type of blade", "color": "EE82EE" },
    "7113": { "id": 7113, "name": "Type of denture", "color": "DA70D6" },
    "7114": { "id": 7114, "name": "Type of partial denture", "color": "FF00FF" },
    "7115": { "id": 7115, "name": "Type of resection", "color": "FF00FF" },
    "7116": { "id": 7116, "name": "Type of enlargement", "color": "BA55D3" },
    "7117": { "id": 7117, "name": "Type of fluid", "color": "9370DB" },
    "7118": { "id": 7118, "name": "Type of registration", "color": "8A2BE2" },
    "7119": { "id": 7119, "name": "Type of crown", "color": "9400D3" },
    "7120": { "id": 7120, "name": "Type of conduit", "color": "9932CC" },
    "7121": { "id": 7121, "name": "Type of repair", "color": "8B008B" },
    "7122": { "id": 7122, "name": "Type of stimulus", "color": "800080" },
    "7123": { "id": 7123, "name": "Type of current", "color": "4B0082" },
    "7124": { "id": 7124, "name": "Type of probe", "color": "6A5ACD" },
    "7125": { "id": 7125, "name": "Type of drain", "color": "483D8B" },
    "7126": { "id": 7126, "name": "Type of valve", "color": "00FFFF" },
    "7127": { "id": 7127, "name": "Type of cuff", "color": "00FFFF" },
    "7128": { "id": 7128, "name": "Type of eye movement", "color": "E0FFFF" },
    "7129": { "id": 7129, "name": "Type of pedicle", "color": "AFEEEE" },
    "7130": { "id": 7130, "name": "Type of cement", "color": "7FFFD4" },
    "7131": { "id": 7131, "name": "Type of traction system", "color": "40E0D0" },
    "7132": { "id": 7132, "name": "Type of fixator", "color": "48D1CC" },
    "7133": { "id": 7133, "name": "Type of loop", "color": "00CED1" },
    "7134": { "id": 7134, "name": "Type of jejunal loop", "color": "5F9EA0" },
    "7135": { "id": 7135, "name": "Type of anastomosis", "color": "4682B4" },
    "7136": { "id": 7136, "name": "Venous anastomosis", "color": "B0C4DE" },
    "7137": { "id": 7137, "name": "Type of lens", "color": "B0E0E6" },
    "7138": { "id": 7138, "name": "Type of headgear", "color": "ADD8E6" },
    "7139": { "id": 7139, "name": "Type of pack", "color": "87CEEB" },
    "7140": { "id": 7140, "name": "Type of inlay", "color": "87CEFA" },
    "7141": { "id": 7141, "name": "Type of incision", "color": "00BFFF" },
    "7142": { "id": 7142, "name": "Type of shunt", "color": "1E90FF" },
    "7143": { "id": 7143, "name": "Type of line", "color": "6495ED" },
    "7144": { "id": 7144, "name": "Type of electrode", "color": "7B68EE" },
    "7145": { "id": 7145, "name": "Type of plaster cast", "color": "4169E1" },
    "7146": { "id": 7146, "name": "Type of hemostatic agent", "color": "0000FF" },
    "7147": { "id": 7147, "name": "Type of device", "color": "0000CD" },
    "7148": { "id": 7148, "name": "Type of reconstruction", "color": "00008B" },
    "7149": { "id": 7149, "name": "Type of post core", "color": "000080" },
    "7150": { "id": 7150, "name": "Type of construction", "color": "191970" },
    "7151": { "id": 7151, "name": "Type of reservoir construction", "color": "FFF8DC" },
    "7152": { "id": 7152, "name": "Type of scan", "color": "FFEBCD" },
    "7153": { "id": 7153, "name": "Type of cell", "color": "FFE4C4" },
    "7154": { "id": 7154, "name": "Type of separator", "color": "FFDEAD" },
    "7155": { "id": 7155, "name": "Type of fracture", "color": "00b652" },
    "7156": { "id": 7156, "name": "Has measured component", "color": "DEB887" },
    "7157": { "id": 7157, "name": "Lead polarity", "color": "D2B48C" },
    "7158": { "id": 7158, "name": "Radiating to", "color": "BC8F8F" },
    "7159": { "id": 7159, "name": "Place", "color": "F4A460" },
    "7160": { "id": 7160, "name": "Place of testing", "color": "DAA520" },
    "7161": { "id": 7161, "name": "Place of accident", "color": "B8860B" },
    "7162": { "id": 7162, "name": "Place of acquisition", "color": "CD853F" },
    "7163": { "id": 7163, "name": "Metastatic from", "color": "D2691E" },
    "7164": { "id": 7164, "name": "Has patient", "color": "8B4513" },
    "7165": { "id": 7165, "name": "Size", "color": "A0522D" },
    "7166": { "id": 7166, "name": "Controlled by", "color": "A52A2A" },
    "7167": { "id": 7167, "name": "Function", "color": "800000" },
    "7168": { "id": 7168, "name": "Clinical function of hamartoma", "color": "A0522D" },
    "7169": { "id": 7169, "name": "Progression", "color": "F08080" },
    "7170": { "id": 7170, "name": "Maturity", "color": "FA8072" },
    "7171": { "id": 7171, "name": "Behavior of tumor", "color": "E9967A" },
    "7172": { "id": 7172, "name": "Associated etiologic finding", "color": "FFA07A" },
    "7173": { "id": 7173, "name": "Transmitted by", "color": "DC143C" },
    "7174": { "id": 7174, "name": "Presentation", "color": "FF0000" },
    "7175": { "id": 7175, "name": "Field stimulated", "color": "B22222" },
    "7176": { "id": 7176, "name": "Voice presentation", "color": "8B0000" },
    "7177": { "id": 7177, "name": "Initial presentation", "color": "FFC0CB" },
    "7178": { "id": 7178, "name": "Disease polarity", "color": "FFB6C1" },
    "7179": { "id": 7179, "name": "Associated etiology", "color": "FF69B4" },
    "7180": { "id": 7180, "name": "In transit", "color": "FF1493" },
    "7181": { "id": 7181, "name": "Free of", "color": "C71585" },
    "7182": { "id": 7182, "name": "With intensity", "color": "DB7093" },
    "7183": { "id": 7183, "name": "Has object", "color": "ADFF2F" },
    "7184": { "id": 7184, "name": "Stimulus used", "color": "7FFF00" },
    "7185": { "id": 7185, "name": "Extension of sac", "color": "7CFC00" },
    "7186": { "id": 7186, "name": "Has objective observations", "color": "00FF00" },
    "7187": { "id": 7187, "name": "Agent used", "color": "32CD32" },
    "7188": { "id": 7188, "name": "Fluid used", "color": "98FB98" },
    "7189": { "id": 7189, "name": "Cycloplegic used", "color": "5db4cc" },
    "7190": { "id": 7190, "name": "Stain used", "color": "00FA9A" },
    "7191": { "id": 7191, "name": "Drug used", "color": "00FF7F" },
    "7192": { "id": 7192, "name": "Pharmacological stress used", "color": "3CB371" },
    "7193": { "id": 7193, "name": "Insulin used", "color": "2E8B57" },
    "7194": { "id": 7194, "name": "Sensitization drug", "color": "228B22" },
    "7195": { "id": 7195, "name": "Solution used", "color": "008000" },
    "7196": { "id": 7196, "name": "Distending agent used", "color": "006400" },
    "7197": { "id": 7197, "name": "Contrast used", "color": "9ACD32" },
    "7198": { "id": 7198, "name": "Test allergen used", "color": "6B8E23" },
    "7199": { "id": 7199, "name": "Substances used", "color": "808000" },
    "7200": { "id": 7200, "name": "Chemical used", "color": "556B2F" },
    "7201": { "id": 7201, "name": "Control substance used", "color": "66CDAA" },
    "7202": { "id": 7202, "name": "Blood product used", "color": "8FBC8F" },
    "7203": { "id": 7203, "name": "Gel used", "color": "20B2AA" },
    "7204": { "id": 7204, "name": "Gas used", "color": "008B8B" },
    "7205": { "id": 7205, "name": "Physiological stress used", "color": "008080" },
    "7206": { "id": 7206, "name": "Medium used", "color": "FFA07A" },
    "7207": { "id": 7207, "name": "Stimulus agent", "color": "FF7F50" },
    "7208": { "id": 7208, "name": "Substance injected", "color": "FF6347" },
    "7209": { "id": 7209, "name": "Administers energy", "color": "FF4500" },
    "7210": { "id": 7210, "name": "Except for", "color": "FF8C00" },
    "7211": { "id": 7211, "name": "Metastatic to", "color": "FFA500" },
    "7212": { "id": 7212, "name": "Affected animal", "color": "FFD700" },
    "7213": { "id": 7213, "name": "Associated topography", "color": "FFFF00" },
    "7214": { "id": 7214, "name": "Anatomical line", "color": "5ed3ff" },
    "7215": { "id": 7215, "name": "General site", "color": "9ed563" },
    "7216": { "id": 7216, "name": "Attachment site", "color": "5F9EA0" },
    "7217": { "id": 7217, "name": "Proximal site", "color": "FFEFD5" },
    "7218": { "id": 7218, "name": "Recipient site", "color": "FFE4B5" },
    "7219": { "id": 7219, "name": "Female site", "color": "FFDAB9" },
    "7220": { "id": 7220, "name": "Affected site", "color": "EEE8AA" },
    "7221": { "id": 7221, "name": "Male site", "color": "F0E68C" },
    "7222": { "id": 7222, "name": "Distal site", "color": "BDB76B" },
    "7223": { "id": 7223, "name": "Donor site", "color": "E6E6FA" },
    "7224": { "id": 7224, "name": "Site of", "color": "D8BFD8" },
    "7225": { "id": 7225, "name": "Site of insertion", "color": "DDA0DD" },
    "7226": { "id": 7226, "name": "Site of implantation", "color": "EE82EE" },
    "7227": { "id": 7227, "name": "Ureteric implantation site", "color": "DA70D6" },
    "7228": { "id": 7228, "name": "Site of break", "color": "FF00FF" },
    "7229": { "id": 7229, "name": "Site of pacemaker", "color": "FF00FF" },
    "7230": { "id": 7230, "name": "Site of origin", "color": "BA55D3" },
    "7231": { "id": 7231, "name": "Site of origin of graft", "color": "9370DB" },
    "7232": { "id": 7232, "name": "Site of fixation", "color": "8A2BE2" },
    "7233": { "id": 7233, "name": "Site of incision", "color": "9400D3" },
    "7234": { "id": 7234, "name": "Site of primary lesion", "color": "9932CC" },
    "7235": { "id": 7235, "name": "Site of radiation", "color": "8B008B" },
    "7236": { "id": 7236, "name": "Site of overgrowth", "color": "800080" },
    "7237": { "id": 7237, "name": "Site of lesion", "color": "4B0082" },
    "7238": { "id": 7238, "name": "Site of pancreatic duct", "color": "6A5ACD" },
    "7239": { "id": 7239, "name": "Site of harvest", "color": "483D8B" },
    "7240": { "id": 7240, "name": "Site of stricture", "color": "00FFFF" },
    "7241": { "id": 7241, "name": "Site of catheter", "color": "00FFFF" },
    "7242": { "id": 7242, "name": "Catheter tip site", "color": "E0FFFF" },
    "7243": { "id": 7243, "name": "Old catheter site", "color": "AFEEEE" },
    "7244": { "id": 7244, "name": "New catheter site", "color": "7FFFD4" },
    "7245": { "id": 7245, "name": "Probe location", "color": "40E0D0" },
    "7246": { "id": 7246, "name": "Site of neoplasm", "color": "48D1CC" },
    "7247": { "id": 7247, "name": "Site of retraction", "color": "00CED1" },
    "7248": { "id": 7248, "name": "Site of sampling", "color": "5F9EA0" },
    "7249": { "id": 7249, "name": "Site of intrathecal injection", "color": "4682B4" },
    "7250": { "id": 7250, "name": "Side of approach", "color": "B0C4DE" },
    "7251": { "id": 7251, "name": "Site of ectopic tumor", "color": "B0E0E6" },
    "7252": { "id": 7252, "name": "Site of exit", "color": "ADD8E6" },
    "7253": { "id": 7253, "name": "Site of exit of pack", "color": "87CEEB" },
    "7254": { "id": 7254, "name": "Site of epidural", "color": "87CEFA" },
    "7255": { "id": 7255, "name": "Site of fistula/graft", "color": "00BFFF" },
    "7256": { "id": 7256, "name": "Site of shunt", "color": "1E90FF" },
    "7257": { "id": 7257, "name": "Site of entry", "color": "6495ED" },
    "7258": { "id": 7258, "name": "Site of stent", "color": "7B68EE" },
    "7259": { "id": 7259, "name": "Site of gradient", "color": "4169E1" },
    "7260": { "id": 7260, "name": "Site of malfunction", "color": "0000FF" },
    "7261": { "id": 7261, "name": "Site of detachment", "color": "0000CD" },
    "7262": { "id": 7262, "name": "Site of perforation", "color": "00008B" },
    "7263": { "id": 7263, "name": "Perforation into", "color": "000080" },
    "7264": { "id": 7264, "name": "Site of bypass", "color": "191970" },
    "7265": { "id": 7265, "name": "Site of distal bowel involved", "color": "FFF8DC" },
    "7266": { "id": 7266, "name": "Site of obstruction", "color": "FFEBCD" },
    "7267": { "id": 7267, "name": "Site of tumor", "color": "FFE4C4" },
    "7268": { "id": 7268, "name": "Site of primary tumor", "color": "FFDEAD" },
    "7269": { "id": 7269, "name": "Location of brain tumor", "color": "00b652" },
    "7270": { "id": 7270, "name": "Site of electrode", "color": "DEB887" },
    "7271": { "id": 7271, "name": "Cardiac electrode site", "color": "D2B48C" },
    "7272": { "id": 7272, "name": "Lead location", "color": "BC8F8F" },
    "7273": { "id": 7273, "name": "Site of volvulus", "color": "F4A460" },
    "7274": { "id": 7274, "name": "Site of undergrowth", "color": "DAA520" },
    "7275": { "id": 7275, "name": "Site in breathing system", "color": "B8860B" },
    "7276": { "id": 7276, "name": "Site of drainage", "color": "CD853F" },
    "7277": { "id": 7277, "name": "Site of component", "color": "D2691E" },
    "7278": { "id": 7278, "name": "Site of distal component", "color": "8B4513" },
    "7279": { "id": 7279, "name": "Site of proximal component", "color": "A0522D" },
    "7280": { "id": 7280, "name": "Site of recording", "color": "A52A2A" },
    "7281": { "id": 7281, "name": "Site of regurgitation", "color": "800000" },
    "7282": { "id": 7282, "name": "Site of revision", "color": "A0522D" },
    "7283": { "id": 7283, "name": "Site of cannulation", "color": "F08080" },
    "7284": { "id": 7284, "name": "Outflow site", "color": "FA8072" },
    "7285": { "id": 7285, "name": "Inflow site", "color": "E9967A" },
    "7286": { "id": 7286, "name": "Site of access", "color": "FFA07A" },
    "7287": { "id": 7287, "name": "Site of reconstruction", "color": "DC143C" },
    "7288": { "id": 7288, "name": "Site of anastomosis", "color": "FF0000" },
    "7289": { "id": 7289, "name": "Arterial anastomosis site", "color": "B22222" },
    "7290": { "id": 7290, "name": "Proximal anastomosis site", "color": "8B0000" },
    "7291": { "id": 7291, "name": "Distal anastomosis site", "color": "FFC0CB" },
    "7292": { "id": 7292, "name": "Venous anastomosis site", "color": "FFB6C1" },
    "7293": { "id": 7293, "name": "Site of undiversion", "color": "FF69B4" },
    "7294": { "id": 7294, "name": "Replacement fluid inflow", "color": "FF1493" },
    "7295": { "id": 7295, "name": "Site of complete cleft", "color": "C71585" },
    "7296": { "id": 7296, "name": "Site of transplantation", "color": "DB7093" },
    "7297": { "id": 7297, "name": "Site of leakage", "color": "ADFF2F" },
    "7298": { "id": 7298, "name": "Site of stimulation", "color": "7FFF00" },
    "7299": { "id": 7299, "name": "Site of connection", "color": "7CFC00" },
    "7300": { "id": 7300, "name": "Site of fracture", "color": "00FF00" },
    "7301": { "id": 7301, "name": "Site of involvement", "color": "32CD32" },
    "7302": { "id": 7302, "name": "Site of referred pain", "color": "98FB98" },
    "7303": { "id": 7303, "name": "Site of proximal bowel involved", "color": "5db4cc" },
    "7304": { "id": 7304, "name": "Site of fistula", "color": "00FA9A" },
    "7305": { "id": 7305, "name": "Distal site of fistula", "color": "00FF7F" },
    "7306": { "id": 7306, "name": "Proximal site of fistula", "color": "3CB371" },
    "7307": { "id": 7307, "name": "Site of duplication", "color": "2E8B57" },
    "7308": { "id": 7308, "name": "Site of shortening", "color": "228B22" },
    "7309": { "id": 7309, "name": "Site of distal opening", "color": "008000" },
    "7310": { "id": 7310, "name": "Site of rupture", "color": "006400" },
    "7311": { "id": 7311, "name": "Specific site", "color": "9ACD32" },
    "7312": { "id": 7312, "name": "Muscle involved", "color": "6B8E23" },
    "7313": { "id": 7313, "name": "Apex of primary deformity", "color": "808000" },
    "7314": { "id": 7314, "name": "Arterial site", "color": "556B2F" },
    "7315": { "id": 7315, "name": "Anomalous artery", "color": "66CDAA" },
    "7316": { "id": 7316, "name": "Part of artery", "color": "8FBC8F" },
    "7317": { "id": 7317, "name": "Arterial inflow site", "color": "20B2AA" },
    "7318": { "id": 7318, "name": "Arterial anatomy", "color": "008B8B" },
    "7319": { "id": 7319, "name": "Donor artery", "color": "008080" },
    "7320": { "id": 7320, "name": "Recipient artery", "color": "FFA07A" },
    "7321": { "id": 7321, "name": "Arterial anastomosis", "color": "FF7F50" },
    "7322": { "id": 7322, "name": "Tooth site", "color": "FF6347" },
    "7323": { "id": 7323, "name": "Alveolar site", "color": "FF4500" },
    "7324": { "id": 7324, "name": "Muscle assessed", "color": "FF8C00" },
    "7325": { "id": 7325, "name": "Dominant hand", "color": "FFA500" },
    "7326": { "id": 7326, "name": "Nerve stimulated", "color": "FFD700" },
    "7327": { "id": 7327, "name": "Floor of mouth site", "color": "FFFF00" },
    "7328": { "id": 7328, "name": "Liver sector involvement", "color": "5ed3ff" },
    "7329": { "id": 7329, "name": "Vessel", "color": "9ed563" },
    "7330": { "id": 7330, "name": "Vessel receiving collaterals", "color": "5F9EA0" },
    "7331": { "id": 7331, "name": "Target vessel", "color": "FFEFD5" },
    "7332": { "id": 7332, "name": "Vessel giving collaterals", "color": "FFE4B5" },
    "7333": { "id": 7333, "name": "Palate site", "color": "FFDAB9" },
    "7334": { "id": 7334, "name": "Trigger point site", "color": "EEE8AA" },
    "7335": { "id": 7335, "name": "Intravascular line site", "color": "F0E68C" },
    "7336": { "id": 7336, "name": "Pulp canal", "color": "BDB76B" },
    "7337": { "id": 7337, "name": "Sanctuary site", "color": "E6E6FA" },
    "7338": { "id": 7338, "name": "Measurement site", "color": "D8BFD8" },
    "7339": { "id": 7339, "name": "Ossicle lost", "color": "DDA0DD" },
    "7340": { "id": 7340, "name": "Lip site", "color": "EE82EE" },
    "7341": { "id": 7341, "name": "Fixing eye", "color": "DA70D6" },
    "7342": { "id": 7342, "name": "Venous site", "color": "FF00FF" },
    "7343": { "id": 7343, "name": "Venous outflow site", "color": "FF00FF" },
    "7344": { "id": 7344, "name": "Arm of chromosome", "color": "BA55D3" },
    "7345": { "id": 7345, "name": "Finger involved", "color": "9370DB" },
    "7346": { "id": 7346, "name": "Device used", "color": "8A2BE2" },
    "7347": { "id": 7347, "name": "Association", "color": "9400D3" },
    "7348": { "id": 7348, "name": "Associated observation", "color": "9932CC" },
    "7349": { "id": 7349, "name": "Communication", "color": "8B008B" },
    "7350": { "id": 7350, "name": "Communication with wound", "color": "800080" },
    "7351": { "id": 7351, "name": "Associated substance", "color": "4B0082" },
    "7352": { "id": 7352, "name": "Associated organism", "color": "6A5ACD" },
    "7353": { "id": 7353, "name": "Connection", "color": "483D8B" },
    "7354": { "id": 7354, "name": "Associated interpretation", "color": "00FFFF" },
    "7355": { "id": 7355, "name": "Recipient side", "color": "00FFFF" },
    "7356": { "id": 7356, "name": "Procedure approach", "color": "E0FFFF" },
    "7357": { "id": 7357, "name": "Distribution", "color": "AFEEEE" },
    "7358": { "id": 7358, "name": "Spread", "color": "7FFFD4" },
    "7359": { "id": 7359, "name": "Nodality", "color": "40E0D0" },
    "7360": { "id": 7360, "name": "Reversibility", "color": "48D1CC" },
    "7361": { "id": 7361, "name": "Course of illness", "color": "00CED1" },
    "7362": { "id": 7362, "name": "Test protocol used", "color": "5F9EA0" },
    "7363": { "id": 7363, "name": "Stycar graded balls used", "color": "4682B4" },
    "7364": { "id": 7364, "name": "Pattern", "color": "B0C4DE" },
    "7365": { "id": 7365, "name": "Pattern of disease", "color": "B0E0E6" },
    "7366": { "id": 7366, "name": "Pattern of episode", "color": "ADD8E6" },
    "7367": { "id": 7367, "name": "Pattern presentation", "color": "87CEEB" },
    "7368": { "id": 7368, "name": "Pattern of hearing loss", "color": "87CEFA" },
    "7369": { "id": 7369, "name": "Distribution pattern", "color": "00BFFF" },
    "7370": { "id": 7370, "name": "Limb pattern", "color": "1E90FF" },
    "7371": { "id": 7371, "name": "Pattern of infiltration", "color": "6495ED" },
    "7372": { "id": 7372, "name": "Pattern of staining", "color": "7B68EE" },
    "7373": { "id": 7373, "name": "Pattern of tear", "color": "4169E1" },
    "7374": { "id": 7374, "name": "Depth", "color": "0000FF" },
    "7375": { "id": 7375, "name": "Depth of epidural space", "color": "0000CD" },
    "7376": { "id": 7376, "name": "Depth of burn", "color": "00008B" },
    "7377": { "id": 7377, "name": "Depth of sample", "color": "000080" },
    "7378": { "id": 7378, "name": "With odor", "color": "191970" },
    "7379": { "id": 7379, "name": "With type", "color": "FFF8DC" },
    "7380": { "id": 7380, "name": "Reduced distance method", "color": "FFEBCD" },
    "7381": { "id": 7381, "name": "Deficiency of", "color": "FFE4C4" },
    "7382": { "id": 7382, "name": "Fixation side", "color": "FFDEAD" },
    "7383": { "id": 7383, "name": "Laboratory method", "color": "00b652" },
    "7384": { "id": 7384, "name": "Method of action", "color": "DEB887" },
    "7385": { "id": 7385, "name": "Method of staging", "color": "D2B48C" },
    "7386": { "id": 7386, "name": "Method of biopsy", "color": "BC8F8F" },
    "7387": { "id": 7387, "name": "Method of detection", "color": "F4A460" },
    "7388": { "id": 7388, "name": "Method of administration of drug", "color": "DAA520" },
    "7389": { "id": 7389, "name": "Method of regional block drug administration", "color": "B8860B" },
    "7390": { "id": 7390, "name": "Method of adjustment", "color": "CD853F" },
    "7391": { "id": 7391, "name": "Method of establishment of airway", "color": "D2691E" },
    "7392": { "id": 7392, "name": "Method of excision", "color": "8B4513" },
    "7393": { "id": 7393, "name": "Method of coronary implantation", "color": "A0522D" },
    "7394": { "id": 7394, "name": "Method of injection", "color": "A52A2A" },
    "7395": { "id": 7395, "name": "Method of fixation", "color": "800000" },
    "7396": { "id": 7396, "name": "Method of electrode fixation", "color": "A0522D" },
    "7397": { "id": 7397, "name": "Component fixation", "color": "F08080" },
    "7398": { "id": 7398, "name": "Proximal component fixation", "color": "FA8072" },
    "7399": { "id": 7399, "name": "Humeral component fixation", "color": "E9967A" },
    "7400": { "id": 7400, "name": "Distal component fixation", "color": "FFA07A" },
    "7401": { "id": 7401, "name": "Glenoid component fixation", "color": "DC143C" },
    "7402": { "id": 7402, "name": "Radial component fixation", "color": "FF0000" },
    "7403": { "id": 7403, "name": "Ulnar component fixation", "color": "B22222" },
    "7404": { "id": 7404, "name": "Femoral component fixation", "color": "8B0000" },
    "7405": { "id": 7405, "name": "Tibial component fixation", "color": "FFC0CB" },
    "7406": { "id": 7406, "name": "Acetabular component fixation", "color": "FFB6C1" },
    "7407": { "id": 7407, "name": "Talar component fixation", "color": "FF69B4" },
    "7408": { "id": 7408, "name": "Patellar component fixation", "color": "FF1493" },
    "7409": { "id": 7409, "name": "Method of dilatation", "color": "C71585" },
    "7410": { "id": 7410, "name": "Method of fragmentation", "color": "DB7093" },
    "7411": { "id": 7411, "name": "Method of bronchial revascularization", "color": "ADFF2F" },
    "7412": { "id": 7412, "name": "Method of closure", "color": "7FFF00" },
    "7413": { "id": 7413, "name": "Method of bronchial closure", "color": "7CFC00" },
    "7414": { "id": 7414, "name": "Method of wound closure", "color": "00FF00" },
    "7415": { "id": 7415, "name": "Method of arthroplasty", "color": "32CD32" },
    "7416": { "id": 7416, "name": "Method of dietary advice given", "color": "98FB98" },
    "7417": { "id": 7417, "name": "Method of drainage", "color": "5db4cc" },
    "7418": { "id": 7418, "name": "Method of reaming", "color": "00FA9A" },
    "7419": { "id": 7419, "name": "Method of division", "color": "00FF7F" },
    "7420": { "id": 7420, "name": "Method of identification", "color": "3CB371" },
    "7421": { "id": 7421, "name": "Method of patient identification", "color": "2E8B57" },
    "7422": { "id": 7422, "name": "Method of occlusion", "color": "228B22" },
    "7423": { "id": 7423, "name": "Method of signal processing", "color": "008000" },
    "7424": { "id": 7424, "name": "Method of anastomosis", "color": "006400" },
    "7425": { "id": 7425, "name": "Recipient anastomosis method", "color": "9ACD32" },
    "7426": { "id": 7426, "name": "Donor vessel anastomosis method", "color": "6B8E23" },
    "7427": { "id": 7427, "name": "Method of ureterocolonic anastomosis", "color": "808000" },
    "7428": { "id": 7428, "name": "Method of uretero-ileal anastomosis", "color": "556B2F" },
    "7429": { "id": 7429, "name": "Method of set", "color": "66CDAA" },
    "7430": { "id": 7430, "name": "Method of localization of body structure", "color": "8FBC8F" },
    "7431": { "id": 7431, "name": "Method of narrowing", "color": "20B2AA" },
    "7432": { "id": 7432, "name": "Method of destruction", "color": "008B8B" },
    "7433": { "id": 7433, "name": "Method of insertion", "color": "008080" },
    "7434": { "id": 7434, "name": "Method of insertion of throat pack", "color": "FFA07A" },
    "7435": { "id": 7435, "name": "Method of stump closure", "color": "FF7F50" },
    "7436": { "id": 7436, "name": "Method of collection", "color": "FF6347" },
    "7437": { "id": 7437, "name": "Method of reconstruction", "color": "FF4500" },
    "7438": { "id": 7438, "name": "Patellofemoral reconstruction", "color": "FF8C00" },
    "7439": { "id": 7439, "name": "Method of instillation", "color": "FFA500" },
    "7440": { "id": 7440, "name": "Method of reduction", "color": "FFD700" },
    "7441": { "id": 7441, "name": "Method of extraction", "color": "FFFF00" },
    "7442": { "id": 7442, "name": "Method of repair", "color": "5ed3ff" },
    "7443": { "id": 7443, "name": "Method of rotator cuff repair", "color": "9ed563" },
    "7444": { "id": 7444, "name": "Type of plastic repair", "color": "5F9EA0" },
    "7445": { "id": 7445, "name": "Method of lung repair", "color": "FFEFD5" },
    "7446": { "id": 7446, "name": "Method of wiring", "color": "FFE4B5" },
    "7447": { "id": 7447, "name": "Method of cycling", "color": "FFDAB9" },
    "7448": { "id": 7448, "name": "Method of removal", "color": "EEE8AA" },
    "7449": { "id": 7449, "name": "Method of inhalational induction", "color": "F0E68C" },
    "7450": { "id": 7450, "name": "Method of incision", "color": "BDB76B" },
    "7451": { "id": 7451, "name": "Method of transfer", "color": "E6E6FA" },
    "7452": { "id": 7452, "name": "Method of hemostasis", "color": "D8BFD8" },
    "7453": { "id": 7453, "name": "Method of sealing", "color": "DDA0DD" },
    "7454": { "id": 7454, "name": "Method of giving glucose", "color": "EE82EE" },
    "7455": { "id": 7455, "name": "Method of augmentation", "color": "DA70D6" },
    "7456": { "id": 7456, "name": "Method of sampling", "color": "FF00FF" },
    "7457": { "id": 7457, "name": "Has proprietary name", "color": "FF00FF" },
    "7458": { "id": 7458, "name": "With size", "color": "BA55D3" },
    "7459": { "id": 7459, "name": "Relieved by", "color": "9370DB" },
    "7460": { "id": 7460, "name": "Degree", "color": "8A2BE2" },
    "7461": { "id": 7461, "name": "Degree of paralysis", "color": "9400D3" },
    "7462": { "id": 7462, "name": "Degree of differentiation", "color": "9932CC" },
    "7463": { "id": 7463, "name": "Degree of chordee", "color": "8B008B" },
    "7464": { "id": 7464, "name": "Degree of occlusion", "color": "800080" },
    "7465": { "id": 7465, "name": "Degree of involvement", "color": "4B0082" },
    "7466": { "id": 7466, "name": "Degree of thrombosis", "color": "6A5ACD" },
    "7467": { "id": 7467, "name": "With pattern", "color": "483D8B" },
    "7468": { "id": 7468, "name": "Intention - attribute", "color": "00FFFF" },
    "7469": { "id": 7469, "name": "Length", "color": "00FFFF" },
    "7470": { "id": 7470, "name": "Has action", "color": "E0FFFF" },
    "7471": { "id": 7471, "name": "Laterality of ureteric anastomosis", "color": "AFEEEE" },
    "7472": { "id": 7472, "name": "Ureteric implantation technique", "color": "7FFFD4" },
    "7473": { "id": 7473, "name": "Erosiveness", "color": "40E0D0" },
    "7474": { "id": 7474, "name": "Measure", "color": "48D1CC" },
    "7475": { "id": 7475, "name": "Tooth surface counted", "color": "00CED1" },
    "7476": { "id": 7476, "name": "Teeth counted", "color": "5F9EA0" },
    "7477": { "id": 7477, "name": "Quantity", "color": "4682B4" },
    "7478": { "id": 7478, "name": "Impedance", "color": "B0C4DE" },
    "7479": { "id": 7479, "name": "Score", "color": "B0E0E6" },
    "7480": { "id": 7480, "name": "Prognostic score", "color": "ADD8E6" },
    "7481": { "id": 7481, "name": "Multiplicity", "color": "87CEEB" },
    "7482": { "id": 7482, "name": "Dosage", "color": "87CEFA" },
    "7483": { "id": 7483, "name": "Number", "color": "00BFFF" },
    "7484": { "id": 7484, "name": "Inspiratory/expiratory ratio", "color": "1E90FF" },
    "7485": { "id": 7485, "name": "Ventricular pressures", "color": "6495ED" },
    "7486": { "id": 7486, "name": "Measured from", "color": "7B68EE" },
    "7487": { "id": 7487, "name": "Surface measured from", "color": "4169E1" },
    "7488": { "id": 7488, "name": "Brachytherapy dose rate", "color": "0000FF" },
    "7489": { "id": 7489, "name": "Measured to", "color": "0000CD" },
    "7490": { "id": 7490, "name": "Surface measured to", "color": "00008B" },
    "7491": { "id": 7491, "name": "Extent of procedure", "color": "000080" },
    "7492": { "id": 7492, "name": "Distal extent of patch", "color": "191970" },
    "7493": { "id": 7493, "name": "Extent of biopsy", "color": "FFF8DC" },
    "7494": { "id": 7494, "name": "With cleanliness", "color": "FFEBCD" },
    "7495": { "id": 7495, "name": "Bulky disease status", "color": "FFE4C4" },
    "7496": { "id": 7496, "name": "Orifice morphology", "color": "FFDEAD" },
    "7497": { "id": 7497, "name": "With shape", "color": "00b652" },
    "7498": { "id": 7498, "name": "Has assessment", "color": "DEB887" },
    "7499": { "id": 7499, "name": "Laterality of diverticula", "color": "D2B48C" },
    "7500": { "id": 7500, "name": "Stinging insect", "color": "BC8F8F" },
    "7501": { "id": 7501, "name": "Interpretation", "color": "F4A460" },
    "7502": { "id": 7502, "name": "Nursing additional attributes", "color": "DAA520" },
    "7503": { "id": 7503, "name": "Suggestive of", "color": "B8860B" },
    "7504": { "id": 7504, "name": "Radiopharmaceutical carrier", "color": "CD853F" },
    "7505": { "id": 7505, "name": "Substance measured", "color": "D2691E" },
    "7506": { "id": 7506, "name": "Allergen measured", "color": "8B4513" },
    "7507": { "id": 7507, "name": "Antibiotic tested", "color": "A0522D" },
    "7508": { "id": 7508, "name": "Anesthetic agent measured", "color": "A52A2A" },
    "7509": { "id": 7509, "name": "Analyte measured", "color": "800000" },
    "7510": { "id": 7510, "name": "Hormone in excess", "color": "A0522D" },
    "7511": { "id": 7511, "name": "Drug measured", "color": "F08080" },
    "7512": { "id": 7512, "name": "Substance secreted", "color": "FA8072" },
    "7513": { "id": 7513, "name": "Hormone or peptide secreted", "color": "E9967A" },
    "7514": { "id": 7514, "name": "Hormone secreted", "color": "FFA07A" },
    "7515": { "id": 7515, "name": "Hormone secreted ectopically", "color": "DC143C" },
    "7516": { "id": 7516, "name": "Paraprotein secreted", "color": "FF0000" },
    "7517": { "id": 7517, "name": "Contents", "color": "B22222" },
    "7518": { "id": 7518, "name": "Cylinder contents", "color": "8B0000" },
    "7519": { "id": 7519, "name": "Pipeline contents", "color": "FFC0CB" },
    "7520": { "id": 7520, "name": "Additives to fluid", "color": "FFB6C1" },
    "7521": { "id": 7521, "name": "Base used in replacement fluid", "color": "FF69B4" },
    "7522": { "id": 7522, "name": "Base used in dialysate", "color": "FF1493" },
    "7523": { "id": 7523, "name": "Agent", "color": "C71585" },
    "7524": { "id": 7524, "name": "Correcting agent", "color": "DB7093" },
    "7525": { "id": 7525, "name": "Augmenting agent", "color": "ADFF2F" },
    "7526": { "id": 7526, "name": "Polishing agent", "color": "7FFF00" },
    "7527": { "id": 7527, "name": "Cement cure", "color": "7CFC00" },
    "7528": { "id": 7528, "name": "Calcium content of dialysate", "color": "00FF00" },
    "7529": { "id": 7529, "name": "Product transfused", "color": "32CD32" },
    "7530": { "id": 7530, "name": "Toxin production", "color": "98FB98" },
    "7531": { "id": 7531, "name": "Sealer", "color": "5db4cc" },
    "7532": { "id": 7532, "name": "Substance aspirated", "color": "00FA9A" },
    "7533": { "id": 7533, "name": "Medicament", "color": "00FF7F" },
    "7534": { "id": 7534, "name": "Allergen", "color": "3CB371" },
    "7535": { "id": 7535, "name": "Aeroallergen", "color": "2E8B57" },
    "7536": { "id": 7536, "name": "Usage", "color": "228B22" },
    "7537": { "id": 7537, "name": "Use of", "color": "008000" },
    "7538": { "id": 7538, "name": "Use of facebow", "color": "006400" },
    "7539": { "id": 7539, "name": "Use of skin marker", "color": "9ACD32" },
    "7540": { "id": 7540, "name": "Use of tocolytic", "color": "6B8E23" },
    "7541": { "id": 7541, "name": "Use of Carrel patch", "color": "808000" },
    "7542": { "id": 7542, "name": "Use of cross-compression", "color": "556B2F" },
    "7543": { "id": 7543, "name": "Use of anticoagulation", "color": "66CDAA" },
    "7544": { "id": 7544, "name": "Use of dentine bonding agent", "color": "8FBC8F" },
    "7545": { "id": 7545, "name": "Use of prosthesis", "color": "20B2AA" },
    "7546": { "id": 7546, "name": "Use of implant", "color": "008B8B" },
    "7547": { "id": 7547, "name": "Use of pack", "color": "008080" },
    "7548": { "id": 7548, "name": "Use of graft", "color": "FFA07A" },
    "7549": { "id": 7549, "name": "Use of etchant", "color": "FF7F50" },
    "7550": { "id": 7550, "name": "Structural embryological defect", "color": "FF6347" },
    "7551": { "id": 7551, "name": "Involvement", "color": "FF4500" },
    "7552": { "id": 7552, "name": "Area of involvement", "color": "FF8C00" },
    "7553": { "id": 7553, "name": "Has development", "color": "FFA500" },
    "7554": { "id": 7554, "name": "Temperature", "color": "FFD700" },
    "7555": { "id": 7555, "name": "Controlled temperature", "color": "FFFF00" },
    "7556": { "id": 7556, "name": "Controlled core temperature", "color": "5ed3ff" },
    "7557": { "id": 7557, "name": "Has strength", "color": "9ed563" },
    "7558": { "id": 7558, "name": "Parameter", "color": "5F9EA0" },
    "7559": { "id": 7559, "name": "Physiotherapy treatment parameters", "color": "FFEFD5" },
    "7560": { "id": 7560, "name": "Transcutaneous electrical nerve stimulation stimulus frequency", "color": "FFE4B5" },
    "7561": { "id": 7561, "name": "Instrumentation", "color": "FFDAB9" },
    "7562": { "id": 7562, "name": "Associated subcellular entity", "color": "EEE8AA" },
    "7563": { "id": 7563, "name": "Provocation", "color": "F0E68C" },
    "7564": { "id": 7564, "name": "Physiological stress", "color": "BDB76B" },
    "7565": { "id": 7565, "name": "Pharmacological stress", "color": "E6E6FA" },
    "7566": { "id": 7566, "name": "With texture", "color": "D8BFD8" },
    "7567": { "id": 7567, "name": "Object", "color": "DDA0DD" },
    "7568": { "id": 7568, "name": "Safety device", "color": "EE82EE" },
    "7569": { "id": 7569, "name": "Shoe site", "color": "DA70D6" },
    "7570": { "id": 7570, "name": "Lock pins", "color": "FF00FF" },
    "7571": { "id": 7571, "name": "Component investigated", "color": "FF00FF" },
    "7572": { "id": 7572, "name": "Hook", "color": "BA55D3" },
    "7573": { "id": 7573, "name": "Tray", "color": "9370DB" },
    "7574": { "id": 7574, "name": "Root canal dressing", "color": "8A2BE2" },
    "7575": { "id": 7575, "name": "Acquisition apparatus", "color": "9400D3" },
    "7576": { "id": 7576, "name": "Equipment", "color": "9932CC" },
    "7577": { "id": 7577, "name": "Additional retention", "color": "8B008B" },
    "7578": { "id": 7578, "name": "Stops", "color": "800080" },
    "7579": { "id": 7579, "name": "Temporary seal", "color": "4B0082" },
    "7580": { "id": 7580, "name": "Supporting structure", "color": "6A5ACD" },
    "7581": { "id": 7581, "name": "Type of bridge", "color": "483D8B" },
    "7582": { "id": 7582, "name": "Retainer", "color": "00FFFF" },
    "7583": { "id": 7583, "name": "Adhesive retainer", "color": "00FFFF" },
    "7584": { "id": 7584, "name": "Implant retainer", "color": "E0FFFF" },
    "7585": { "id": 7585, "name": "Torquing auxiliaries", "color": "AFEEEE" },
    "7586": { "id": 7586, "name": "Femoral component", "color": "7FFFD4" },
    "7587": { "id": 7587, "name": "Fixation device", "color": "40E0D0" },
    "7588": { "id": 7588, "name": "Denture", "color": "48D1CC" },
    "7589": { "id": 7589, "name": "Joint component", "color": "00CED1" },
    "7590": { "id": 7590, "name": "Tooth or teeth overlaid", "color": "5F9EA0" },
    "7591": { "id": 7591, "name": "Tooth or teeth replaced", "color": "4682B4" },
    "7592": { "id": 7592, "name": "Instrumentation or fixation device", "color": "B0C4DE" },
    "7593": { "id": 7593, "name": "Aid", "color": "B0E0E6" },
    "7594": { "id": 7594, "name": "Appliance component", "color": "ADD8E6" },
    "7595": { "id": 7595, "name": "Peritoneal dialysis used", "color": "87CEEB" },
    "7596": { "id": 7596, "name": "Without", "color": "87CEFA" },
    "7597": { "id": 7597, "name": "With configuration", "color": "00BFFF" },
    "7598": { "id": 7598, "name": "Configuration of primary curve", "color": "1E90FF" },
    "7599": { "id": 7599, "name": "Membrane configuration", "color": "6495ED" },
    "7600": { "id": 7600, "name": "Fracture configuration", "color": "7B68EE" },
    "7601": { "id": 7601, "name": "Condition", "color": "4169E1" },
    "7602": { "id": 7602, "name": "Condition of pupil", "color": "0000FF" },
    "7603": { "id": 7603, "name": "Conditions for acquisition", "color": "0000CD" },
    "7604": { "id": 7604, "name": "Test conditions", "color": "00008B" },
    "7605": { "id": 7605, "name": "Position", "color": "000080" },
    "7606": { "id": 7606, "name": "Position of laryngoscope tip", "color": "191970" },
    "7607": { "id": 7607, "name": "Location of testis", "color": "FFF8DC" },
    "7608": { "id": 7608, "name": "Positioning", "color": "FFEBCD" },
    "7609": { "id": 7609, "name": "Position when rocking", "color": "FFE4C4" },
    "7610": { "id": 7610, "name": "Patient position", "color": "FFDEAD" },
    "7611": { "id": 7611, "name": "Position of cord", "color": "00b652" },
    "7612": { "id": 7612, "name": "With consistency", "color": "DEB887" },
    "7613": { "id": 7613, "name": "Sudden onset", "color": "D2B48C" },
    "7614": { "id": 7614, "name": "Site", "color": "BC8F8F" },
    "7615": { "id": 7615, "name": "Part", "color": "F4A460" },
    "7616": { "id": 7616, "name": "Moiety removed", "color": "DAA520" },
    "7617": { "id": 7617, "name": "Tissue repositioned", "color": "B8860B" },
    "7618": { "id": 7618, "name": "Organ failure status", "color": "CD853F" },
    "7619": { "id": 7619, "name": "Tissue torn", "color": "D2691E" },
    "7620": { "id": 7620, "name": "Surface", "color": "8B4513" },
    "7621": { "id": 7621, "name": "Plane", "color": "A0522D" },
    "7622": { "id": 7622, "name": "Plane of laceration", "color": "A52A2A" },
    "7623": { "id": 7623, "name": "Region", "color": "800000" },
    "7624": { "id": 7624, "name": "Dental region", "color": "A0522D" },
    "7625": { "id": 7625, "name": "Part of tooth", "color": "F08080" },
    "7626": { "id": 7626, "name": "Period of onset", "color": "FA8072" },
    "7627": { "id": 7627, "name": "Has recipient category", "color": "E9967A" },
    "7628": { "id": 7628, "name": "Required property", "color": "FFA07A" },
    "7629": { "id": 7629, "name": "With color", "color": "DC143C" },
    "7630": { "id": 7630, "name": "Associated etiologic chemical", "color": "FF0000" },
    "7631": { "id": 7631, "name": "Direction", "color": "B22222" },
    "7632": { "id": 7632, "name": "Direction of gaze", "color": "8B0000" },
    "7633": { "id": 7633, "name": "Direction of movement", "color": "FFC0CB" },
    "7634": { "id": 7634, "name": "Trunk direction", "color": "FFB6C1" },
    "7635": { "id": 7635, "name": "Direction of tear", "color": "FF69B4" },
    "7636": { "id": 7636, "name": "Direction of prolapse", "color": "FF1493" },
    "7637": { "id": 7637, "name": "Axial direction", "color": "C71585" },
    "7638": { "id": 7638, "name": "Direction of catheter threading", "color": "DB7093" },
    "7639": { "id": 7639, "name": "Direction of angulation", "color": "ADFF2F" },
    "7640": { "id": 7640, "name": "Direction of flow", "color": "7FFF00" },
    "7641": { "id": 7641, "name": "Direction of prism base", "color": "7CFC00" },
    "7642": { "id": 7642, "name": "Direction of locking", "color": "00FF00" },
    "7643": { "id": 7643, "name": "Direction of dislocation", "color": "32CD32" },
    "7644": { "id": 7644, "name": "Direction of displacement", "color": "98FB98" },
    "7645": { "id": 7645, "name": "Direction of intussusception", "color": "5db4cc" },
    "7646": { "id": 7646, "name": "Direction of shunt", "color": "00FA9A" },
    "7647": { "id": 7647, "name": "Shunt from", "color": "00FF7F" },
    "7648": { "id": 7648, "name": "Shunt to", "color": "3CB371" },
    "7649": { "id": 7649, "name": "Direction of rotation", "color": "2E8B57" },
    "7650": { "id": 7650, "name": "Meridional direction", "color": "228B22" },
    "7651": { "id": 7651, "name": "With distance", "color": "008000" },
    "7652": { "id": 7652, "name": "Followed by", "color": "006400" },
    "7653": { "id": 7653, "name": "Insidious onset", "color": "9ACD32" },
    "7654": { "id": 7654, "name": "Has provider", "color": "6B8E23" },
    "7655": { "id": 7655, "name": "Direction of approach", "color": "808000" },
    "7656": { "id": 7656, "name": "Location", "color": "556B2F" },
    "7657": { "id": 7657, "name": "Tube attachment to cup", "color": "66CDAA" },
    "7658": { "id": 7658, "name": "With trend", "color": "8FBC8F" },
    "7659": { "id": 7659, "name": "Has plan", "color": "20B2AA" },
    "7660": { "id": 7660, "name": "Associated etiologic living organism", "color": "008B8B" },
    "7661": { "id": 7661, "name": "Not free of", "color": "008080" },
    "7662": { "id": 7662, "name": "Level", "color": "FFA07A" },
    "7663": { "id": 7663, "name": "Fracture level", "color": "FF7F50" },
    "7664": { "id": 7664, "name": "Level of obstruction", "color": "FF6347" },
    "7665": { "id": 7665, "name": "Upper level of stenotic segment", "color": "FF4500" },
    "7666": { "id": 7666, "name": "Clark level", "color": "FF8C00" },
    "7667": { "id": 7667, "name": "Axillary node level", "color": "FFA500" },
    "7668": { "id": 7668, "name": "Conscious level", "color": "FFD700" },
    "7669": { "id": 7669, "name": "Lower level of stenotic segment", "color": "FFFF00" },
    "7670": { "id": 7670, "name": "Subacute onset", "color": "5ed3ff" },
    "7671": { "id": 7671, "name": "View", "color": "9ed563" },
    "7672": { "id": 7672, "name": "View at intubation", "color": "5F9EA0" },
    "7673": { "id": 7673, "name": "Temporal relationship", "color": "FFEFD5" },
    "7674": { "id": 7674, "name": "Chronicity", "color": "FFE4B5" },
    "7675": { "id": 7675, "name": "Phase", "color": "FFDAB9" },
    "7676": { "id": 7676, "name": "Cardiac cycle phase", "color": "EEE8AA" },
    "7677": { "id": 7677, "name": "Pressure phase", "color": "F0E68C" },
    "7678": { "id": 7678, "name": "Phase of respiration", "color": "BDB76B" },
    "7679": { "id": 7679, "name": "Disease phase", "color": "E6E6FA" },
    "7680": { "id": 7680, "name": "Gas flow phase", "color": "D8BFD8" },
    "7681": { "id": 7681, "name": "End point of test", "color": "DDA0DD" },
    "7682": { "id": 7682, "name": "Episode", "color": "EE82EE" },
    "7683": { "id": 7683, "name": "Date", "color": "DA70D6" },
    "7684": { "id": 7684, "name": "Growth rate", "color": "FF00FF" },
    "7685": { "id": 7685, "name": "Time", "color": "FF00FF" },
    "7686": { "id": 7686, "name": "Timing", "color": "BA55D3" },
    "7687": { "id": 7687, "name": "Timing of stage", "color": "9370DB" },
    "7688": { "id": 7688, "name": "Frequency", "color": "8A2BE2" },
    "7689": { "id": 7689, "name": "Measurement frequency", "color": "9400D3" },
    "7690": { "id": 7690, "name": "Pattern of frequency", "color": "9932CC" },
    "7691": { "id": 7691, "name": "Speed of test", "color": "8B008B" },
    "7692": { "id": 7692, "name": "Duration", "color": "800080" },
    "7693": { "id": 7693, "name": "Time since injury", "color": "4B0082" },
    "7694": { "id": 7694, "name": "Permanence", "color": "6A5ACD" },
    "7695": { "id": 7695, "name": "Permanence of cannulation", "color": "483D8B" },
    "7696": { "id": 7696, "name": "Treats", "color": "00FFFF" },
    "7697": { "id": 7697, "name": "Bounded by (BB)", "color": "00FFFF" },
    "7698": { "id": 7698, "name": "Associated etiologic living organism, direct cause", "color": "E0FFFF" },
    "7699": { "id": 7699, "name": "Extent of insertion", "color": "AFEEEE" },
    "7700": { "id": 7700, "name": "Septal morphology", "color": "7FFFD4" },
    "7701": { "id": 7701, "name": "Typing", "color": "40E0D0" },
    "7702": { "id": 7702, "name": "Morphological type", "color": "48D1CC" },
    "7703": { "id": 7703, "name": "Serotype", "color": "00CED1" },
    "7704": { "id": 7704, "name": "Toxin A Serotype (USA)", "color": "5F9EA0" },
    "7705": { "id": 7705, "name": "Toxin A Serotype (UK)", "color": "4682B4" },
    "7706": { "id": 7706, "name": "Toxin A Serotype (Japan)", "color": "B0C4DE" },
    "7707": { "id": 7707, "name": "Cowan serotype", "color": "B0E0E6" },
    "7708": { "id": 7708, "name": "T antigen serotype", "color": "ADD8E6" },
    "7709": { "id": 7709, "name": "Coagulase serotype", "color": "87CEEB" },
    "7710": { "id": 7710, "name": "Serosubtype", "color": "87CEFA" },
    "7711": { "id": 7711, "name": "M antigen serotype", "color": "00BFFF" },
    "7712": { "id": 7712, "name": "O Serotype", "color": "1E90FF" },
    "7713": { "id": 7713, "name": "H Serotype", "color": "6495ED" },
    "7714": { "id": 7714, "name": "K Serotype", "color": "7B68EE" },
    "7715": { "id": 7715, "name": "Serotype (Chinese)", "color": "4169E1" },
    "7716": { "id": 7716, "name": "Serotype (United Kingdom)", "color": "0000FF" },
    "7717": { "id": 7717, "name": "Oxford subgroup", "color": "0000CD" },
    "7718": { "id": 7718, "name": "Staging type", "color": "00008B" },
    "7719": { "id": 7719, "name": "Subtype", "color": "000080" },
    "7720": { "id": 7720, "name": "Antigenic factor type", "color": "191970" },
    "7721": { "id": 7721, "name": "Virus type", "color": "FFF8DC" },
    "7722": { "id": 7722, "name": "Serogroup", "color": "FFEBCD" },
    "7723": { "id": 7723, "name": "Colicin type", "color": "FFE4C4" },
    "7724": { "id": 7724, "name": "Microbiological strain", "color": "FFDEAD" },
    "7725": { "id": 7725, "name": "Resistotype", "color": "00b652" },
    "7726": { "id": 7726, "name": "Bacteriocin type", "color": "DEB887" },
    "7727": { "id": 7727, "name": "Lysovar", "color": "D2B48C" },
    "7728": { "id": 7728, "name": "Phage type", "color": "BC8F8F" },
    "7729": { "id": 7729, "name": "Phage type variant", "color": "F4A460" },
    "7730": { "id": 7730, "name": "Phage type Dean and Williams", "color": "DAA520" },
    "7731": { "id": 7731, "name": "Phage type Pulverer", "color": "B8860B" },
    "7732": { "id": 7732, "name": "Biotype", "color": "CD853F" },
    "7733": { "id": 7733, "name": "Deoxyribonucleic acid restriction fragment type", "color": "D2691E" },
    "7734": { "id": 7734, "name": "Test type", "color": "8B4513" },
    "7735": { "id": 7735, "name": "FAB type", "color": "A0522D" },
    "7736": { "id": 7736, "name": "Lancefield group", "color": "A52A2A" },
    "7737": { "id": 7737, "name": "Yeast form type", "color": "800000" },
    "7738": { "id": 7738, "name": "Lipo-oligosaccharide immunotype", "color": "A0522D" },
    "7739": { "id": 7739, "name": "Antigen type", "color": "F08080" },
    "7740": { "id": 7740, "name": "R antigen type", "color": "FA8072" },
    "7741": { "id": 7741, "name": "Isoenzyme type", "color": "E9967A" },
    "7742": { "id": 7742, "name": "Toxin type", "color": "FFA07A" },
    "7743": { "id": 7743, "name": "Antibody type", "color": "DC143C" },
    "7744": { "id": 7744, "name": "Viral antibody type", "color": "FF0000" },
    "7745": { "id": 7745, "name": "Bacterial antibody type", "color": "B22222" },
    "7746": { "id": 7746, "name": "Hemolysin type", "color": "8B0000" },
    "7747": { "id": 7747, "name": "Pyocin type", "color": "FFC0CB" },
    "7748": { "id": 7748, "name": "Biogroup", "color": "FFB6C1" },
    "7749": { "id": 7749, "name": "Restriction fragment length polymorphism type", "color": "FF69B4" },
    "7750": { "id": 7750, "name": "Histological type", "color": "FF1493" },
    "7751": { "id": 7751, "name": "Blauth type", "color": "C71585" },
    "7752": { "id": 7752, "name": "Condition activity", "color": "DB7093" },
    "7753": { "id": 7753, "name": "Independent of", "color": "ADFF2F" },
    "7754": { "id": 7754, "name": "Complication", "color": "7FFF00" },
    "7755": { "id": 7755, "name": "Limitation", "color": "7CFC00" },
    "7756": { "id": 7756, "name": "Upper limit of fusion", "color": "00FF00" },
    "7757": { "id": 7757, "name": "Upper limit of primary deformity", "color": "32CD32" },
    "7758": { "id": 7758, "name": "Upper limit of fixation", "color": "98FB98" },
    "7759": { "id": 7759, "name": "Upper limit of decompression", "color": "5db4cc" },
    "7760": { "id": 7760, "name": "Lower limit of decompression", "color": "00FA9A" },
    "7761": { "id": 7761, "name": "Lower limit of fusion", "color": "00FF7F" },
    "7762": { "id": 7762, "name": "Lower limit of primary deformity", "color": "3CB371" },
    "7763": { "id": 7763, "name": "Lower limit of fixation", "color": "2E8B57" },
    "7764": { "id": 7764, "name": "Associated etiologic living organism, vector", "color": "228B22" },
    "7765": { "id": 7765, "name": "Mount used", "color": "008000" },
    "7766": { "id": 7766, "name": "Mathematical sign", "color": "006400" },
    "7767": { "id": 7767, "name": "First value", "color": "9ACD32" },
    "7768": { "id": 7768, "name": "Second sign", "color": "6B8E23" },
    "7769": { "id": 7769, "name": "Second value", "color": "808000" },
    "7770": { "id": 7770, "name": "First sign", "color": "556B2F" },
    "7771": { "id": 7771, "name": "Associated disorder", "color": "66CDAA" },
    "7772": { "id": 7772, "name": "Tissue used", "color": "8FBC8F" },
    "7773": { "id": 7773, "name": "Digit used", "color": "20B2AA" },
    "7774": { "id": 7774, "name": "Replacement bladder outlet", "color": "008B8B" },
    "7775": { "id": 7775, "name": "Tissue used for augmentation", "color": "008080" },
    "7776": { "id": 7776, "name": "Graft used", "color": "FFA07A" },
    "7777": { "id": 7777, "name": "Flap", "color": "FF7F50" },
    "7778": { "id": 7778, "name": "Tissue used for bladder replacement", "color": "FF6347" },
    "7779": { "id": 7779, "name": "Rank", "color": "FF4500" },
    "7780": { "id": 7780, "name": "Grading", "color": "FF8C00" },
    "7781": { "id": 7781, "name": "Gass' grading", "color": "FFA500" },
    "7782": { "id": 7782, "name": "Grade", "color": "FFD700" },
    "7783": { "id": 7783, "name": "Histological grade", "color": "FFFF00" },
    "7784": { "id": 7784, "name": "Fracture grade", "color": "5ed3ff" },
    "7785": { "id": 7785, "name": "Group", "color": "9ed563" },
    "7786": { "id": 7786, "name": "Stage", "color": "5F9EA0" },
    "7787": { "id": 7787, "name": "Stage of reconstruction needing repair", "color": "FFEFD5" },
    "7788": { "id": 7788, "name": "Scale - rank", "color": "FFE4B5" },
    "7789": { "id": 7789, "name": "Edition", "color": "FFDAB9" },
    "7790": { "id": 7790, "name": "Class", "color": "EEE8AA" },
    "7791": { "id": 7791, "name": "Gass classification", "color": "F0E68C" },
    "7792": { "id": 7792, "name": "Fracture class", "color": "BDB76B" },
    "7793": { "id": 7793, "name": "Injury classification", "color": "E6E6FA" },
    "7794": { "id": 7794, "name": "Human leukocyte antigen class defect", "color": "D8BFD8" },
    "7795": { "id": 7795, "name": "Subclass", "color": "DDA0DD" },
    "7796": { "id": 7796, "name": "Classification", "color": "EE82EE" },
    "7797": { "id": 7797, "name": "Relationship", "color": "DA70D6" },
    "7798": { "id": 7798, "name": "System", "color": "FF00FF" },
    "7799": { "id": 7799, "name": "System involved", "color": "FF00FF" },
    "7800": { "id": 7800, "name": "Delivery system", "color": "BA55D3" },
    "7801": { "id": 7801, "name": "For which was done", "color": "9370DB" },
    "7802": { "id": 7802, "name": "Associated etiologic deficiency", "color": "8A2BE2" },
    "7803": { "id": 7803, "name": "Optical aid used", "color": "9400D3" },
    "7804": { "id": 7804, "name": "Introducer used", "color": "9932CC" },
    "7805": { "id": 7805, "name": "With distribution", "color": "8B008B" },
    "7806": { "id": 7806, "name": "Associated mechanism", "color": "800080" },
    "7807": { "id": 7807, "name": "Measures", "color": "4B0082" },
    "7808": { "id": 7808, "name": "Material used", "color": "6A5ACD" },
    "7809": { "id": 7809, "name": "Threshold", "color": "483D8B" },
    "7810": { "id": 7810, "name": "Qualitative observation", "color": "00FFFF" },
    "7811": { "id": 7811, "name": "Patency", "color": "00FFFF" },
    "7812": { "id": 7812, "name": "Stent patency", "color": "E0FFFF" },
    "7813": { "id": 7813, "name": "Innervation", "color": "AFEEEE" },
    "7814": { "id": 7814, "name": "Reflex elicited", "color": "7FFFD4" },
    "7815": { "id": 7815, "name": "Affective response to pain", "color": "40E0D0" },
    "7816": { "id": 7816, "name": "Hemolysis", "color": "48D1CC" },
    "7817": { "id": 7817, "name": "Specific heart sound", "color": "00CED1" },
    "7818": { "id": 7818, "name": "Texture", "color": "5F9EA0" },
    "7819": { "id": 7819, "name": "Radioisotope uptake", "color": "4682B4" },
    "7820": { "id": 7820, "name": "Donor relationship", "color": "B0C4DE" },
    "7821": { "id": 7821, "name": "Regularity", "color": "B0E0E6" },
    "7822": { "id": 7822, "name": "Lesion attributes", "color": "ADD8E6" },
    "7823": { "id": 7823, "name": "Opacification", "color": "87CEEB" },
    "7824": { "id": 7824, "name": "Edge of lesion", "color": "87CEFA" },
    "7825": { "id": 7825, "name": "Pulsatility", "color": "00BFFF" },
    "7826": { "id": 7826, "name": "Resonance", "color": "1E90FF" },
    "7827": { "id": 7827, "name": "Shape", "color": "6495ED" },
    "7828": { "id": 7828, "name": "Mobility of lesion", "color": "7B68EE" },
    "7829": { "id": 7829, "name": "Form of lesion", "color": "4169E1" },
    "7830": { "id": 7830, "name": "Form of skin change", "color": "0000FF" },
    "7831": { "id": 7831, "name": "Form of septum", "color": "0000CD" },
    "7832": { "id": 7832, "name": "Form of stenosis", "color": "00008B" },
    "7833": { "id": 7833, "name": "Form of goiter", "color": "000080" },
    "7834": { "id": 7834, "name": "Form of duplication", "color": "191970" },
    "7835": { "id": 7835, "name": "Form of cholangitis", "color": "FFF8DC" },
    "7836": { "id": 7836, "name": "Form of adhesion", "color": "FFEBCD" },
    "7837": { "id": 7837, "name": "Form of cyst", "color": "FFE4C4" },
    "7838": { "id": 7838, "name": "Consistency", "color": "FFDEAD" },
    "7839": { "id": 7839, "name": "Tension of tympanic membrane", "color": "00b652" },
    "7840": { "id": 7840, "name": "Character of pain", "color": "DEB887" },
    "7841": { "id": 7841, "name": "Relationship to transcutaneous electrical nerve stimulation threshold", "color": "D2B48C" },
    "7842": { "id": 7842, "name": "Mobility", "color": "BC8F8F" },
    "7843": { "id": 7843, "name": "Nodularity", "color": "F4A460" },
    "7844": { "id": 7844, "name": "Displacement", "color": "DAA520" },
    "7845": { "id": 7845, "name": "Ventricular dominance", "color": "B8860B" },
    "7846": { "id": 7846, "name": "Form", "color": "CD853F" },
    "7847": { "id": 7847, "name": "Waveform", "color": "D2691E" },
    "7848": { "id": 7848, "name": "Flow waveform", "color": "8B4513" },
    "7849": { "id": 7849, "name": "Pressure waveform", "color": "A0522D" },
    "7850": { "id": 7850, "name": "Parasite form seen", "color": "A52A2A" },
    "7851": { "id": 7851, "name": "Integrity", "color": "800000" },
    "7852": { "id": 7852, "name": "Integrity of globe", "color": "A0522D" },
    "7853": { "id": 7853, "name": "Integrity of skin", "color": "F08080" },
    "7854": { "id": 7854, "name": "Viability of gut", "color": "FA8072" },
    "7855": { "id": 7855, "name": "Quality", "color": "E9967A" },
    "7856": { "id": 7856, "name": "Quality of fixation", "color": "FFA07A" },
    "7857": { "id": 7857, "name": "Composition of stone", "color": "DC143C" },
    "7858": { "id": 7858, "name": "Expectoration observation", "color": "FF0000" },
    "7859": { "id": 7859, "name": "Character of effusion", "color": "B22222" },
    "7860": { "id": 7860, "name": "Nature", "color": "8B0000" },
    "7861": { "id": 7861, "name": "Nature of disorder", "color": "FFC0CB" },
    "7862": { "id": 7862, "name": "Nature of endoscopy", "color": "FFB6C1" },
    "7863": { "id": 7863, "name": "Nature of disease", "color": "FF69B4" },
    "7864": { "id": 7864, "name": "Nature of goiter", "color": "FF1493" },
    "7865": { "id": 7865, "name": "Nature of communication", "color": "C71585" },
    "7866": { "id": 7866, "name": "Nature of operation", "color": "DB7093" },
    "7867": { "id": 7867, "name": "Nature of biopsy", "color": "ADFF2F" },
    "7868": { "id": 7868, "name": "Extent of duplication", "color": "7FFF00" },
    "7869": { "id": 7869, "name": "Associated etiologic physical agent", "color": "7CFC00" },
    "7870": { "id": 7870, "name": "Action", "color": "00FF00" },
    "7871": { "id": 7871, "name": "Dialysate regeneration", "color": "32CD32" },
    "7872": { "id": 7872, "name": "Preserving", "color": "98FB98" },
    "7873": { "id": 7873, "name": "Subprocedure", "color": "5db4cc" },
    "7874": { "id": 7874, "name": "Cuff deflated", "color": "00FA9A" },
    "7875": { "id": 7875, "name": "Control", "color": "00FF7F" },
    "7876": { "id": 7876, "name": "Image control", "color": "3CB371" },
    "7877": { "id": 7877, "name": "Control of induction", "color": "2E8B57" },
    "7878": { "id": 7878, "name": "Volume control", "color": "228B22" },
    "7879": { "id": 7879, "name": "Control during cannula insertion", "color": "008000" },
    "7880": { "id": 7880, "name": "Control of anesthesia", "color": "006400" },
    "7881": { "id": 7881, "name": "Prescription", "color": "9ACD32" },
    "7882": { "id": 7882, "name": "Operation performed", "color": "6B8E23" },
    "7883": { "id": 7883, "name": "Position check", "color": "808000" },
    "7884": { "id": 7884, "name": "Present procedure", "color": "556B2F" },
    "7885": { "id": 7885, "name": "Insertion of drain - attribute", "color": "66CDAA" },
    "7886": { "id": 7886, "name": "Splintage", "color": "8FBC8F" },
    "7887": { "id": 7887, "name": "Supplementary splintage", "color": "20B2AA" },
    "7888": { "id": 7888, "name": "Type of restoration", "color": "008B8B" },
    "7889": { "id": 7889, "name": "Obtained", "color": "008080" },
    "7890": { "id": 7890, "name": "Application", "color": "FFA07A" },
    "7891": { "id": 7891, "name": "Construction", "color": "FF7F50" },
    "7892": { "id": 7892, "name": "Adjustment", "color": "FF6347" },
    "7893": { "id": 7893, "name": "Activation technique", "color": "FF4500" },
    "7894": { "id": 7894, "name": "Incision - attribute", "color": "FF8C00" },
    "7895": { "id": 7895, "name": "Incision into uterus", "color": "FFA500" },
    "7896": { "id": 7896, "name": "Cuff inflated", "color": "FFD700" },
    "7897": { "id": 7897, "name": "Elicited by", "color": "FFFF00" },
    "7898": { "id": 7898, "name": "Has time aspect", "color": "5ed3ff" },
    "7899": { "id": 7899, "name": "With quality", "color": "9ed563" },
    "7900": { "id": 7900, "name": "Introduces", "color": "5F9EA0" },
    "7901": { "id": 7901, "name": "With", "color": "FFEFD5" },
    "7902": { "id": 7902, "name": "Condition course", "color": "FFE4B5" },
    "7903": { "id": 7903, "name": "Method access", "color": "FFDAB9" },
    "7904": { "id": 7904, "name": "Arising in", "color": "EEE8AA" },
    "7905": { "id": 7905, "name": "Mechanism", "color": "F0E68C" },
    "7906": { "id": 7906, "name": "Mechanism of syncope", "color": "BDB76B" },
    "7907": { "id": 7907, "name": "Electrical mechanism", "color": "E6E6FA" },
    "7908": { "id": 7908, "name": "Continence mechanism", "color": "D8BFD8" },
    "7909": { "id": 7909, "name": "Infarct mechanism", "color": "DDA0DD" },
    "7910": { "id": 7910, "name": "Compatible with", "color": "EE82EE" },
    "7911": { "id": 7911, "name": "Clearance", "color": "DA70D6" },
    "7912": { "id": 7912, "name": "Condition severity", "color": "FF00FF" },
    "7913": { "id": 7913, "name": "Removes from", "color": "FF00FF" },
    "7914": { "id": 7914, "name": "Has scale type", "color": "BA55D3" },
    "7915": { "id": 7915, "name": "Time course", "color": "9370DB" },
    "7916": { "id": 7916, "name": "Associated function", "color": "8A2BE2" },
    "7917": { "id": 7917, "name": "Removes", "color": "9400D3" },
    "7918": { "id": 7918, "name": "Geometry", "color": "9932CC" },
    "7919": { "id": 7919, "name": "Correction applied", "color": "8B008B" },
    "7920": { "id": 7920, "name": "Continuity", "color": "800080" },
    "7921": { "id": 7921, "name": "Alignment of fracture", "color": "4B0082" },
    "7922": { "id": 7922, "name": "Fracture stability", "color": "6A5ACD" },
    "7923": { "id": 7923, "name": "Congruity", "color": "483D8B" },
    "7924": { "id": 7924, "name": "Incongruous", "color": "00FFFF" },
    "7925": { "id": 7925, "name": "Congruous", "color": "00FFFF" },
    "7926": { "id": 7926, "name": "Loss of joint congruency", "color": "E0FFFF" },
    "7927": { "id": 7927, "name": "Type of food and drink", "color": "AFEEEE" },
    "7928": { "id": 7928, "name": "General food type", "color": "7FFFD4" },
    "7929": { "id": 7929, "name": "Drink", "color": "40E0D0" },
    "7930": { "id": 7930, "name": "In", "color": "48D1CC" },
    "7931": { "id": 7931, "name": "Inward", "color": "00CED1" },
    "7932": { "id": 7932, "name": "Assists", "color": "5F9EA0" },
    "7933": { "id": 7933, "name": "Nodes removed", "color": "4682B4" },
    "7934": { "id": 7934, "name": "Extent of disease", "color": "B0C4DE" },
    "7935": { "id": 7935, "name": "Extent of radial penetration", "color": "B0E0E6" },
    "7936": { "id": 7936, "name": "Extent of colitis", "color": "ADD8E6" },
    "7937": { "id": 7937, "name": "Extent of tumor", "color": "87CEEB" },
    "7938": { "id": 7938, "name": "Extent of reflux", "color": "87CEFA" },
    "7939": { "id": 7939, "name": "Extent of defect", "color": "00BFFF" },
    "7940": { "id": 7940, "name": "Extent of tear", "color": "1E90FF" },
    "7941": { "id": 7941, "name": "Deficiency", "color": "6495ED" },
    "7942": { "id": 7942, "name": "Triggering mode", "color": "7B68EE" },
    "7943": { "id": 7943, "name": "Extent", "color": "4169E1" },
    "7944": { "id": 7944, "name": "Administered substance", "color": "0000FF" },
    "7945": { "id": 7945, "name": "Procedure technique", "color": "0000CD" },
    "7946": { "id": 7946, "name": "Extent of removal", "color": "00008B" },
    "7947": { "id": 7947, "name": "Extent of stone removal", "color": "000080" },
    "7948": { "id": 7948, "name": "Extent of resection", "color": "191970" },
    "7949": { "id": 7949, "name": "Level of resection", "color": "FFF8DC" },
    "7950": { "id": 7950, "name": "Extent of excision", "color": "FFEBCD" },
    "7951": { "id": 7951, "name": "Brought on by", "color": "FFE4C4" },
    "7952": { "id": 7952, "name": "Investigates", "color": "FFDEAD" },
    "7953": { "id": 7953, "name": "Site of maximal intensity", "color": "00b652" },
    "7954": { "id": 7954, "name": "Status", "color": "DEB887" },
    "7955": { "id": 7955, "name": "Marker status", "color": "D2B48C" },
    "7956": { "id": 7956, "name": "Lymph nodal status", "color": "BC8F8F" },
    "7957": { "id": 7957, "name": "Retroperitoneal nodal volume status", "color": "F4A460" },
    "7958": { "id": 7958, "name": "Abdominal node status", "color": "DAA520" },
    "7959": { "id": 7959, "name": "Mediastinal node status", "color": "B8860B" },
    "7960": { "id": 7960, "name": "Neck node status", "color": "CD853F" },
    "7961": { "id": 7961, "name": "Receptor status", "color": "D2691E" },
    "7962": { "id": 7962, "name": "Rhesus status", "color": "8B4513" },
    "7963": { "id": 7963, "name": "Donor status", "color": "A0522D" },
    "7964": { "id": 7964, "name": "ABO status", "color": "A52A2A" },
    "7965": { "id": 7965, "name": "Clone status", "color": "800000" },
    "7966": { "id": 7966, "name": "Patient status", "color": "A0522D" },
    "7967": { "id": 7967, "name": "Uvea G status", "color": "F08080" },
    "7968": { "id": 7968, "name": "Clinical status", "color": "FA8072" },
    "7969": { "id": 7969, "name": "Maternal clinical status", "color": "E9967A" },
    "7970": { "id": 7970, "name": "Tumor status", "color": "FFA07A" },
    "7971": { "id": 7971, "name": "Lymphoma staging symptom status", "color": "DC143C" },
    "7972": { "id": 7972, "name": "Lung status", "color": "FF0000" },
    "7973": { "id": 7973, "name": "Venous tumor invasion status", "color": "B22222" },
    "7974": { "id": 7974, "name": "Lymphatic tumor invasion status", "color": "8B0000" },
    "7975": { "id": 7975, "name": "Serum tumor marker status", "color": "FFC0CB" },
    "7976": { "id": 7976, "name": "System involvement status", "color": "FFB6C1" },
    "7977": { "id": 7977, "name": "Metastatic testicular tumor volume status", "color": "FF69B4" },
    "7978": { "id": 7978, "name": "Metastases status", "color": "FF1493" },
    "7979": { "id": 7979, "name": "Lymphoma extranodal involvement status", "color": "C71585" },
    "7980": { "id": 7980, "name": "Residual tumor status", "color": "DB7093" },
    "7981": { "id": 7981, "name": "Hematogenous metastases status", "color": "ADFF2F" },
    "7982": { "id": 7982, "name": "Hepatic status", "color": "7FFF00" },
    "7983": { "id": 7983, "name": "Lymphoma extralymphatic organ involvement status", "color": "7CFC00" },
    "7984": { "id": 7984, "name": "Scleral invasion status", "color": "00FF00" },
    "7985": { "id": 7985, "name": "Choroidal invasion status", "color": "32CD32" },
    "7986": { "id": 7986, "name": "Splenic involvement status", "color": "98FB98" },
    "7987": { "id": 7987, "name": "Tumor histopathological grade status", "color": "5db4cc" },
    "7988": { "id": 7988, "name": "Optic nerve invasion status", "color": "00FA9A" },
    "7989": { "id": 7989, "name": "C-factor status", "color": "00FF7F" },
    "7990": { "id": 7990, "name": "Central nervous system involvement status", "color": "3CB371" },
    "7991": { "id": 7991, "name": "Carrier status", "color": "2E8B57" },
    "7992": { "id": 7992, "name": "Primary status", "color": "228B22" },
    "7993": { "id": 7993, "name": "Into", "color": "008000" },
    "7994": { "id": 7994, "name": "Histological finding", "color": "006400" },
    "7995": { "id": 7995, "name": "Has subjective observations", "color": "9ACD32" },
    "7996": { "id": 7996, "name": "Branch of", "color": "6B8E23" },
    "7997": { "id": 7997, "name": "Prevents", "color": "808000" },
    "7998": { "id": 7998, "name": "Uses equipment", "color": "556B2F" },
    "7999": { "id": 7999, "name": "Origin", "color": "66CDAA" },
    "8000": { "id": 8000, "name": "Pathogenesis", "color": "8FBC8F" },
    "8001": { "id": 8001, "name": "Following", "color": "20B2AA" },
    "8002": { "id": 8002, "name": "Origin of centrifugal force", "color": "008B8B" },
    "8003": { "id": 8003, "name": "Focal origin", "color": "008080" },
    "8004": { "id": 8004, "name": "Inheritance", "color": "FFA07A" },
    "8005": { "id": 8005, "name": "Gene deletion", "color": "FF7F50" },
    "8006": { "id": 8006, "name": "Chromosomal status", "color": "FF6347" },
    "8007": { "id": 8007, "name": "Source", "color": "FF4500" },
    "8008": { "id": 8008, "name": "Patient source", "color": "FF8C00" },
    "8009": { "id": 8009, "name": "Source of antibody", "color": "FFA500" },
    "8010": { "id": 8010, "name": "Source of infection", "color": "FFD700" },
    "8011": { "id": 8011, "name": "Source of disease", "color": "FFFF00" },
    "8012": { "id": 8012, "name": "Source of information", "color": "5ed3ff" },
    "8013": { "id": 8013, "name": "Information from images", "color": "9ed563" },
    "8014": { "id": 8014, "name": "Source of energy", "color": "5F9EA0" },
    "8015": { "id": 8015, "name": "Source of specimen", "color": "FFEFD5" },
    "8016": { "id": 8016, "name": "Source of radiation", "color": "FFE4B5" },
    "8017": { "id": 8017, "name": "Source of hormone stimulus", "color": "FFDAB9" },
    "8018": { "id": 8018, "name": "Source of semen", "color": "EEE8AA" },
    "8019": { "id": 8019, "name": "Origin of fluid", "color": "F0E68C" },
    "8020": { "id": 8020, "name": "Origin of organ", "color": "BDB76B" },
    "8021": { "id": 8021, "name": "Origin of kidney", "color": "E6E6FA" },
    "8022": { "id": 8022, "name": "Origin of ureter", "color": "D8BFD8" },
    "8023": { "id": 8023, "name": "Species of origin", "color": "DDA0DD" },
    "8024": { "id": 8024, "name": "Origin of lesion", "color": "EE82EE" },
    "8025": { "id": 8025, "name": "Tissue of origin of bladder neck tumor", "color": "DA70D6" },
    "8026": { "id": 8026, "name": "Cause of blockage", "color": "FF00FF" },
    "8027": { "id": 8027, "name": "Etiology", "color": "FF00FF" },
    "8028": { "id": 8028, "name": "Etiology of stenosis", "color": "BA55D3" },
    "8029": { "id": 8029, "name": "Origin of gene defect", "color": "9370DB" },
    "8030": { "id": 8030, "name": "Origin of graft", "color": "8A2BE2" },
    "8031": { "id": 8031, "name": "Has subject of information", "color": "9400D3" },
    "8032": { "id": 8032, "name": "Mechanism of injury", "color": "9932CC" },
    "8033": { "id": 8033, "name": "Disease behavior", "color": "8B008B" },
    "8034": { "id": 8034, "name": "Causing", "color": "800080" },
    "8035": { "id": 8035, "name": "Temporally follows", "color": "4B0082" },
    "8036": { "id": 8036, "name": "Introduces to", "color": "6A5ACD" },
    "8037": { "id": 8037, "name": "Has property", "color": "483D8B" },
    "8038": { "id": 8038, "name": "Context", "color": "00FFFF" },
    "8039": { "id": 8039, "name": "Provoked by", "color": "00FFFF" },
    "8040": { "id": 8040, "name": "Aggravated by", "color": "E0FFFF" },
    "8041": { "id": 8041, "name": "Agent relationship", "color": "AFEEEE" },
    "8042": { "id": 8042, "name": "Used by", "color": "7FFFD4" },
    "8043": { "id": 8043, "name": "Subdivision of", "color": "40E0D0" },
    "8044": { "id": 8044, "name": "Member of", "color": "48D1CC" },
    "8045": { "id": 8045, "name": "Responsible to", "color": "00CED1" },
    "8046": { "id": 8046, "name": "Success of action", "color": "5F9EA0" },
    "8047": { "id": 8047, "name": "Reason and justification", "color": "4682B4" },
    "8048": { "id": 8048, "name": "Reason for stopping test", "color": "B0C4DE" },
    "8049": { "id": 8049, "name": "Indication for", "color": "B0E0E6" },
    "8050": { "id": 8050, "name": "Reason for", "color": "ADD8E6" },
    "8051": { "id": 8051, "name": "Reason for visit diagnosis", "color": "87CEEB" },
    "8052": { "id": 8052, "name": "Reason for action", "color": "87CEFA" },
    "8053": { "id": 8053, "name": "Purpose", "color": "00BFFF" },
    "8054": { "id": 8054, "name": "Outcome of action", "color": "1E90FF" },
    "8055": { "id": 8055, "name": "Ease of action", "color": "6495ED" },
    "8056": { "id": 8056, "name": "Finding status", "color": "7B68EE" },
    "8057": { "id": 8057, "name": "Certainty", "color": "4169E1" },
    "8058": { "id": 8058, "name": "Action status", "color": "0000FF" },
    "8059": { "id": 8059, "name": "Concept model attribute", "color": "0000CD" },
    "8060": { "id": 8060, "name": "Concept model object attribute", "color": "00008B" },
    "8061": { "id": 8061, "name": "Interprets", "color": "000080" },
    "8062": { "id": 8062, "name": "Finding informer", "color": "191970" },
    "8063": { "id": 8063, "name": "Has interpretation", "color": "FFF8DC" },
    "8064": { "id": 8064, "name": "Has definitional manifestation", "color": "FFEBCD" },
    "8065": { "id": 8065, "name": "Subject relationship context", "color": "FFE4C4" },
    "8066": { "id": 8066, "name": "Pathological process", "color": "FFDEAD" },
    "8067": { "id": 8067, "name": "Inheres in", "color": "00b652" },
    "8068": { "id": 8068, "name": "Procedure site", "color": "DEB887" },
    "8069": { "id": 8069, "name": "Procedure site - Indirect", "color": "D2B48C" },
    "8070": { "id": 8070, "name": "Procedure site - Direct", "color": "BC8F8F" },
    "8071": { "id": 8071, "name": "Temporal context", "color": "F4A460" },
    "8072": { "id": 8072, "name": "Time aspect", "color": "DAA520" },
    "8073": { "id": 8073, "name": "Has intent", "color": "B8860B" },
    "8074": { "id": 8074, "name": "Has focus", "color": "CD853F" },
    "8075": { "id": 8075, "name": "Component", "color": "D2691E" },
    "8076": { "id": 8076, "name": "Procedure morphology", "color": "8B4513" },
    "8077": { "id": 8077, "name": "Direct morphology", "color": "A0522D" },
    "8078": { "id": 8078, "name": "Indirect morphology", "color": "A52A2A" },
    "8079": { "id": 8079, "name": "Is about", "color": "800000" },
    "8080": { "id": 8080, "name": "Direct substance", "color": "A0522D" },
    "8081": { "id": 8081, "name": "Recipient category", "color": "F08080" },
    "8082": { "id": 8082, "name": "Episodicity", "color": "FA8072" },
    "8083": { "id": 8083, "name": "Scale type", "color": "E9967A" },
    "8084": { "id": 8084, "name": "Property", "color": "FFA07A" },
    "8085": { "id": 8085, "name": "Occurrence", "color": "DC143C" },
    "8086": { "id": 8086, "name": "Procedure device", "color": "FF0000" },
    "8087": { "id": 8087, "name": "Indirect device", "color": "B22222" },
    "8088": { "id": 8088, "name": "Using device", "color": "8B0000" },
    "8089": { "id": 8089, "name": "Using access device", "color": "FFC0CB" },
    "8090": { "id": 8090, "name": "Direct device", "color": "FFB6C1" },
    "8091": { "id": 8091, "name": "Associated morphology", "color": "FF69B4" },
    "8092": { "id": 8092, "name": "Specimen source identity", "color": "FF1493" },
    "8093": { "id": 8093, "name": "Relative to", "color": "C71585" },
    "8094": { "id": 8094, "name": "Associated procedure", "color": "DB7093" },
    "8095": { "id": 8095, "name": "Associated finding", "color": "ADFF2F" },
    "8096": { "id": 8096, "name": "Measurement method", "color": "7FFF00" },
    "8097": { "id": 8097, "name": "Process output", "color": "7CFC00" },
    "8098": { "id": 8098, "name": "Direct site", "color": "00FF00" },
    "8099": { "id": 8099, "name": "Specimen procedure", "color": "32CD32" },
    "8100": { "id": 8100, "name": "Subject of information", "color": "98FB98" },
    "8101": { "id": 8101, "name": "Precondition", "color": "5db4cc" },
    "8102": { "id": 8102, "name": "Priority", "color": "00FA9A" },
    "8103": { "id": 8103, "name": "Role group", "color": "00FF7F" },
    "8104": { "id": 8104, "name": "Procedure context", "color": "3CB371" },
    "8105": { "id": 8105, "name": "Has manufactured dose form", "color": "2E8B57" },
    "8106": { "id": 8106, "name": "Route of administration", "color": "228B22" },
    "8107": { "id": 8107, "name": "Severity", "color": "008000" },
    "8108": { "id": 8108, "name": "Using energy", "color": "006400" },
    "8109": { "id": 8109, "name": "Access", "color": "9ACD32" },
    "8110": { "id": 8110, "name": "Revision status", "color": "6B8E23" },
    "8111": { "id": 8111, "name": "Finding site", "color": "808000" },
    "8112": { "id": 8112, "name": "Units", "color": "556B2F" },
    "8113": { "id": 8113, "name": "Method", "color": "66CDAA" },
    "8114": { "id": 8114, "name": "Process agent", "color": "8FBC8F" },
    "8115": { "id": 8115, "name": "Laterality", "color": "20B2AA" },
    "8116": { "id": 8116, "name": "Characterizes", "color": "008B8B" },
    "8117": { "id": 8117, "name": "Technique", "color": "008080" },
    "8118": { "id": 8118, "name": "Process duration", "color": "FFA07A" },
    "8119": { "id": 8119, "name": "Specimen substance", "color": "FF7F50" },
    "8120": { "id": 8120, "name": "Specified by", "color": "FF6347" },
    "8121": { "id": 8121, "name": "Has realization", "color": "FF4500" },
    "8122": { "id": 8122, "name": "Has dose form release characteristic", "color": "FF8C00" },
    "8123": { "id": 8123, "name": "Finding method", "color": "FFA500" },
    "8124": { "id": 8124, "name": "Towards", "color": "FFD700" },
    "8125": { "id": 8125, "name": "Has specimen", "color": "FFFF00" },
    "8126": { "id": 8126, "name": "Finding context", "color": "5ed3ff" },
    "8127": { "id": 8127, "name": "Specimen source morphology", "color": "9ed563" },
    "8128": { "id": 8128, "name": "Using substance", "color": "5F9EA0" },
    "8129": { "id": 8129, "name": "Clinical course", "color": "FFEFD5" },
    "8130": { "id": 8130, "name": "Specimen source topography", "color": "FFE4B5" },
    "8131": { "id": 8131, "name": "Associated with", "color": "FFDAB9" },
    "8132": { "id": 8132, "name": "Causative agent", "color": "EEE8AA" },
    "8133": { "id": 8133, "name": "Due to", "color": "F0E68C" },
    "8134": { "id": 8134, "name": "Temporally related to", "color": "BDB76B" },
    "8135": { "id": 8135, "name": "Before", "color": "E6E6FA" },
    "8136": { "id": 8136, "name": "During", "color": "D8BFD8" },
    "8137": { "id": 8137, "name": "After", "color": "DDA0DD" },
    "8138": { "id": 8138, "name": "Inherent location", "color": "EE82EE" },
    "8139": { "id": 8139, "name": "Relative to part of", "color": "DA70D6" },
    "8140": { "id": 8140, "name": "Has dose form intended site", "color": "FF00FF" },
    "8141": { "id": 8141, "name": "Has disposition", "color": "FF00FF" },
    "8142": { "id": 8142, "name": "All or part of", "color": "BA55D3" },
    "8143": { "id": 8143, "name": "Proper part of", "color": "9370DB" },
    "8144": { "id": 8144, "name": "Regional part of", "color": "8A2BE2" },
    "8145": { "id": 8145, "name": "Lateral half of", "color": "9400D3" },
    "8146": { "id": 8146, "name": "Constitutional part of", "color": "9932CC" },
    "8147": { "id": 8147, "name": "Systemic part of", "color": "8B008B" },
    "8148": { "id": 8148, "name": "Contained in", "color": "800080" },
    "8149": { "id": 8149, "name": "Tributary of", "color": "4B0082" },
    "8150": { "id": 8150, "name": "Has basis of strength substance", "color": "6A5ACD" },
    "8151": { "id": 8151, "name": "Has presentation strength numerator unit", "color": "483D8B" },
    "8152": { "id": 8152, "name": "Has presentation strength denominator unit", "color": "00FFFF" },
    "8153": { "id": 8153, "name": "Has concentration strength denominator unit", "color": "00FFFF" },
    "8154": { "id": 8154, "name": "Has concentration strength numerator unit", "color": "E0FFFF" },
    "8155": { "id": 8155, "name": "Has basic dose form", "color": "AFEEEE" },
    "8156": { "id": 8156, "name": "Has state of matter", "color": "7FFFD4" },
    "8157": { "id": 8157, "name": "Observes", "color": "40E0D0" },
    "8158": { "id": 8158, "name": "Surgical approach", "color": "48D1CC" },
    "8159": { "id": 8159, "name": "Has dose form transformation", "color": "00CED1" },
    "8160": { "id": 8160, "name": "Has dose form administration method", "color": "5F9EA0" },
    "8161": { "id": 8161, "name": "Is modification of", "color": "4682B4" },
    "8162": { "id": 8162, "name": "Has ingredient", "color": "B0C4DE" },
    "8163": { "id": 8163, "name": "Has active ingredient", "color": "B0E0E6" },
    "8164": { "id": 8164, "name": "Has precise active ingredient", "color": "ADD8E6" },
    "8165": { "id": 8165, "name": "Has unit of presentation", "color": "87CEEB" },
    "8166": { "id": 8166, "name": "Plays role", "color": "87CEFA" },
    "8167": { "id": 8167, "name": "Has product name", "color": "00BFFF" },
    "8168": { "id": 8168, "name": "Has supplier", "color": "1E90FF" },
    "8169": { "id": 8169, "name": "Contains clinical drug", "color": "6495ED" },
    "8170": { "id": 8170, "name": "Has pack size unit", "color": "7B68EE" },
    "8171": { "id": 8171, "name": "Has device intended site", "color": "4169E1" },
    "8172": { "id": 8172, "name": "Has filling", "color": "0000FF" },
    "8173": { "id": 8173, "name": "Has compositional material", "color": "0000CD" },
    "8174": { "id": 8174, "name": "Has ingredient characteristic", "color": "00008B" },
    "8175": { "id": 8175, "name": "Has product characteristic", "color": "000080" },
    "8176": { "id": 8176, "name": "Unit of presentation size unit", "color": "191970" },
    "8177": { "id": 8177, "name": "Process extends to", "color": "FFF8DC" },
    "8178": { "id": 8178, "name": "Process acts on", "color": "FFEBCD" },
    "8179": { "id": 8179, "name": "Has coating material", "color": "FFE4C4" },
    "8180": { "id": 8180, "name": "Is sterile", "color": "FFDEAD" },
    "8181": { "id": 8181, "name": "Has ingredient qualitative strength", "color": "00b652" },
    "8182": { "id": 8182, "name": "Has target population", "color": "DEB887" },
    "8183": { "id": 8183, "name": "Has absorbability", "color": "D2B48C" },
    "8184": { "id": 8184, "name": "Has surface texture", "color": "BC8F8F" },
    "8185": { "id": 8185, "name": "Concept model data attribute", "color": "F4A460" },
    "8186": { "id": 8186, "name": "Has presentation strength numerator value", "color": "DAA520" },
    "8187": { "id": 8187, "name": "Has presentation strength denominator value", "color": "B8860B" },
    "8188": { "id": 8188, "name": "Has concentration strength denominator value", "color": "CD853F" },
    "8189": { "id": 8189, "name": "Has concentration strength numerator value", "color": "D2691E" },
    "8190": { "id": 8190, "name": "Count of base of active ingredient", "color": "8B4513" },
    "8191": { "id": 8191, "name": "Count of active ingredient", "color": "A0522D" },
    "8192": { "id": 8192, "name": "Count of base and modification pair", "color": "A52A2A" },
    "8193": { "id": 8193, "name": "Has pack size", "color": "800000" },
    "8194": { "id": 8194, "name": "Count of clinical drug type", "color": "A0522D" },
    "8195": { "id": 8195, "name": "Unit of presentation size quantity", "color": "F08080" },
    "8196": { "id": 8196, "name": "Concept history attribute", "color": "FA8072" },
    "8197": { "id": 8197, "name": "WAS A", "color": "E9967A" },
    "8198": { "id": 8198, "name": "SAME AS", "color": "FFA07A" },
    "8199": { "id": 8199, "name": "MAY BE A", "color": "DC143C" },
    "8200": { "id": 8200, "name": "ISA", "color": "FF0000" },
    "8201": { "id": 8201, "name": "MOVED TO", "color": "B22222" },
    "8202": { "id": 8202, "name": "REPLACED BY", "color": "8B0000" },
    "8203": { "id": 8203, "name": "MOVED FROM", "color": "FFC0CB" },
    "8204": { "id": 8204, "name": null, "color": "FFB6C1" },
    "8205": { "id": 8205, "name": "Medicinal product", "color": "FF69B4" },
    "8206": { "id": 8206, "name": "Clinical drug", "color": "FF1493" },
    "8207": { "id": 8207, "name": "Medicinal product form", "color": "C71585" },
    "8208": { "id": 8208, "name": "Disposition", "color": "DB7093" },
    "8209": { "id": 8209, "name": "Dose form", "color": "ADFF2F" },
    "8210": { "id": 8210, "name": "Role", "color": "7FFF00" },
    "8211": { "id": 8211, "name": "Administrative concept", "color": "7CFC00" },
    "8212": { "id": 8212, "name": "Context-dependent category", "color": "00FF00" },
    "8213": { "id": 8213, "name": "Basic dose form", "color": "32CD32" },
    "8214": { "id": 8214, "name": "Unit of presentation", "color": "98FB98" },
    "8215": { "id": 8215, "name": "Intended site", "color": "5db4cc" },
    "8216": { "id": 8216, "name": "Administration method", "color": "00FA9A" },
    "8217": { "id": 8217, "name": "Virtual clinical drug", "color": "00FF7F" },
    "8218": { "id": 8218, "name": "Release characteristic", "color": "3CB371" },
    "8219": { "id": 8219, "name": "Transformation", "color": "2E8B57" },
    "8220": { "id": 8220, "name": "Owl metadata concept", "color": "228B22" },
    "8221": { "id": 8221, "name": "State of matter", "color": "008000" },
    "8222": { "id": 8222, "name": "Linkage concept", "color": "006400" },
    "8223": { "id": 8223, "name": "Finding", "color": "9ACD32" },
    "8224": { "id": 8224, "name": "Disorder", "color": "6B8E23" },
    "8225": { "id": 8225, "name": "Procedure", "color": "808000" },
    "8226": { "id": 8226, "name": "Qualifier value", "color": "556B2F" },
    "8227": { "id": 8227, "name": "Organism", "color": "66CDAA" },
    "8228": { "id": 8228, "name": "Ethnic group", "color": "8FBC8F" },
    "8229": { "id": 8229, "name": "Observable entity", "color": "20B2AA" },
    "8230": { "id": 8230, "name": "Body structure", "color": "008B8B" },
    "8231": { "id": 8231, "name": "Occupation", "color": "008080" },
    "8232": { "id": 8232, "name": "Substance", "color": "FFA07A" },
    "8233": { "id": 8233, "name": "Physical force", "color": "FF7F50" },
    "8234": { "id": 8234, "name": "Morphologic abnormality", "color": "FF6347" },
    "8235": { "id": 8235, "name": "Product", "color": "FF4500" },
    "8236": { "id": 8236, "name": "Cell", "color": "FF8C00" },
    "8237": { "id": 8237, "name": "Physical object", "color": "FFA500" },
    "8238": { "id": 8238, "name": "Environment", "color": "FFD700" },
    "8239": { "id": 8239, "name": "Situation", "color": "FFFF00" },
    "8240": { "id": 8240, "name": "Event", "color": "5ed3ff" },
    "8241": { "id": 8241, "name": "Religion/philosophy", "color": "9ed563" },
    "8242": { "id": 8242, "name": "Cell structure", "color": "5F9EA0" },
    "8243": { "id": 8243, "name": "Attribute", "color": "FFEFD5" },
    "8244": { "id": 8244, "name": "Navigational concept", "color": "FFE4B5" },
    "8245": { "id": 8245, "name": "Regime/therapy", "color": "FFDAB9" },
    "8246": { "id": 8246, "name": "Social concept", "color": "EEE8AA" },
    "8247": { "id": 8247, "name": "Person", "color": "F0E68C" },
    "8248": { "id": 8248, "name": "Life style", "color": "BDB76B" },
    "8249": { "id": 8249, "name": "Staging scale", "color": "E6E6FA" },
    "8250": { "id": 8250, "name": "Tumor staging", "color": "D8BFD8" },
    "8251": { "id": 8251, "name": "Specimen", "color": "DDA0DD" },
    "8252": { "id": 8252, "name": "Record artifact", "color": "EE82EE" },
    "8253": { "id": 8253, "name": "Assessment scale", "color": "DA70D6" },
    "8254": { "id": 8254, "name": "SNOMED RT+CTV3", "color": "FF00FF" },
    "8255": { "id": 8255, "name": "Environment / location", "color": "FF00FF" },
    "8256": { "id": 8256, "name": "Metadata", "color": "BA55D3" },
    "8257": { "id": 8257, "name": "Pathological process", "color": "9370DB" },
    "8258": { "id": 8258, "name": "Onset", "color": "8A2BE2" },
    "8259": { "id": 8259, "name": "Course", "color": "9400D3" },
    "8260": { "id": 8260, "name": "Part of", "color": "9932CC" },
    "8261": { "id": 8261, "name": "Using", "color": "8B008B" },
    "8262": { "id": 8262, "name": "Approach", "color": "800080" },
    "8263": { "id": 8263, "name": "Access instrument", "color": "4B0082" },
    "8264": { "id": 8264, "name": "Has concentration strength numerator value", "color": "6A5ACD" },
    "8265": { "id": 8265, "name": "Count of base of active ingredient", "color": "483D8B" },
    "8266": { "id": 8266, "name": "Has concentration strength denominator value", "color": "00FFFF" },
    "8267": { "id": 8267, "name": "Property type", "color": "00FFFF" },
    "8268": { "id": 8268, "name": "Has device characteristic", "color": "E0FFFF" },
    "8269": { "id": 8269, "name": "Has presentation strength numerator value", "color": "AFEEEE" },
    "8270": { "id": 8270, "name": "Has presentation strength denominator value", "color": "7FFFD4" },
    "8271": { "id": 8271, "name": "Has surface characteristic", "color": "40E0D0" },
    "8272": { "id": 8272, "name": "Count of active ingredient", "color": "48D1CC" },
    "8273": { "id": 8273, "name": "biological function", "color": "00CED1" },
    "8274": { "id": 8274, "name": "curator museum/gallery", "color": "5F9EA0" },
    "8275": { "id": 8275, "name": "Supplier", "color": "4682B4" },
    "9000": { "id": 9000, "name": "Модератор", "color": "B0C4DE" },
    "9001": { "id": 9001, "name": "Администратор", "color": "B0E0E6" },
    "9002": { "id": 9002, "name": "Бот", "color": "ADD8E6" },
    "9100": { "id": 9100, "name": "Права доступа к функциям и сервисам приложений", "color": "87CEEB" },
    "9109": { "id": 9109, "name": "---", "color": "87CEFA" },
    "9110": { "id": 9110, "name": "Права доступа на LIB, TOOL, FIELD, LEVEL, BEL", "color": "00BFFF" },
    "9111": { "id": 9111, "name": "Права доступа на level (используется для метода Дельфи)", "color": "1E90FF" },
    "9112": { "id": 9112, "name": "Роль элемента", "color": "6495ED" },
    "9113": { "id": 9113, "name": "Ссылка на пренадлежащий элемент", "color": "7B68EE" },
    "9114": { "id": 9114, "name": "Ключ авторизации (связь пользователя с устройством)", "color": "4169E1" },
    "9115": { "id": 9115, "name": "Лицензионный ключ", "color": "0000FF" },
    "9116": { "id": 9116, "name": "Приложение/сервис", "color": "0000CD" },
    "9120": { "id": 9120, "name": "Функционал/сервис в приложении", "color": "00008B" },
    "9121": { "id": 9121, "name": "Связь ключ->роли", "color": "000080" },
    "9130": { "id": 9130, "name": "---", "color": "191970" },
    "10000": { "id": 10000, "name": "Тип серона не определен (все типы)", "color": "FFF8DC" }
};