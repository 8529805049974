import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
    Box,
} from '@mui/material';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import { Search } from "features/pages";
import { LifesAnamnezDetailsList } from "./LifesAnamnezDetailsList";

import {
    selectCurrentPatientLifesAnamnez,
} from "features/pages/pagesSlice";

const LifesAnamnezDetailsComponent = () => {
    const currentPatientLifesAnamnez = useSelector(selectCurrentPatientLifesAnamnez);

    console.log(currentPatientLifesAnamnez);
    return <>
        <Box sx={{ fontWeight: 600, mb: 3, ml: 2, mr: 2 }} component={"span"}>{currentPatientLifesAnamnez?.name}</Box>

        <LifesAnamnezDetailsList type="Localization" />
        <Box sx={{ mb: 4 }}>
            <LifesAnamnezDetailsList type="LifesAnamnezDetails" />
        </Box>

        <Box sx={{ mb: 4 }}>
            <Search
                item={currentPatientLifesAnamnez}
                type="LifesAnamnezDetails"
                inputColor="#aa904129"
                label={"Укажите характеристики"}
                itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
                resultItemProps={{
                    sx: { textTransform: "lowercase" }
                }}
            />
        </Box>

    </>;
}

export const LifesAnamnezDetails = memo(LifesAnamnezDetailsComponent);
