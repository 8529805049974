
import { APP_SERVICE } from "./constants";
import { POST } from "helpers";


export function fetchAuth(path, data) {
  console.log("fetchAuth", path, data);

  if (APP_SERVICE) {
    data.service = APP_SERVICE;
  }

  return POST({
    path,
    data
  });
}
