import { GRAPH_SETTINGS } from 'constants';
import { appStorage } from "helpers";

const settings = appStorage.getItem("settings") || {};
const type = settings.graph && settings.graph.edgesType ? settings.graph.edgesType : "line"; //  line cubic quadratic polyline arc cubic-vertical cubic-horizontal circle-running

export default function MidPoint(G6) {
  G6.registerEdge(
    'mid-point-edge',
    {
      afterDraw(cfg, group) {

        if (!group) {
          return;
        }

        const shape = group.get('children')[0];
        const midPoint = shape.getPoint(0.5);
        const data = cfg?.data || {};
        const levelColor = data.levelColor || GRAPH_SETTINGS.EMPTY_COLOR;
        const color = data.color || 'gray';
        const toolColor = data.toolColor;

        if (toolColor) {
          shape.attr('stroke', toolColor);
        }

        function getIconChar(emblem) {
          if (emblem && emblem.substring && emblem.substring(0, 2) === "&#") {
            return emblem.replace(/&#(\d+);/g, function (_match, dec) {
              return String.fromCharCode(dec);
            });
          }

          return String.fromCharCode(emblem);
        }

        function getBGColor(text) {
          if (text === "" || text === null) {
            return "#FFF";
          }

          if (text < 0) {
            return "#b94a48";
          }
          // console.log(text);
          return "#57C562";
        }

        function getTooltip(valueObject) {
          let tooltip = "";
          if (valueObject.name) {
            tooltip = `${valueObject.name}:`;
          }

          if (valueObject.valueInfo && valueObject.valueInfo.name) {
            tooltip += ` ${valueObject.valueInfo.name}`;
          } else if (valueObject.realValue !== null) {
            tooltip += ` ${valueObject.realValue}`;
          }

          return tooltip;
        }

        /**
         * @todo перенести все расчёты, кроме координат в graph.ts
         */
        if (midPoint) {
          let currentPoint = 8.5;

          data.values.map(valueObject => {
            currentPoint = (currentPoint - 0.6);
            let point = shape.getPoint(currentPoint / 10);
            let textProps = {};

            if (valueObject.valueInfo && valueObject.valueInfo.view) {
              if (valueObject.valueInfo.font) {
                textProps = {
                  ...textProps,
                  text: getIconChar(valueObject.valueInfo.view),
                  fontFamily: valueObject.valueInfo.font,
                  fontSize: 13,
                  x: point.x - 7,
                  y: point.y + 7,
                };
              } else {
                if (valueObject.valueInfo.view.substring(0, 2) === "&#") {
                  textProps = {
                    ...textProps,
                    text: getIconChar(valueObject.valueInfo.view),
                    // fontFamily: "sans-serif",
                    fontSize: 17,
                    x: point.x - 5,
                    y: point.y + 10,
                  };
                } else {
                  if (!valueObject.valueInfo.view.match(/^[A-Za-z0-9]+$/)) {
                    textProps = {
                      ...textProps,
                      text: valueObject.valueInfo.view,
                      fontSize: 15,
                      x: point.x - 5,
                      y: point.y + 8,
                    };

                    // textProps.x = point.x - 4;
                    // textProps.y = point.y + 7;
                  } else {
                    textProps = {
                      ...textProps,
                      text: valueObject.valueInfo.view,
                      fontSize: 15,
                      x: point.x - 4,
                      y: point.y + 9,
                    };
                  }
                }
              }
            } else {
              const realValueText = valueObject.realValue === null ? "" : "" + valueObject.realValue;
              textProps = {
                ...textProps,
                text: realValueText.length > 3 ? realValueText.substring(0, 3) : valueObject.realValue,
                fontSize: 10,
                fill: "#222",
                x: realValueText.length === 1 ? point.x - 3 : point.x - 6,
                y: point.y + 6,
              };
            }

            let valueGroup = group.addGroup({
              id: 'valueGroup',
              // draggable: true,
              // other configurations
            });

            valueGroup.addShape('circle', {
              attrs: {
                r: 9,
                fill: getBGColor(valueObject.realValue),
                stroke: GRAPH_SETTINGS.NODE_STROKE_COLOR,
                lineWidth: 1,
                x: point.x,
                y: point.y,
              },
              data: {
                tooltip: getTooltip(valueObject)
              },
              zIndex: 20,
              // draggable: false,
              name: 'mid-point-edge-value-circle',
            });

            if (textProps.text !== "" && textProps.text !== null) {
              valueGroup.addShape('text', {
                attrs: {
                  fill: "#FFF",
                  text: "",
                  fontFamily: "Fira Sans",
                  ...textProps
                },

                // capture: false,
                // draggable: false,
                zIndex: 30,
                name: 'mid-point-edge-value-text',
              });
            }
          });

          // Внешний круг концепта (цвет типа)

          if (levelColor) {
            group.addShape('circle', {
              attrs: {
                r: GRAPH_SETTINGS.NODE_RADIUS_SMALL,
                fill: levelColor,
                stroke: '#FFF',
                lineWidth: 2,
                x: midPoint.x,
                y: midPoint.y,
              },
              zIndex: 20,
              // draggable: true,
              name: 'master-concept-node-level-circle',
            });

            // Внутренний круг концепта (цвет таблицы)
            group.addShape('circle', {
              attrs: {
                r: Math.floor(GRAPH_SETTINGS.NODE_RADIUS_SMALL - 5),
                fill: color,
                stroke: GRAPH_SETTINGS.NODE_STROKE_COLOR,
                lineWidth: 1,
                x: midPoint.x,
                y: midPoint.y,
              },
              zIndex: 25,
              // draggable: true,
              name: 'mid-point-edge-circle',
            });
          } else {
            group.addShape('circle', {
              attrs: {
                r: GRAPH_SETTINGS.NODE_RADIUS_SMALL,
                fill: color,
                stroke: '#FFF',
                lineWidth: 2,
                x: midPoint.x,
                y: midPoint.y,
              },
              zIndex: 20,
              // draggable: true,
              name: 'mid-point-edge-circle',
            });
          }


          
          // get the first shape in the group, it is the edge's path here=
          const shape = group.get('children')[0];
          // the start position of the edge's path
          const startPoint = shape.getPoint(0);

          // add red circle shape
          const circle = group.addShape('circle', {
            attrs: {
              x: startPoint.x,
              y: startPoint.y,
              fill: '#40a9ff',
              r: 3,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'circle-shape',
          });

          // animation for the red circle
          circle.animate(
            (ratio) => {
              // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
              // get the position on the edge according to the ratio
              const tmpPoint = shape.getPoint(ratio);
              // returns the modified configurations here, x and y here
              return {
                x: tmpPoint.x,
                y: tmpPoint.y,
              };
            },
            {
              repeat: true, // Whether executes the animation repeatly
              duration: 2800, // the duration for executing once
            },
          );

        }


      },
      update: undefined,
    },
    type,
  );
}