import React, { memo } from "react";

import {
    Paper,
} from '@mui/material';

const PaperBlockComponent = ({
    children,
}) => <Paper
    elevation={0}
    sx={{
        mb: 7,
        backgroundColor: "#ceedff4d",
        border: "1px solid gray",
        pb: 3,
        pt: 4,
        mt: 2,
        mr: 2,
        // borderRadius: 4
    }}
>{children}</Paper>;

export const PaperBlock = memo(PaperBlockComponent);
