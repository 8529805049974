import registerGhostNode from './GhostNode';
import registerPieNode from './PieNode';
import registerToolNode from './ToolNode';
import registerConceptNode from './ConceptNode';
import registerMasterConceptNode from './MasterConceptNode';
import registerMasterConceptNodeAnimated from './MasterConceptNodeAnimated';

export default function registerNodes(G6) {
    registerGhostNode(G6);
    registerPieNode(G6);
    registerToolNode(G6);
    registerConceptNode(G6);
    registerMasterConceptNode(G6);
    registerMasterConceptNodeAnimated(G6);
}
