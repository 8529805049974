import { SERVER } from './server';

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9061",
        auth: "https://service.socmedica.com:9061",
    },
    dev: {
        api: "https://service.socmedica.dev:9061",
        auth: "https://service.socmedica.dev:9061",
    },
    box: {
        api: "http://ecp.box:30946",
        auth: "http://ecp.box:44380",
    },
};

const getUrls = () => {
    const { api: APIServer, auth: authServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        auth: {
            login: authServer + "/api/authentication/Authorization",
            registration: authServer + "/api/authentication/Send",
            confirm: authServer + "/api/authentication/ConfirmEmail",
            getConfirm: authServer + "/api/authentication/GetConfirmEmail",

            recovery: authServer + "/api/authentication/Recovery",
            resetPass: authServer + "/api/authentication/ResetPassword",

            checkAuth: APIServer + "/api/Authentication/AuthCheck",
        },
    }
}

export const API_URLS = getUrls();
