import React from 'react';

import { AUTH_PATHS } from "../../constants";

import { NavLink } from "react-router-dom";
import { Stack, Divider } from '@mui/material';

import styles from './Navigation.module.css';

export function Navigation({ tab }) {

  const isRegistration = tab === 1;

  let paths = [];
  if (isRegistration) {
    paths = AUTH_PATHS.filter(item => item.type === "registration");
  } else {
    paths = AUTH_PATHS.filter(item => item.type === "login");
  }

  return (
    <Stack direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
      className={styles.links}
    >
      {paths.map(item => <NavLink
        key={item.path}
        className={({ isActive }) => isActive ? styles.linkActive : undefined}
        to={item.path}
      >{item.title}</NavLink>
      )}
    </Stack>
  );
}