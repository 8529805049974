import { GRAPH_SETTINGS } from 'constants';

export default function (G6) {
    G6.registerNode(
        'ghost-node',
        {
            generateWedgeString(startX, startY, startAngle, endAngle, radius) {
                let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
                let x1 = startX + radius * Math.cos(Math.PI * startAngle / 180);
                let y1 = startY + radius * Math.sin(Math.PI * startAngle / 180);
                let x2 = startX + radius * Math.cos(Math.PI * endAngle / 180);
                let y2 = startY + radius * Math.sin(Math.PI * endAngle / 180);
                let pathString = "M" + startX + " " + startY + " L" + x1 + " " + y1 + " A" + radius + " " + radius + " 0 " + largeArcFlag + " 1 " + x2 + " " + y2 + " z";

                return pathString;
            },

            draw(cfg, group) {
                if (!group) {
                    return;
                }

                let data = cfg?.data || {};
                let levelColor = data.levelColor || GRAPH_SETTINGS.EMPTY_COLOR;
                let color = data.color || 'red';
                let keyShape;

                keyShape = group.addShape('circle', {
                    attrs: {
                        r: 20,
                        stroke: GRAPH_SETTINGS.EDGE_COLOR,
                        lineWidth: 2,
                        fill: "#FFF",
                        zIndex: 0,
                    },
                });

                // Группа для инструментов
                const toolsGroup = group.addGroup({
                    id: 'toolsGroup',
                    zIndex: 1,
                    visible: false,
                    name: 'tools-group',
                });

                const tools = data.tools || [];
                const radius = GRAPH_SETTINGS.NODE_RADIUS_SMALL;
                const deg = 360 / tools.length;

                // Перемещаем деление концепта в конец, чтобы его рамка не перекрывалась другими инструментами
                let binaryToolIndex = tools.findIndex(tool => tool.id === -1);

                if (binaryToolIndex !== -1) {
                    const sizeof = tools.length;
                    // tools.splice(0, 0, tools.splice(binaryToolIndex, 1)[0]);
                    [tools[binaryToolIndex], tools[sizeof - 1]] = [tools[sizeof - 1], tools[binaryToolIndex]];
                }

                tools.forEach((tool, i) => {
                    // Уменьшаем радиус, если в инструменте нет связей
                    const currentDeg = 180 + (deg * i) - (deg / 2);
                    let toolRadius = tool.count ? Math.floor(radius * 1.8) : Math.floor(radius * 1.5);

                    // Для деления концепта собственные стили
                    let toolStyleParams = {};
                    if (tool.id === -1) {
                        toolStyleParams = {
                            stroke: "#0082dd",
                            fill: "#FFF",
                            lineWidth: 2,
                        };
                    }

                    // Инструменты
                    toolsGroup.addShape('path', {
                        attrs: {
                            path: this.generateWedgeString(0, 0, currentDeg, currentDeg + deg, toolRadius),
                            // stroke: randomFromArray(colors),
                            stroke: '#FFF',
                            lineWidth: 2,
                            // fill: randomFromArray(colors),
                            fill: tool.color,
                            ...toolStyleParams,
                        },
                        name: 'inst-path',
                        zIndex: 10,
                        anchorPointIdx: i
                    });
                });




                if (levelColor) {
                    group.addShape('circle', {
                        attrs: {
                            r: GRAPH_SETTINGS.NODE_RADIUS_SMALL,
                            fill: levelColor,
                            stroke: '#FFF',
                            lineWidth: 2,
                        },
                        zIndex: 20,
                        name: 'master-concept-node-level-circle',
                    });

                    // Внутренний круг концепта (цвет таблицы)
                    group.addShape('circle', {
                        attrs: {
                            r: Math.floor(GRAPH_SETTINGS.NODE_RADIUS_SMALL - 5),
                            fill: color,
                            stroke: GRAPH_SETTINGS.NODE_STROKE_COLOR,
                            lineWidth: 1,
                        },
                        zIndex: 25,
                        name: 'master-concept-node-circle',
                    });
                } else {
                    group.addShape('circle', {
                        attrs: {
                            r: GRAPH_SETTINGS.NODE_RADIUS_SMALL,
                            fill: color,
                            stroke: '#FFF',
                            lineWidth: 2,
                        },
                        zIndex: 20,
                        name: 'master-concept-node-circle',
                    });
                }

                toolsGroup.hide();

                return keyShape;
            },

            setState(name, value, item) {
                console.log(name, value, item);

                if (name === 'showTools') {
                    const toolsGroup = item.getContainer().findById("toolsGroup");

                    if (toolsGroup) {
                        if (value === true) {
                            console.log("SHOW", toolsGroup)
                            toolsGroup.show();
                        } else {
                            console.log("HIDE", toolsGroup)
                            toolsGroup.hide();
                        }
                    }
                }

            },
            update: null,
        },
        'single-node',
    );
}
