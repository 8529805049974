import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import G6 from '@antv/g6';

import { setGraph } from "helpers";

import { convertUMKBDataToGraph } from "./utils";

import createGraph from './createGraph';
import registerShape from './shape';
import registerBehavior from './behavior';

import { GraphOptions } from "./GraphOptions";

import styles from './styles';

import { selectGraphData } from "../graphSlice";

let graph;

const GraphBlock = ({ classes }) => {
	registerShape(G6);
	registerBehavior(G6);

	const ref = React.useRef(null);
	const result = useSelector(selectGraphData);

	let data = {
		nodes: [],
		edges: [],
		combos: [],
	};

	let touchTimer;
	let touchNodeEvent;
	let touchCoordinates = { x: 0, y: 0 };

	function setCanvasSize(graph, c) {
		if (graph && !graph.get('destroyed')) {
			const container = ref.current || null;

			if (container && container.clientWidth && container.clientHeight) {
				console.log("setCanvasSize", c, container.clientWidth, container.clientHeight);
				graph.changeSize(container.clientWidth - 4, container.clientHeight - 4);
			}
		}
	}

	const menuHide = () => {
		const menuPlugin = graph.cfg.plugins.find(item => !!item.onMenuHide);
		if (menuPlugin) {
			menuPlugin.onMenuHide();
		}
	}

	useEffect(() => {
		graph = createGraph(ref, data);

		console.log(result);
		setGraph(graph);

		setTimeout(() => {
			setCanvasSize(graph, 1);
		}, 400);

		window.onresize = () => setCanvasSize(graph, 5);

		const nodeDragStart = event => {
			console.log("nodeDragStart");
			const item = event.item;
			const edges = item.getEdges();

			// to Front
			item.toFront();

			edges.forEach(edge => {
				edge.toFront();
			});
			// <==
		};

		const holdEdgesInCenter = () => {
			// Цепляем рёберные ноды к центру ребра
			// @todo оптимизировать, запускать этот алгоритм только если на рёбрах этой ноды есть ноды

			const ghostNodes = graph.findAll('node', (node) => {
				return node.get('model').type === "ghost-node";
			});

			ghostNodes.forEach(ghostNode => {
				const nodeId = ghostNode.get('id');
				const edge = graph.findById(`${nodeId}_ghost`);

				if (edge) {
					const group = edge.getContainer();
					const shape = group.get('children')[0];
					const midPoint = shape.getPoint(0.5);

					graph.updateItem(ghostNode, {
						x: midPoint.x,
						y: midPoint.y,
					});
				}
			});
		}

		const nodeDrag = event => {
			console.log("nodeDrag");
			// Фиксируем позицию перетаскиваемой ноды, чтобы при перерисовке после добавления нода не уехала
			// @todo проверить можно ли это повесить на dragstart, и при этом проверить поведение при перетаскивание за экран
			function refreshDragedNodePosition(event) {
				const model = event.item.get('model');
				model.fx = event.x;
				model.fy = event.y;
				model.x = event.x;
				model.y = event.y;
			}

			refreshDragedNodePosition(event);
			holdEdgesInCenter();
		};

		const nodeTouchMove = event => {
			holdEdgesInCenter();
			const accuracy = 5;
			const isMove = Math.abs(touchCoordinates.x - event.x) > accuracy || Math.abs(touchCoordinates.y - event.y) > accuracy;

			if (touchTimer && isMove) {
				clearTimeout(touchTimer);
			}
		};

		const nodeTouchStart = event => {
			console.log("nodeTouchStart");
			touchCoordinates = { x: event.x, y: event.y };

			touchNodeEvent = event;

			touchTimer = setTimeout(() => {
				graph.emit('contextmenu', event);
			}, 1000);
		};

		const nodeDragStop = _ => {
			if (touchTimer) {
				clearTimeout(touchTimer);
			}
			if (touchNodeEvent) {
				// nodeClick(touchNodeEvent);
			}
		};

		graph.on('node:dragstart', nodeDragStart);
		graph.on('node:drag', nodeDrag);

		graph.on('node:touchstart', nodeTouchStart);
		graph.on('touchmove', nodeTouchMove);

		graph.on('touchend', nodeDragStop);
		graph.on('touchstart', menuHide);

		graph.on('wheelzoom', () => {
			const zoom = graph.getZoom();
			const lableIsHidden = graph.get("lableIsHidden");

			menuHide();

			if (zoom < 0.35) {
				if (!lableIsHidden) {
					graph.set("lableIsHidden", true);
					const nodes = graph.getNodes();
					nodes.forEach(node => {
						node.setState("noLabel", true);
					});
				}
			} else {
				if (lableIsHidden) {
					graph.set("lableIsHidden", false);
					const nodes = graph.getNodes();
					nodes.forEach(node => {
						node.setState("noLabel", false);
					});
				}
			}
		});

		// graph.layout();
	}, []);


	useEffect(() => {
		if (graph) {

			const convertedGraphData = convertUMKBDataToGraph({
				graph,
				result,
			});
			
			console.log("getGraphTies: convertedGraphData", convertedGraphData);

			// Существующие ноды фиксируем на своих местах
			// @todo оптимизировать, можно взять только ноды с пустыми fx и fy
			const existingNodes = graph.getNodes();

			existingNodes.forEach(node => {
				let bbox = node.getBBox();
				let model = node.get('model');

				model.fx = bbox.centerX;
				model.fy = bbox.centerY;
			});


			const lableIsHidden = graph.get("lableIsHidden");

			// Добавляем ноды в граф
			convertedGraphData.nodes.forEach(node => {
				let item = graph.addItem("node", node);

				// Устанавливаем нодам стейт noLabel 
				if (lableIsHidden && item) {
					item.setState('noLabel', true);
				}
			});

			// Добавляем рёбра в граф
			convertedGraphData.edges.forEach(edge => {
				graph.addItem("edge", edge);
			});


			if (convertedGraphData.nodes.length > 1000 || convertedGraphData.edges.length > 1000) {
				graph.updateLayout({ "gpuEnabled": true });
			} else {
				graph.updateLayout({ "gpuEnabled": false });
			}

			// queue
			// graph.layout();

		}
	}, [result]);

	return <>
		{/* <GraphSettings /> */}
		{/* <>{graph && <GraphOptions graph={graph}/>}</> */}
		<div ref={ref} id="container" className={classes.container}></div>
	</>;
}

export default withStyles(styles)(GraphBlock);
