import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
} from '@mui/material';

import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

import { Dialog } from "UI";

import { Search } from "features/pages";
import { LifesAnamnezList } from "./LifesAnamnezList";
import { LifesAnamnezDetails } from "../LifesAnamnezDetails/LifesAnamnezDetails";

import {
    selectCurrentPatientLifesAnamnez,
    resetCurrentPatientLifesAnamnez,
} from "features/pages/pagesSlice";

const LifesAnamnezComponent = () => {
    const dispatch = useDispatch();

    const currentPatientLifesAnamnez = useSelector(selectCurrentPatientLifesAnamnez);

    const handleClosePatientLifesAnamnez = () => {
        dispatch(resetCurrentPatientLifesAnamnez());
    }

    console.log(currentPatientLifesAnamnez);

    return <>
        <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
            <Search
                variant="parse"
                type="LifesAnamnez"
                label={"Анамнез жизни"}
                itemIcon={<OfflineBoltIcon sx={{ color: 'text.secondary' }} />}
                inputColor="#3977c629"
                sx={{
                    backgroundColor: "#fcfdfd",
                }}
                resultItemProps={{
                    sx: {
                        display: "inline-block",
                        "&:first-letter": {
                            textTransform: "uppercase",
                        }
                    }
                }}

            />

            <LifesAnamnezList />

        </Box>

        <Dialog
            open={!!currentPatientLifesAnamnez}
            onClose={handleClosePatientLifesAnamnez}
        >
            <LifesAnamnezDetails />
        </Dialog>
    </>;
}

export const LifesAnamnez = memo(LifesAnamnezComponent);
