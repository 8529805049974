import React from "react";
import { CircularProgress, Box, Backdrop } from "@mui/material";

export const Loader = (props) => {
    let { loading, children, variant = "cover", sx = {}, replacer = null } = props;

    if (loading) {

        switch (variant) {
            case "backdrop":
                return <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: 100000,
                        ...sx,
                    }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>;

            case "cover":
                return <Box sx={{
                    position: "relative",
                }}>
                    <Box sx={{
                        position: "absolute",
                        display: 'flex',
                        height: "100%",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.4)",
                        zIndex: 10,
                        ...sx
                    }}>
                        <CircularProgress color="primary" size={30} />
                    </Box >{children}</Box>;

            case "replace":
                return <Box sx={{
                    position: "relative",
                }}>
                    <Box sx={{
                        position: "absolute",
                        display: 'flex',
                        height: "100%",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,1)",
                        zIndex: 10,
                        ...sx
                    }}>
                        {replacer || <CircularProgress color="primary" size={30} />}
                    </Box >{children}</Box>;

            default:
                return children;
        }
    }

    return children;
}
