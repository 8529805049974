import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    selectCurrentSymptom,
    removeClarificationById,
} from "features/pages/pagesSlice";

import { ClarificationItem } from "features/pages"; // ServiceInfo

const colors = {
    "Localization": "#f5b726",
    "Details": "#e172e0",
};

const getColorByType = (type) => {
    return colors[type] || "lightgray";
}

const ClarificationsListComponent = ({ type }) => {
    const dispatch = useDispatch();

    let clarificationsList = [];
    const currentSymptom = useSelector(selectCurrentSymptom);

    if (currentSymptom?.clarifications?.length) {
        clarificationsList = currentSymptom.clarifications;
        clarificationsList = clarificationsList.filter(item => item.type === type);
    }

    const handleClickRemove = (clarificationId) => {
        dispatch(removeClarificationById({ item: currentSymptom, clarificationId }))
    }

    const color = getColorByType(type);

    return <>
        {clarificationsList.map((item) =>
            <ClarificationItem
                key={item.id}
                sx={{ backgroundColor: color }}
                label={item.name}
                onDelete={() => handleClickRemove(item.id)}
            />
        )}
    </>;
}

export const ClarificationsList = memo(ClarificationsListComponent);
