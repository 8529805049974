import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Stack,
    TextField,
} from '@mui/material';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import {
    Search,
    ClarificationsList,
    // Parameters,
} from "features/pages";

import { Loader } from "UI";
import {
    addToUnitsList,
    selectUnitsList,
    selectCurrentSymptom,
    selectLoading,
    selectClarificationData,
} from "features/pages/pagesSlice";

const ClarificationsComponent = () => {
    const dispatch = useDispatch();

    const { clarifications: loading } = useSelector(selectLoading);
    const currentSymptom = useSelector(selectCurrentSymptom);
    const clarificationData = useSelector(selectClarificationData);
    const unitsList = useSelector(selectUnitsList);

    const currentUnit = currentSymptom?.id ? unitsList[currentSymptom.id] || {} : {};
    const { value: currentUnitValue = "", unit: currentUnitObject = {} } = currentUnit;

    console.log(currentUnitValue);
    console.log(currentUnitObject);

    const [unitValue, setUnitValue] = useState(currentUnitValue || "");




    console.log(currentSymptom);




    const handleChangeUnit = (event) => {
        console.log(event);
        console.log(event.target.value);

        const newValue = event.target.value;

        dispatch(addToUnitsList({
            item: currentSymptom,
            value: newValue,
            type: "Unit",
        }));

        setUnitValue(newValue);
    }

    // useEffect(()=>{
    //     setUnitValue();
    // }, [])

    return <>
        <Box sx={{
            fontWeight: 600,
            mb: 3,
            ml: 2,
            mr: 2,
            display: "inline-block",
            "&:first-letter": {
                textTransform: "uppercase",
            }
        }}
            component={"span"}
        >{currentSymptom?.name}</Box>
        <Loader loading={loading}>
            <>
                {clarificationData.hasLocalization && <ClarificationsList type="Localization" />}
                {clarificationData.hasСharacteristics && <Box sx={{ mb: 4 }}>
                    <ClarificationsList type="Details" />
                </Box>}

                {clarificationData.hasLocalization && <Box sx={{ mb: 4 }}>
                    <Search
                        item={currentSymptom}
                        type="Localization"
                        inputColor="#aa904129"
                        label={"Укажите локализацию симптома"}
                        itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
                        resultItemProps={{
                            sx: { textTransform: "lowercase" }
                        }}
                    />
                </Box>}
                {clarificationData.hasСharacteristics && <Box sx={{ mb: 4 }}>
                    <Search
                        item={currentSymptom}
                        showLevel={true}
                        type="Details"
                        inputColor="#ff000029"
                        label={"Укажите характер симптома"}
                        itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
                        resultItemProps={{
                            sx: { textTransform: "lowercase" }
                        }}
                    />
                </Box>}
                {clarificationData.hasParameters && <Box sx={{ mb: 4 }}>
                    <Stack
                        spacing={2}
                        direction="row"
                    >
                        <TextField
                            id="outlined-basic"
                            label="Числовое значение"
                            variant="outlined"
                            value={unitValue}
                            onChange={handleChangeUnit}
                        />

                        <Search
                            item={currentSymptom}
                            showLevel={true}
                            minSearchText={0}
                            type="Unit"
                            currentValue={currentUnitObject}
                            inputColor="#ff000029"
                            label={"Укажите единицу измерения"}
                            itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
                            resultItemProps={{
                                sx: { textTransform: "lowercase" }
                            }}
                        />
                    </Stack>


                </Box>}
            </>
        </Loader>
    </>;
}

export const Clarifications = memo(ClarificationsComponent);
