import React, { memo } from "react";
import moment from "moment";
import 'moment/locale/ru';

import { TextField } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';

// import styles from '../../Patient.module.css';

moment.locale('ru')

// localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
const DateTimeComponent = ({ title, value, onChange, name }) => {
    value = +value || 0;

    // const [value, setValue] = React.useState(moment('2014-08-18T21:11:54'));
    const handleChange = (newValue) => {
        // setValue(newValue);
        onChange && onChange({ name, value: moment(newValue).valueOf() });
    };

    return <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="ru"
        localeText={{
            todayButtonLabel: "Сегодня",
            nextMonth: "Следующий месяц",
            previousMonth: "Предыдущий месяц",
        }}
    >
        <DesktopDatePicker
            sx={{
                width: "100%",
                backgroundColor: "#fcfdfd"
                // mt: "-20px !important",
            }}
            views={['year', 'month', 'day']}
            label={title || ""}
            value={value ? moment(value) : null}
            maxDate={moment()}
            onChange={handleChange}
            // renderInput={(params) => <TextField {...params} fullWidth={true} />}
            
            slotProps={{
                textField: { variant: 'outlined' } ,
                actionBar: {
                    actions: ['today'],
                },
            }}
        />
    </LocalizationProvider>;
}
export const DateTime = memo(DateTimeComponent);
