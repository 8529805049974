import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
    Button,
    Typography,
    FormControl,
    Slider,
} from '@mui/material';

import {
    resetSettings,
    selectSettings,
    selectSymptomsList,
    setSetting,
    sendGetDiagnostic,
    selectLoading,
} from "features/pages/pagesSlice";

import { setCurrentPage } from "features/main/mainSlice";

import { CheckboxBlock, InputValueBlock, SliderBlock, PaperBlock } from "./components";


const percentValue = value => `${Math.round(value * 100)}%`;

const SettingsComponent = () => {
    const dispatch = useDispatch();

    const settings = useSelector(selectSettings);
    const symptomsList = useSelector(selectSymptomsList);
    
    const { diagnostic: loading } = useSelector(selectLoading);
    
    const diagnosticDisabled = !symptomsList.length || loading;

    const handleChange = (event) => {
        let value = event.target.checked !== undefined ? event.target.checked : event.target.value;

        dispatch(setSetting({ name: event.target.name, value }));
    }

    const handleChange1 = (_, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        const minDistance = 0.01;
        let value;
        let name;

        if (activeThumb === 0) {
            value = Math.min(newValue[0], settings.upperDecisionProbabilityThreshold - minDistance);
            name = "lowerDecisionProbabilityThreshold";
        } else {
            value = Math.max(newValue[1], settings.lowerDecisionProbabilityThreshold + minDistance);
            name = "upperDecisionProbabilityThreshold";
        }
        dispatch(setSetting({ name, value }));
    };

    const handleClickDiagnostic = () => {
        dispatch(sendGetDiagnostic({
            success: () => dispatch(setCurrentPage("result"))
        }));
    }

    const handleClickReset = () => {
        dispatch(resetSettings());
    }

    useEffect(() => {
        const considerParentalRelationsNewValue = !(settings.parentalRelationsDepth < 1);

        if (settings.considerParentalRelations !== considerParentalRelationsNewValue) {
            dispatch(setSetting({ name: "considerParentalRelations", value: considerParentalRelationsNewValue }));
        }

    }, [dispatch, settings.considerParentalRelations, settings.parentalRelationsDepth]);

    useEffect(() => {
        const considerChildishRelationsNewValue = !(settings.childishRelationsDepth < 1);

        if (settings.considerChildishRelations !== considerChildishRelationsNewValue) {
            dispatch(setSetting({ name: "considerChildishRelations", value: considerChildishRelationsNewValue }));
        }

    }, [dispatch, settings.considerChildishRelations, settings.childishRelationsDepth]);

    useEffect(() => {
        const considerPrevalenceNewValue = !(settings.defaultPrevalence < 0);

        if (settings.considerPrevalence !== considerPrevalenceNewValue) {
            dispatch(setSetting({ name: "considerPrevalence", value: considerPrevalenceNewValue }));
        }

    }, [dispatch, settings.considerPrevalence, settings.defaultPrevalence]);


    return <Box sx={{ m: 3 }}>
        <SliderBlock
            label="Глубина поиска"
            name="causialDepth"
            value={settings.causialDepth}
            step={1}
            min={1}
            max={10}
            onChange={handleChange}
        />

        <FormControl fullWidth sx={{ mt: 3, width: "94%" }} >
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Typography>Границы срабатывания поиска</Typography>
                <InputValueBlock value={percentValue(settings.lowerDecisionProbabilityThreshold)} />
                <InputValueBlock value={percentValue(settings.upperDecisionProbabilityThreshold)} />
            </Box>

            <Slider
                value={[settings.upperDecisionProbabilityThreshold, settings.lowerDecisionProbabilityThreshold]}
                onChange={handleChange1}
                valueLabelFormat={percentValue}
                valueLabelDisplay="auto"
                disableSwap
                step={0.01}
                min={0}
                max={1}
                size="small"
            />
        </FormControl>

        <SliderBlock
            label="Учёт бинаризации (вычитания по глубине)"
            name="binarizationProbability"
            value={settings.binarizationProbability}
            valueLabelFormat={value => value === -0.01 ? "выкл" : `${Math.round(value * 100)}%`}
            step={0.01}
            min={0}
            max={1}
            onChange={handleChange}
            turnKey="considerBinarization"
            canTurnOff={true}
        />

        {/* ========== */}

        <PaperBlock>
            <SliderBlock
                label="Глубина предков"
                name="parentalRelationsDepth"
                value={settings.parentalRelationsDepth}
                valueLabelFormat={value => value === 0 ? "выкл" : value}
                step={1}
                min={1}
                max={10}
                onChange={handleChange}
                turnKey="considerParentalRelations"
                canTurnOff={true}
            />

            <SliderBlock
                label="Вычитания"
                name="parentalRelationsProbability"
                value={settings.parentalRelationsProbability}
                valueLabelFormat={percentValue}
                step={0.01}
                min={0}
                max={1}
                disabled={!settings.considerParentalRelations}
                onChange={handleChange}
            />
        </PaperBlock>

        {/* ========== */}

        <PaperBlock>
            <SliderBlock
                label="Глубина потомка"
                name="childishRelationsDepth"
                value={settings.childishRelationsDepth}
                valueLabelFormat={value => value === 0 ? "выкл" : value}
                step={1}
                min={1}
                max={10}
                onChange={handleChange}
                turnKey="considerChildishRelations"
                canTurnOff={true}
            />
            <SliderBlock
                label="Вычитания"
                name="childishRelationsProbability"
                value={settings.childishRelationsProbability}
                valueLabelFormat={percentValue}
                step={0.01}
                min={0}
                max={1}
                disabled={!settings.considerChildishRelations}
                onChange={handleChange}
            />
        </PaperBlock>

        {/* ========== */}

        <PaperBlock>
            <SliderBlock
                label="Распространенности"
                name="defaultPrevalence"
                value={settings.defaultPrevalence}
                valueLabelFormat={value => value === -0.01 ? "выкл" : `${Math.round(value * 100)}%`}
                step={0.01}
                min={0}
                max={1}
                onChange={handleChange}
                turnKey="considerPrevalence"
                canTurnOff={true}
            />

            <SliderBlock
                label="Множитель распространенности"
                name="prevalenceMultiplier"
                value={settings.prevalenceMultiplier}
                valueLabelFormat={percentValue}
                step={0.01}
                min={0}
                max={1}
                disabled={!settings.considerPrevalence}
                onChange={handleChange}
            />
        </PaperBlock>

        {/* ========== */}

        <PaperBlock>
            <Typography variant="h6">Учёт деталей симптома</Typography>

            <CheckboxBlock
                checked={settings.considerSymptomNature}
                name="considerSymptomNature"
                onChange={handleChange}
                label="Характер симптома"
            />
            <CheckboxBlock
                checked={settings.considerPersonalData}
                name="considerPersonalData"
                onChange={handleChange}
                label="Персонализация отношений"
            />
            <CheckboxBlock
                checked={settings.considerSymptomConditions}
                name="considerSymptomConditions"
                onChange={handleChange}
                label="Условия при которых симптом проявляется"
            />
            <CheckboxBlock
                checked={settings.considerSymptomLocalization}
                name="considerSymptomLocalization"
                onChange={handleChange}
                label="Локализация"
            />
            <CheckboxBlock
                checked={settings.considerSymptomDynamic}
                name="considerSymptomDynamic"
                onChange={handleChange}
                label="Динамика процесса"
            />
            <CheckboxBlock
                checked={settings.considerSymptomOtherFactors}
                name="considerSymptomOtherFactors"
                onChange={handleChange}
                label="Влияющие факторы"
            />
        </PaperBlock>


        <CheckboxBlock
            checked={settings.considerEtiology}
            name="considerEtiology"
            onChange={handleChange}
            label="Учёт этиологии"
        />
        <CheckboxBlock
            checked={settings.considerRiskFactor}
            name="considerRiskFactor"
            onChange={handleChange}
            label="Учёт факторов риска"
        />
        <CheckboxBlock
            checked={settings.considerEquivalentRelations}
            name="considerEquivalentRelations"
            onChange={handleChange}
            label="Учёт эквивалентной связи"
        />
        <CheckboxBlock
            checked={settings.enableOldSemantic}
            name="enableOldSemantic"
            onChange={handleChange}
            label="Учёт устаревшей семантики"
        />


        <Box
            sx={{
                textDecoration: "underline",
                cursor: "pointer",
                textAlign: "right",
                mt: 4,
                color: "gray"
            }}
            onClick={handleClickReset}
        >
            Сбросить настройки
        </Box>
        <Box
            sx={{
                width: "100%",
                mt: 5,
                position: "sticky",
                bottom: 0,
                backgroundColor: "#FFF",
                pt: 2,
                pb: 2,
            }}>
            <Button
                variant="outlined"
                disabled={diagnosticDisabled}
                onClick={handleClickDiagnostic}
                sx={{
                    width: "100%",
                }}>Диагностировать</Button>
        </Box>

    </Box>;
}

export const Settings = memo(SettingsComponent);
