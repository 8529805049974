// import registerAnchor from './anchor/index';
// import registerEditorIcon from './editorIcon/index';
import registerNode from './node/index';
import registerEdge from './edge/index';

export default function (G6) {
    // registerAnchor(G6);
    // registerEditorIcon(G6);
    registerNode(G6);
    registerEdge(G6);
}
