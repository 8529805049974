import { createStyles } from '@mui/styles';

const styles = () => createStyles({
    tabs: {
        flexGrow: 1,
        minHeight: 'unset',
        marginLeft: '3%',
    },
    tab: {
        minHeight: 'unset',
        // fontSize: 11,
    },
    nodeTooltip: {
        minHeight: 'unset',
        border: '1px solid #e2e2e2',
        borderRadius: '4px',
        fontSize: '12px',
        opacity: 0.8,
        color: '#545454',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 10px 2px 4px',
        boxShadow: 'rgb(174 174 174) 0px 0px 10px',
    },
    container: {
        // border: '1px solid #e2e2e2',        
        height: "100%",
        width: "100%",
        overflow: "hidden",
        backgroundColor: "#FFF",
        "& svg": {
            // border: '1px solid #e2e2e2',
            backgroundColor: "#FFF",
        }
    }

});

export default styles;
