import React, { memo } from "react";

import {
    Checkbox,
    FormControlLabel,
} from '@mui/material';

const CheckboxBlockComponent = ({
    checked,
    name,
    onChange,
    label,
}) => <FormControlLabel
        control={<Checkbox
            checked={checked}
            name={name}
            onChange={onChange}
        />}
        label={label}
    />;

export const CheckboxBlock = memo(CheckboxBlockComponent);
