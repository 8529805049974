import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URLS, API_KEYS, CONFIG } from "./constants";
import { appStorage } from "helpers";

import {
  fetchAuth,
} from "./authAPI";

const initialState = {
  actionName: "registration",
  loading: false,
  tab: 0,
  registrationResult: {},
  loginResult: {},

  lastName: "",
  firstName: "",
  middleName: "",
  email: "",
  password: "",
  password2: "",
  department: "",
  lpuName: "",
  key: "",

  code: "",
  confirmationCode: "",
  recoveryCode: "",
  newPassword: "",

  authKey: "",
  isAuth: false,
  errors: {},
};

export const sendRegistration = createAsyncThunk(
  "auth/fetchSendRegistration",
  async (_, { getState }) => {

    const { auth } = getState();

    let data = {
      authKey: auth.authKey,
      lastName: auth.lastName,
      firstName: auth.firstName,
      middleName: auth.middleName,
      email: auth.email,
      password: auth.password,
      lpuName: auth.lpuName,
      department: auth.department,
    };

    let welcomeId = appStorage.getItem("welcomeId") || "";

    if (welcomeId) {
      data.welcomeId = welcomeId;
    }

    if (API_KEYS.service) {
      data.key = API_KEYS.service;
    }

    const response = await fetchAuth(API_URLS.auth.registration, data);
    return response;
  }
);

export const sendConfirm = createAsyncThunk(
  "auth/fetchSendConfirm",
  async (_, { getState }) => {

    const { auth } = getState();

    let data = {
      email: auth.email,
      code: auth.confirmationCode,
    };

    if (API_KEYS.service) {
      data.key = API_KEYS.service;
    }

    const response = await fetchAuth(API_URLS.auth.confirm, data);
    return response;
  }
);

export const sendLogin = createAsyncThunk(
  "auth/fetchSendLogin",
  async (_, { getState }) => {

    const { auth } = getState();

    let data = {
      email: auth.email,
      password: auth.password,
    };

    if (API_KEYS.service) {
      data.key = API_KEYS.service;
    }

    let welcomeId = appStorage.getItem("welcomeId") || "";

    if (welcomeId) {
      data.welcomeId = welcomeId;
    }

    console.log("sendLogin");
    const response = await fetchAuth(API_URLS.auth.login, data);

    console.log("response", response);
    return response;
  }
);

export const sendRecovery = createAsyncThunk(
  "auth/fetchSendRecovery",
  async (_, { getState }) => {

    const { auth } = getState();

    let data = {
      email: auth.email,
    };

    if (API_KEYS.passRequest) {
      data.key = API_KEYS.passRequest;
    }

    const response = await fetchAuth(API_URLS.auth.recovery, data);

    return response;
  }
);

export const sendGetConfirm = createAsyncThunk(
  "auth/fetchSendGetConfirm",
  async (_, { getState }) => {

    const { auth } = getState();

    let data = {
      email: auth.email,
    };

    if (API_KEYS.service) {
      data.key = API_KEYS.service;
    }

    const response = await fetchAuth(API_URLS.auth.getConfirm, data);

    return response;
  }
);

export const sendResetPass = createAsyncThunk(
  "auth/fetchSendResetPass",
  async (_, { getState }) => {

    const { auth } = getState();

    let data = {
      email: auth.email,
      newPassword: auth.password,
      recoveryCode: auth.recoveryCode,
    };

    if (API_KEYS.passReset) {
      data.key = API_KEYS.passReset;
    }

    const response = await fetchAuth(API_URLS.auth.resetPass, data);

    return response;
  }
);

export const sendLoginWhithKey = createAsyncThunk(
  "auth/fetchSendLoginWhithKey",
  async (propAuth, { getState }) => {
    let auth = {};

    if (propAuth && (propAuth.authKey || propAuth.key)) {
      auth = { ...propAuth };
    } else {
      auth = getState().auth;
    }

    const { authKey, key } = auth;

    const response = await fetchAuth(API_URLS.auth.checkAuth, {
      authKey,
      key,
    });

    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeTab: (state, action) => { state.tab = action.payload },
    resetRegistrationResult: state => { state.registrationResult = {} },
    resetLoginResult: state => { state.loginResult = {} },
    setErrors: (state, action) => { state.errors = action.payload },
    setActionName: (state, action) => { state.actionName = action.payload },

    setError: (state, action) => {
      const [actionName, message] = action.payload;

      state.errors[actionName] = message;
    },

    updateAuthData: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },

    updateErrors: (state, action) => {
      state.errors = {
        ...state.errors,
        ...action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendRegistration.pending, state => { state.loading = true })
      .addCase(sendRegistration.fulfilled, state => { state.loading = false })
      .addCase(sendRegistration.rejected, state => { state.loading = false })

      .addCase(sendConfirm.pending, state => { state.loading = true })
      .addCase(sendConfirm.fulfilled, state => { state.loading = false })
      .addCase(sendConfirm.rejected, state => { state.loading = false })

      .addCase(sendResetPass.pending, state => { state.loading = true })
      .addCase(sendResetPass.fulfilled, state => { state.loading = false })
      .addCase(sendResetPass.rejected, state => { state.loading = false })

      .addCase(sendGetConfirm.pending, state => { state.loading = true })
      .addCase(sendGetConfirm.fulfilled, state => { state.loading = false })
      .addCase(sendGetConfirm.rejected, state => { state.loading = false })

      .addCase(sendLogin.pending, state => { state.loading = true })
      .addCase(sendLogin.rejected, state => { state.loading = false })
      .addCase(sendLogin.fulfilled, (state, action) => {
        const payload = action.payload?.data ? action.payload.data : {};

        state.loading = false;
        state.authKey = payload.authKey;

        if (!CONFIG.auth.requiredKey) {
          appStorage.setItem("authKey", state.authKey);
          state.isAuth = true;
        }
      })

      .addCase(sendLoginWhithKey.pending, state => { state.loading = true })
      .addCase(sendLoginWhithKey.rejected, state => { state.loading = false })
      .addCase(sendLoginWhithKey.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.level !== "error") {
          state.isAuth = true;

          // Если в хранилище ключи есть, значит проверяет пользователь при логине, если нет, значит проверка идёт по ключам из localstorage и нужно докинуть их в хранилище
          if (state.authKey && state.key) {
            appStorage.setItem("authKey", state.authKey);
            appStorage.setItem("key", state.key);
          } else {
            state.authKey = appStorage.getItem("authKey") || state.authKey;
            state.key = appStorage.getItem("key") || state.key;

            if (!appStorage.getItem("key") || !appStorage.getItem("key")) {
              appStorage.setItem("authKey", state.authKey);
              appStorage.setItem("key", state.key);
            }
          }
        }
      });
  },
});

export const {
  updateErrors,
  updateAuthData,
  setError,
  setErrors,
  setActionName,
  changeTab,
  resetLoginResult,
  resetRegistrationResult,
} = authSlice.actions;

export const selectAuthData = (state) => state.auth;
export const selectErrors = (state) => state.auth.errors;
export const selectActionName = (state) => state.auth.actionName;
export const selectLoading = (state) => state.auth.loading;
export const selectTab = (state) => state.auth.tab;
export const selectRegistrationResult = (state) => state.auth.registrationResult;
export const selectLoginResult = (state) => state.auth.loginResult;
export const selectAuthkey = (state) => state.auth.authKey;

export default authSlice.reducer;
