import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appStorage } from "helpers";

import { AuthForm } from '../../';

import {
  sendLoginWhithKey,
} from "../../authSlice";

export function Restricted(props) {
  const dispatch = useDispatch();
  let isAuth = useSelector(state => state.auth.isAuth);

  useEffect(() => {
    const authKey = appStorage.getItem("authKey");
    const key = appStorage.getItem("key");

    if (authKey || key) {
      dispatch(sendLoginWhithKey({ authKey, key }));
    }
  }, [dispatch]);

  return (<>
    {!isAuth && !props.forcedAuth ? <AuthForm /> : props.children}
  </>
  );
}
