import { GRAPH_SETTINGS } from 'constants';


export default function (G6) {
  G6.registerBehavior('hoverTool', {
    getDefaultCfg() {
      return {
        multiple: false,
      };
    },
    getEvents() {
      return {
        'node:mouseenter': 'onMouseEnterNode',
        'node:mouseleave': 'onMouseLeaveNode',
        // 'node:mousemove': 'onMouseEnter',
        // 'inst-path:mouseenter': 'onMouseEnter',
        // 'inst-path:mouseover': 'onMouseOverInst',
        // 'inst-path:mouseout': 'onMouseLeaveInst',
        // 'inst-path:click': 'onMouseClickInst',
      };
    },

    getAnchorShapes(model, anchorPointIdx) {
      Math.radians = function (degrees) {
        return degrees * Math.PI / 180;
      };

      const tools = model?.data?.tools || [];
      const deg = 360 / tools.length;
      const ANCHOR_RADIUS = GRAPH_SETTINGS.NODE_RADIUS + 10;
      const ANCHOR_SHIFTED = ANCHOR_RADIUS - (GRAPH_SETTINGS.ANCHOR_SIZE / 2);
      let transformX = Math.cos(Math.radians(anchorPointIdx * deg)) * (ANCHOR_SHIFTED / 2) - ANCHOR_SHIFTED / 2;
      let transformY = Math.sin(Math.radians(anchorPointIdx * deg)) * (ANCHOR_SHIFTED / 2) - ANCHOR_SHIFTED / 2;
      transformX = Math.abs(transformX) / ANCHOR_SHIFTED;
      transformY = Math.abs(transformY) / ANCHOR_SHIFTED;

      return [transformX, transformY];
    },

    fittingString(str, maxWidth, fontSize) {
      let currentWidth = 0;
      let res = str;

      str.split('').forEach((letter, i) => {
        if (currentWidth > maxWidth) return;
        // get the width of single letter according to the fontSize
        currentWidth += G6.Util.getLetterWidth(letter, fontSize);

        if (currentWidth > maxWidth) {
          res = `${str.substr(0, i)}\n${str.substr(i)}`;
        }
      });
      return res;
    },

    clearGroup(group) {
      // group.clear();
    },

    onMouseClickInst(event) {
      console.log("CLICK");

      const el = event.target;
      const item = event.item;
      const anchorPointIdx = el.get("anchorPointIdx");

      const model = item.getModel();
      const tools = model?.data?.tools || [];
      const tool = tools[anchorPointIdx];
      console.log("🚀 ~ file: hoverTool.js ~ line 71 ~ onMouseClickInst ~ tool", tool)
    },

    onMouseEnterNode(event) {
      console.log("ENTER");
    },

    onMouseLeaveNode(event) {
      console.log("LEAVE");

      const item = event.item;
      const group = item.getContainer();
      const toolsTitlesGroup = group.findById('toolsTitlesGroup');
      this.clearGroup(toolsTitlesGroup);
    },

    // onMouseOverInst(event) {
    //   const el = event.target;
    //   const item = event.item;
    //   const anchorPointIdx = el.get("anchorPointIdx");

    //   if (el.get("name") === 'inst-path') {
    //     const model = item.getModel();
    //     const group = item.getContainer();
    //     const tools = model?.data?.tools || [];
    //     const tool = tools[anchorPointIdx];
    //     const toolsTitlesGroup = group.findById('toolsTitlesGroup');
    //     const bbox = el.getBBox();
    //     const anchorPos = this.getAnchorShapes(model, anchorPointIdx);

    //     this.clearGroup(toolsTitlesGroup);
    //     const itemText = toolsTitlesGroup.addShape('text', {
    //       attrs: {
    //         x: (bbox.x + bbox.width * anchorPos[0]),
    //         y: (bbox.y + bbox.height * anchorPos[1]),
    //         text: tool.name,
    //         fill: 'rgb(0 0 0 / 95%)',
    //         fontWeight: 400,
    //         // shadowOffsetX: 0,
    //         // shadowOffsetY: 0,
    //         // shadowColor: '#fff',
    //         // shadowBlur: 4,
    //       },
    //       zIndex: 30,
    //       name: 'inst-title',
    //       visible: false,
    //     });


    //     const applyMargin = (bbox, margin) => {
    //       return {
    //         ...bbox,
    //         width: bbox.width + (margin * 2),
    //         x: bbox.x - margin,
    //       }
    //     }

    //     const textBox = applyMargin(itemText.getBBox(), GRAPH_SETTINGS.TOOL_TITLE_MARGIN);
    //     console.log("🚀 ~ file: hoverTool.js ~ line 99 ~ onMouseOverInst ~ itemText", textBox)

    //     toolsTitlesGroup.addShape('rect', {
    //       attrs: {
    //         x: textBox.x,
    //         y: textBox.y,
    //         width: textBox.width,
    //         height: textBox.height,
    //         stroke: '#6b6b6b',
    //         fill: '#FFF',
    //         opacity: 0.7,
    //         radius: 1,
    //       },
    //       zIndex: 1000000000000,
    //       name: 'rect-shape',
    //     });
    //     console.log(itemText, "tofront");
    //     itemText.toFront()

    //   } else {
    //     if (el.get("name") !== 'inst-title') {

    //     }
    //   }
    // },

    onMouseLeaveInst(event) {
      const group = event.item.getContainer();
      const toolsTitlesGroup = group.findById('toolsTitlesGroup');

      this.clearGroup(toolsTitlesGroup);
    },

  });
}
