import React, { memo, useState } from "react";

import {
  Chip,
  Fade,
} from "@mui/material";


const ClarificationItemComponent = (chipProps) => {
  const [isIn] = useState(true);
  const { sx, ...props } = chipProps;

  return (<Fade
    in={isIn}
    style={{ transformOrigin: '0 0 0' }}
    timeout={400} >
    <Chip
      size="small"
      color="secondary"
      sx={{
        textTransform: "lowercase",
        m: 1,
        borderRadius: 1,
        ...sx
      }}
      {...props}
    />
  </Fade>
  );
}

export const ClarificationItem = memo(ClarificationItemComponent);
