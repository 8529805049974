class AppStorage {
    constructor() {
        this.storage = JSON.parse(localStorage.getItem(process.env.REACT_APP_NAME));

        if (!this.storage) {
            this.storage = {};
        }
    }

    getItem = (item) => {
        return this.storage.hasOwnProperty(item) ? this.storage[item] : null;
    };
    setItem = (item, value = null) => {
        let set = false;
        if (typeof item === 'string' || typeof item === 'number') {
            this.storage = {
                ...this.storage,
                [item]: value,
            };
            set = true;
        } else if (Array.isArray(item)) {
            throw new Error("Array can't be set to localStorage for security reasons");
        } else if (typeof item === 'object') {
            this.storage = {
                ...this.storage,
                ...item,
            };
            set = true;
        }
        if (set) {
            localStorage.setItem(process.env.REACT_APP_NAME, JSON.stringify(this.storage));
        }
    };

    removeItem = (item) => {
        this.setItem(item)
    };
    clear = () => {
        this.storage = null;
        localStorage.setItem(process.env.REACT_APP_NAME, null);
    };
    getAll = () => {
        return this.storage = JSON.parse(localStorage.getItem(process.env.REACT_APP_NAME));
    };

    _set(item, value) {
        if (this.storage) {
            this.storage = {
                ...this.storage,
                [item]: value,
            };
        } else {
            this.storage = {};
            this.storage[item] = value;
        }
    }
};

export const appStorage = new AppStorage();
