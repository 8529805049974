// import { randomFromArray, getIcon } from '../../../../helpers';
// import red from '@material-ui/core/colors/red';
// import purple from '@material-ui/core/colors/purple';
// import blue from '@material-ui/core/colors/blue';
// import teal from '@material-ui/core/colors/teal';
// import orange from '@material-ui/core/colors/orange';
// import indigo from '@material-ui/core/colors/indigo';
// import cyan from '@material-ui/core/colors/cyan';
// import green from '@material-ui/core/colors/green';

// const colors = [
//     red[500],
//     blue[500],
//     teal[500],
//     purple[500],
//     orange[500],
//     cyan[500],
//     green[500],
//     indigo[500],
// ];

export default function (G6) {

    G6.registerNode(
        'tool-node',
        {
            icon: '',
            iconLibColor: '',

            draw(cfg, group) {
                let fanIn;

                if (group) {
                    this.icon = cfg?.data?.icon || '';
                    let hasIcon = true;

                    fanIn = group.addShape('rect', {
                        attrs: {
                            // r: 15,

                            width: 26,
                            height: 26,
                            stroke: 'black',
                            radius: [2, 8],

                            fill: `r(0.6, 0.3, 0.7) 0:#ffffff 1:${this.iconLibColor}`,
                            // stroke: '#72CC4A',
                            // shadowOffsetX: 2,
                            // shadowOffsetY: 2,
                            // shadowColor: 'gray',
                            // shadowBlur: 2,
                            // strokeWidth: 50,
                            // opacity: 0.2,
                            // strokeDasharray: 30,
                            // transition: 'stroke-dasharray 2.3s ease',
                            // x and y should be minus width / 2 and height / 2 respectively to translate the center of the rect to the midPoint
                        },
                    });

                    if (hasIcon) {
                        const icon = this.getIcon(this.icon);

                        group.addShape('text', {
                            attrs: {
                                id: `fontIcon-${this.icon}`,
                                x: 13,
                                y: 13,
                                fontSize: 13,
                                fill: '#124f69',
                                text: icon.view,
                                fontFamily: icon.font,
                                textAlign: 'center',
                                textBaseline: 'middle',
                            },
                            name: 'icon-shape',
                        });
                    }
                }

                return fanIn;
            },
        },
        'single-node',
    );
}
