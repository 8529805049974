import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Typography,
} from '@mui/material';

import { Loader } from "UI";

import { ResultList } from "features/pages";

import {
    selectResultData,
    selectLoading,
} from "features/pages/pagesSlice";

import { Graph } from "features/graph";

import {
    setCurrentPage,
} from "features/main/mainSlice";

import {
    sendGetRAS,
} from "features/pages/pagesSlice";

const ResultComponent = () => {
    const dispatch = useDispatch();
    const resultData = useSelector(selectResultData);
    const { diagnostic: loading } = useSelector(selectLoading);

    const handleClickBack = () => {
        dispatch(setCurrentPage("symptoms"));
    }

    const handleClickRecommendations = () => {
        console.log("handleClickRecommendations");
    }

    const handleClickRAS = () => {
        dispatch(sendGetRAS({
            success: () => dispatch(setCurrentPage("RAS")),
        }));
        console.log("handleClickRecommendations");
    }

    let diagnosis = resultData.nozologyData?.diagnosis || "отсутствует";

    return <>

        <Loader loading={loading}>
            <Box sx={{ height: "calc(100vh - 126px)", overflow: "auto", width: '100%' }}>
                {/* <Graph/> */}
                <Typography variant="h6">Предварительный диагноз:</Typography>
                <Alert
                    sx={{
                        mr: 2,
                        // display: "inline-block",
                        "& :first-letter": {
                            textTransform: "uppercase"
                        }
                    }}
                    severity="info"
                    icon={false}
                >
                    {diagnosis}
                </Alert>

                <Divider sx={{ mt: 6, mb: 6 }} />

                {resultData.nozologyData?.nozologiesList?.length ?
                    <ResultList
                        title={"Вероятность наличия заболеваний:"}
                        data={resultData.nozologyData.nozologiesList || []}
                    /> : null}

                {resultData.nozologyData?.riskFactorsList?.length ?
                    <ResultList
                        title={"Риски развития заболеваний/осложнений:"}
                        data={resultData.nozologyData.riskFactorsList || []}
                    /> : null}
            </Box>

            <ButtonGroup
                fullWidth
                size="large"
                aria-label="button group"
            >
                <Button
                    disabled={false}
                    onClick={handleClickBack}
                >Назад к симптомам</Button>
                <Button
                    disabled={false}
                    onClick={handleClickRAS}
                >Уточнения</Button>
                <Button
                    disabled={false}
                    onClick={handleClickRecommendations}
                >Рекомендации</Button>
            </ButtonGroup>
        </Loader>
    </>;
}

export const Result = memo(ResultComponent);
