import React, { memo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Box,
    InputLabel,
    FormControl,
    Select,
    Paper,
    MenuItem,
} from '@mui/material';

import { Selector1 } from "./Selector1";


import {
    setSensorValue,
} from "features/pages/pagesSlice";


const QuestionsComponent = ({
    sensors,
    children,
}) => {

    const dispatch = useDispatch();

    // const patient = useSelector(selectPatient);
    let ContentComponent = null;
    // let title = "";


    const handleChange = ({ id, value }) => {
        dispatch(setSensorValue({ id, value }));
    }

    const SensorComponent = ({ sensor, id }) => {

        console.log(sensor);
        const type = sensor.type || "";

        switch (type) {

            case "RasButtonPreset_1":
                ContentComponent = <Selector1 sensor={sensor} id={id} onChange={handleChange} />;
                // title = TXT.symptoms;
                break;


            default:
                ContentComponent = <Alert severity="info">Загрузка [ unknown sensor type {type}].</Alert>;
                break;
        }

        console.log(ContentComponent);
        return ContentComponent;
    }

    console.log(sensors);

    return <>
        {Object.keys(sensors).map(key => {
            return <Box key={key}>
                <SensorComponent sensor={sensors[key]} id={key}/></Box>;
        })}
    </>;
}

export const Questions = memo(QuestionsComponent);
