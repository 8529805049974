import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { NavLink } from "react-router-dom";

import { TextField, Stack } from '@mui/material';

import { checkErrors } from 'helpers';

import { Button } from "../Button";

import {
  selectAuthData,
  selectLoading,
  selectErrors,
  selectAuthkey,
  setErrors,
  updateAuthData,
  sendLogin,
  sendLoginWhithKey,
  resetLoginResult,
} from '../../authSlice';


export function Login() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const authData = useSelector(selectAuthData);
  const errors = useSelector(selectErrors);
  const authKey = useSelector(selectAuthkey);

  const handleLogin = () => {
    console.log("handleLogin");
    const formErrors = checkErrors(authData, [
      { name: "email", type: "email", required: true },
      { name: "password", required: true },
    ]);
    console.log("formErrors", formErrors);
    dispatch(setErrors(formErrors));

    console.log("!Object.keys(formErrors).length", !Object.keys(formErrors).length);
    if (!Object.keys(formErrors).length) {
      console.log("if");
      dispatch(resetLoginResult());
      dispatch(sendLogin());
    }
  };

  const handleLoginWhithKey = () => {
    console.log("handleLoginWhithKey");
    const formErrors = checkErrors(authData, [
      { name: "email", type: "email", required: true },
      { name: "password", required: true },
      { name: "key", required: true },
    ]);
    console.log("formErrors", formErrors);
    dispatch(setErrors(formErrors));

    if (!Object.keys(formErrors).length) {
      console.log("NO ERRORS");
      dispatch(resetLoginResult());
      dispatch(sendLoginWhithKey());
    }
  };


  const handleSubmit = () => {
    console.log('authKey', authKey);
    console.log('!!authKey', !!authKey);
    if (!!authKey) {
      console.log("start handleLoginWhithKey");
      handleLoginWhithKey();
    } else {
      handleLogin();
    }
  };

  const handleChangeField = (event) => {
    const name = event.target.id;
    let value = event.target.value;

    dispatch(updateAuthData({
      [name]: value.trim(),
    }));
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      dispatch(setErrors({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Stack
      direction="column"
      spacing={2}
    >
      <TextField
        error={!!errors.email}
        id="email"
        label="Email"
        variant="outlined"
        helperText={errors.email}
        value={authData.email}
        onChange={handleChangeField}
        disabled={!!authKey}
        size="small"
      />
      <TextField
        error={!!errors.password}
        id="password"
        label="Пароль"
        variant="outlined"
        helperText={errors.password}
        value={authData.password}
        onChange={handleChangeField}
        type="password"
        size="small"
        disabled={!!authKey}
      />
      {/* <Box
        sx={{
          width: "100%",
          textAlign: "right",
          fontSize: "12px",
        }}
      ><NavLink
        key={"/resetpass"}
        to={"/resetpass"}
      >Восстановить пароль</NavLink>
      </Box> */}
      {authKey && <TextField
        error={!!errors.key}
        id="key"
        label="Персональный ключ"
        variant="outlined"
        helperText={errors.key}
        value={authData.key}
        onChange={handleChangeField}
        type="key"
        size="small"
      />}
      {/* {loginResult.error && <Alert severity="error">{loginResult.error}</Alert>}
      {loginResult.message && <Alert severity="success">{loginResult.message}</Alert>} */}

      <Button
        onClick={handleSubmit}
        loading={loading}
        title="Войти"
      />
    </Stack>
  );
}
