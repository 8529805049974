// import { GRAPH_SETTINGS } from "../../../../constants";
// import { appStorage } from "../../../../helpers";


// const graphSettings = appStorage.getItem("graphSettings") || {};

// const type = graphSettings.edgesType || "line";

export default function (G6) {
  G6.registerEdge(
    "binary-edge",
    {
      draw(cfg, group) {
        const { startPoint, endPoint } = cfg;
        const keyShape = group.addShape("path", {
          attrs: {
            path: [
              ["M", startPoint.x, startPoint.y],
              ["L", endPoint.x, endPoint.y],
            ],
            // path: [
            //   ['M', startPoint.x, startPoint.y],
            //   ['L', endPoint.x / 3 + (2 / 3) * startPoint.x, startPoint.y], // 1/3
            //   ['L', endPoint.x / 3 + (2 / 3) * startPoint.x, endPoint.y], // 2/3
            //   ['L', endPoint.x, endPoint.y],
            // ],
            // fill: "#0082dd",
            stroke: "#0082dd",
            lineDash: [20, 4],
            lineWidth: 3,

          },
          // must be assigned in G6 3.3 and later versions. it can be any value you want
          name: "binary-edge",
        });

        return keyShape;
      },
      update: undefined,
    },
    "cubic-vertical", //  line cubic quadratic polyline arc cubic-vertical cubic-horizontal
  );
}