
import { getGraph, getIcon, fixColor } from "helpers";
import { GRAPH_SETTINGS, levels, tools } from "constants";


export function convertUMKBDataToGraph({ graph, result }) {
    const DEEP = 10;
    const tool = { id: 100 };
    const model = {};

    console.log(graph);
    console.log(result);
    const graphData = result.graph || [];
    const conceptsInfo = result.conceptsInfo || {};
    //  levels = levels || {};
    // const tools = result.tools || {};
    const valueNames = result.valueNames || {};

    const clickedNodeModel = graphData[0] || {};
    console.log(clickedNodeModel);
    const items = graphData;

    let convertedData = {
        nodes: [],
        edges: [],
    };
    let deg;

    for (let currentDeep = 1; currentDeep <= DEEP; currentDeep++) {
        const deepItems = items.filter(item => item.deep === currentDeep);
        if (DEEP === 1) {
            deg = 360 / deepItems.length;
        }

        // eslint-disable-next-line no-loop-func
        deepItems.forEach((deepItem, i) => {
            // Каждой новой ноде присваиваем координаты той ноды в которую кликали и добавляем рандом, иначе они будут в одной точке и не смогут друг от друга оттолкнуться
            // Это нужно, чтобы ноды тянулись физикой не от центра графа
            let parentModel = {};
            if (DEEP === 1) {
                parentModel = clickedNodeModel;
            } else {
                parentModel = convertedData.nodes.find(node => node.id === deepItem.idA);
                if (!parentModel) {
                    console.log("NOparentModel", parentModel);
                    parentModel = clickedNodeModel;
                }
            }

            if (DEEP > 1) {
                let children = deepItems.filter(item3 => item3.idA === deepItem.idA);
                deg = 360 / children.length;
            }


            const place = tool.id === -1 ? getPlace(parentModel, 90, i) : getPlace(parentModel, deg, i);
            let nodeInfoA = getConceptInfo({
                id: deepItem.idA,
                conceptsInfo,
                levels
            });
            let nodeInfoB = getConceptInfo({
                id: deepItem.idB,
                conceptsInfo,
                levels
            });
            let edgeInfo = getConceptInfo({
                id: deepItem.id,
                conceptsInfo,
                levels
            });
            const toolInfo = getTool({ id: deepItem.tool, tools });

            let edge = graph.findById(`${deepItem.idB}_ghost`);

            let hasIdA = convertedData.nodes.some(item => item.id === `${deepItem.idA}`);
            let hasIdB = convertedData.nodes.some(item => item.id === `${deepItem.idB}`);

            if (edge) {
                const group = edge.getContainer();
                const shape = group.get('children')[0];
                const midPoint = shape.getPoint(0.5);

                if (!hasIdB) {
                    convertedData.nodes.push({
                        id: deepItem.idB,
                        x: midPoint.x,
                        y: midPoint.y,
                        fx: midPoint.x,
                        fy: midPoint.y,
                        data: {
                            ...deepItem,
                            ...nodeInfoB,
                        },
                        type: "ghost-node",
                    });
                }


                if (!hasIdA) {
                    convertedData.nodes.push({
                        id: deepItem.idA,
                        x: midPoint.x,
                        y: midPoint.y,
                        fx: midPoint.x,
                        fy: midPoint.y,
                        data: {
                            ...deepItem,
                            ...nodeInfoA,
                        },
                        type: "ghost-node",
                    });
                }
            } else {

                if (!hasIdB) {
                    convertedData.nodes.push({
                        id: deepItem.idB,
                        ...place,
                        data: {
                            ...deepItem,
                            ...nodeInfoB,
                        },
                        type: clickedNodeModel.idB === deepItem.idB ? 'master-concept-node-animated' : 'master-concept-node',
                    });
                }

                if (!hasIdA) {
                    convertedData.nodes.push({
                        id: deepItem.idA,
                        ...place,
                        data: {
                            ...deepItem,
                            ...nodeInfoA,
                        },
                        type:  clickedNodeModel.idB === deepItem.idA ? 'master-concept-node-animated' : 'master-concept-node',
                    });
                }
            }

            const values = prepareValuesData({ item: deepItem, valueNames });
            const firstValue = values[0] || {};
            const lineWidth = (firstValue.realValue || 0.1) * 5;

            convertedData.edges.push({
                id: `${deepItem.id}_ghost`, // Не присваиваем настоящий id, т.к. он понадобится для фейковых вершин
                source: deepItem.idA,
                target: deepItem.idB,
                type: +tool.id === -1 ? "binary-edge" : 'mid-point-edge',
                style: {
                    lineWidth,
                },
                data: {
                    ...deepItem,
                    id: `${deepItem.id}_ghost`, // попробовать убрать отсюда, но пока на него смотрят, например getToolsByToolConcepts берёт id из data
                    ...edgeInfo,
                    toolColor: fixColor(toolInfo.color),
                    fromTool: model.type === "ghost-node",
                    groupLength: items.length,
                    values: values.slice(1), // удаляем первые значения, предполагаем что там сила связи, её отобразили за счёт толщины линии lineWidth
                },
            });
        });
    }

    console.log(convertedData);
    return convertedData;
}


function getPlace(model, deg, i) {
    let place = model.fx ? {
        x: model.fx,
        y: model.fy,
        // fx: model.fx,
        // fy: model.fy,
    } : {
        x: model.x,
        y: model.y,
        // fx: model.x,
        // fy: model.y,
    };

    // размещаем по кругу
    const currentDeg = 180 + (deg * i) - (deg / 2);
    place.x = place.x + GRAPH_SETTINGS.LINK_LENGTH_DEFAULT * Math.cos(Math.PI * currentDeg / 180);
    place.y = place.y + GRAPH_SETTINGS.LINK_LENGTH_DEFAULT * Math.sin(Math.PI * currentDeg / 180);
    // place.fx = place.x + GRAPH_SETTINGS.LINK_LENGTH_DEFAULT * Math.cos(Math.PI * currentDeg / 180);
    // place.fy = place.y + GRAPH_SETTINGS.LINK_LENGTH_DEFAULT * Math.sin(Math.PI * currentDeg / 180);

    return place;
}



function getConceptInfo({ id, conceptsInfo, levels }) {
    let name = "" + id || "NO_IDB";
    let level = null;
    let levelColor = "";
    let levelName = "";
    let color = "";

    if (conceptsInfo[id]) {
        const levelInfo = getLevel({ id: conceptsInfo[id].level, levels });
        level = conceptsInfo[id].level || null;
        name = conceptsInfo[id].name || "- без названия -";
        color = fixColor(conceptsInfo[id].color) || GRAPH_SETTINGS.EMPTY_COLOR;
        levelColor = fixColor(levelInfo.color);
        levelName = levelInfo.name;
    }

    return {
        name,
        level,
        color,
        levelColor,
        levelName,
    };
}



const prepareValuesData = ({ item, valueNames }) => {
    let preparedValuesData = [];
    const values = valueNames[item.tool] || {};

    for (var prop in values) {
        if (values[prop]) {
            let realVal = item[`value${prop.toUpperCase()}`];
            let val = null;

            if (values[prop].values) {
                val = values[prop].values["" + realVal] || null;

                if (val && val.view) {
                    val = {
                        ...val,
                        ...getIcon(val.view)
                    };

                    // console.log("val getIcon", val);
                }
            }

            preparedValuesData.push({
                name: values[prop].name,
                valueInfo: val,
                realValue: realVal
            });
        }
    }

    // На ребре оценки расставляются с конца, поэтому чтобы не делать лишних расчётов в ребре, сразу переворачиваем массив

    // if (preparedValuesData.length > 1) {
    //     preparedValuesData.reverse();
    // }

    return preparedValuesData;
}



const getLevel = ({ id, levels }) => {
    if (id && levels[id]) {
        if (!levels[id].color) {
            // levels[id].color = GRAPH_SETTINGS.EMPTY_COLOR;

            return {
                ...levels[id],
                color: GRAPH_SETTINGS.EMPTY_COLOR
            }
        }
        return levels[id];
    } else {
        console.log(`NO LEVEL: '${id}'`);
        return {};
    }
};

const getTool = ({ id, tools }) => {
    if (id && tools[id]) {
        if (!tools[id].color) {
            // tools[id].color = GRAPH_SETTINGS.EMPTY_COLOR;
            return {
                ...tools[id],
                color: GRAPH_SETTINGS.EMPTY_COLOR
            }
        }
        return tools[id];
    } else {
        console.log(`NO TOOL: '${id}'`);
        return {};
    }
};


