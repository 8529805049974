import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
    Box,
} from '@mui/material';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import { Search } from "features/pages";
import { DiseaseDetailsList } from "./DiseaseDetailsList";

import {
    selectCurrentPatientDisease,
} from "features/pages/pagesSlice";

const DiseaseDetailsComponent = () => {
    const currentPatientDisease = useSelector(selectCurrentPatientDisease);

    console.log(currentPatientDisease);
    return <>
        <Box sx={{ fontWeight: 600, mb: 3, ml: 2, mr: 2 }} component={"span"}>{currentPatientDisease?.name}</Box>

        <DiseaseDetailsList type="Localization" />
        <Box sx={{ mb: 4 }}>
            <DiseaseDetailsList type="DiseaseDetails" />
        </Box>

        <Box sx={{ mb: 4 }}>
            <Search
                item={currentPatientDisease}
                type="DiseaseDetails"
                inputColor="#aa904129"
                label={"Укажите характеристики заболевания"}
                itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
                resultItemProps={{
                    sx: { textTransform: "lowercase" }
                }}
            />
        </Box>

    </>;
}

export const DiseaseDetails = memo(DiseaseDetailsComponent);
