import React from 'react';
import { ToastContainer, Flip } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';

import 'react-toastify/dist/ReactToastify.min.css';

export function Alerts() {
  return (
      <ToastContainer
        transition={Flip}
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={5}
        // theme="colored"
      />
  );
}