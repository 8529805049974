import React, { memo } from "react";

import {
    Box,
} from '@mui/material';

const InputValueBlockComponent = ({
    value,
}) => <Box sx={{
    width: "40px",
    height: "18px",
    // borderBottom: "1px solid gray",
    backgroundColor: "#e1eeee",
    pr: 1,
    fontSize: "13px",
    textAlign: "right",
    ml: 2,
}}>{value}</Box>;


export const InputValueBlock = memo(InputValueBlockComponent);
