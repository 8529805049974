import React, { memo } from "react";

import Button from '@mui/material/Button';
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { Loader } from "UI/Loader";


const DialogComponent = ({
  title = "",
  children = "",
  loading = false,
  open = true,
  onClose = () => { },
  closeButton = true,
  fullWidth = true,
  actions = [],
  scroll = "paper",
  sx = {},
  classes = {},
  PaperProps = {},
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (closeButton) {
    actions.push(
      {
        title: "Закрыть",
        onClick: onClose
      }
    );
  }

  return (
    <MUIDialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={fullWidth}
      scroll={scroll}
      sx={sx}
      PaperProps={PaperProps}
      classes={classes}
    >
      <Loader loading={loading} variant="replace" >
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent>
          {children}
        </DialogContent>
      </Loader>

      <DialogActions>
        {actions.map(action => <Button key={action.title} {...action}>{action.title}</Button>)}
      </DialogActions>
    </MUIDialog>
  );
}

export const Dialog = memo(DialogComponent);