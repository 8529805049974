import React from "react";
import { ICON_LIBS } from '../constants';

function fixIconName(iconName = "") {

    if (!iconName) {
        return "";
    }

    if (iconName.includes('fa-thumbs-o-down')) {
        iconName = 'fa-thumbs-down';
    }
    if (iconName.includes('fa-hand-o-right')) {
        iconName = 'fa-hand-point-right';
    }
    if (iconName.includes('fa-gears')) {
        iconName = 'fa-cogs';
    }

    iconName = iconName.replace('fa ', '');
    iconName = iconName.replace('fa-', '');
    iconName = iconName.replace('glyphicon-', '');

    return iconName;
}

function getIconLib(iconName = "") {
    let iconLib;

    if (iconName && (iconName.includes('fa-') || iconName.includes('fa '))) {
        iconLib = ICON_LIBS['FA'];
    } else {
        iconLib = ICON_LIBS['GLYPH'];
    }

    return iconLib;
}

export function getIcon(iconName = "") {
    let iconLib = getIconLib(iconName);
    iconName = fixIconName(iconName);
    const matchIcon = iconLib.iconsMeta[iconName];

    let icon = {};

    if (!matchIcon) {
        icon = {
            view: iconName,
            font: ""
        };
    } else {
        icon = {
            view: String.fromCharCode(parseInt(matchIcon.unicode.replace(/\\u/g, ''), 16)),
            font: iconLib.fontFamily,
        };
        icon.node = <span style={{ fontFamily: icon.font }}>{icon.view}</span>;
    }

    // fix html code
    if (icon.view && icon.view.substring && icon.view.substring(0, 2) === "&#" && icon.view.substring(icon.view.length - 1) !== ";") {
        icon.view = `${icon.view};`
    }

    return icon;
}
