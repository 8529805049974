
import G6 from "@antv/g6";
import insertCss from "insert-css";

import { GRAPH_SETTINGS } from "constants";

insertCss(`
  .g6-minimap-container {
    border: 1px solid #e2e2e2;
  }
  .g6-minimap-viewport {
    border: 1px solid rgb(25, 128, 255);
  }
  

  .g6-my-graphContextMenu {
	!border: 1px solid #999c9f;
	max-width: 250px;
	background-color: white;
	font-family: tahoma;
	font-size: 11px;
	max-height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-box-shadow: 3px 2px 5px 2px rgba(34, 60, 80, 0.4);
	-moz-box-shadow: 3px 2px 5px 2px rgba(34, 60, 80, 0.4);
	box-shadow: 3px 2px 5px 2px rgba(34, 60, 80, 0.4);
  }
  .g6-my-graphContextMenu:hover {
	max-width: unset;
  }
  .g6-my-graphContextMenu .g6-my-row:hover{
	background-color: #D8EEF0;
  }
  .g6-my-graphContextMenu .g6-my-row{
	width: 100%;
	border: 1px solid #e2e2e2;	
	display: flex;
	cursor: pointer;
  }
  .g6-my-graphContextMenu .g6-my-color{
	width: 15px;
	min-width: 15px;
	font-size: 8px;
  }
  .g6-my-graphContextMenu .g6-my-icon{
	width: 20px;
	min-width: 20px;
	font-size: 15px;
	color: #7986cb;
	align-self: center;
	text-align: center;
  }
  .g6-my-graphContextMenu .g6-my-text{
	width: 100%;
	padding: 3px 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  .g6-my-graphContextMenu .g6-my-bar{
	width: 100%;
	display: flex;
  }
`);



export default function createGraph(ref, dependencies) {
	let graph;

	const tooltipStarters = [
		"inst-path",
		"mid-point-edge-circle",
		"mid-point-edge-value-circle",
		"mid-point-edge-value-text",
		"master-concept-node-level-circle",
		"master-concept-node-circle",
		// "edge-shape",
	];

	const tooltip = new G6.Tooltip({
		offsetX: 10,
		offsetY: 20,
		shouldBegin(event) {
			const cfg = event?.target.cfg || {};

			return tooltipStarters.includes(cfg.name);
		},

		getContent(event) {
			const cfg = event?.target.cfg || {};

			if (!tooltipStarters.includes(cfg.name)) {
				return "";
			}

			const model = event?.item?.getModel() || {};
			const outDiv = document.createElement("div");
			outDiv.style.width = "250px";
			outDiv.style.padding = "0px";
			outDiv.style.margin = "0px";
			// outDiv.style.backgroundColor = "red";

			let value = "";

			if (model.type === "mid-point-edge") {
				value = model.data.valueA ? ` <br/>[<b>Вес</b>: ${model.data.valueA}]` : "";
			} else if (model.type === "master-concept-node") {
				value = model.data.nodeValue ? ` <br/>[<b>Вес</b>: ${model.data.nodeValue}]` : "";
			}

			if (cfg.name === "inst-path") {
				const anchorPointIdx = cfg.anchorPointIdx;
				outDiv.innerHTML = `${model.data.tools[anchorPointIdx].name || "- без названия -"}`; // e?.item?.getModel().id
			} else if (cfg.name === "mid-point-edge-circle" || cfg.name === "master-concept-node-level-circle" || cfg.name === "master-concept-node-circle") {
				if (graph.get("lableIsHidden")) {
					outDiv.innerHTML = `${model.data.name || "- без названия -"}<br/><br/><b>Тип:</b> ${model.data.levelName || "- без названия -"}${value}`;
				} else {
					outDiv.innerHTML = `<b>Тип:</b> ${model.data.levelName || "- без названия -"}${value}`;
				}

			} else if (cfg.name === "mid-point-edge-value-circle" || cfg.name === "mid-point-edge-value-text") {
				const parent = cfg.parent;
				const circle = parent.cfg.children[0];

				outDiv.innerHTML = `${circle.cfg.data.tooltip}`;
			}

			return outDiv;
		},

		itemTypes: ["node", "edge"]
	});

	graph = new G6.Graph({
		container: ref.current,
		animate: false,
		width: 600,
		height: 600,
		maxZoom: 2, // 10
		minZoom: 0.2, // 0.2		
		plugins: [
			tooltip,
			// menu,
			// minimap,
			// grid,
		],
		// fitCenter: true,
		linkCenter: true,
		renderer: "canvas", // approach, svg, canvas
		// force gForce random radial dagre fruchterman forceAtlas2 circular mds grid concentric 
		// fruchterman forceAtlas2 concentric
		layout: {
			type: "dagre",
			rankdir: 'BT',  // 'TB' | 'BT' | 'LR' | 'RL'
			// align: undefined, //  'UL' | 'UR' | 'DL' | 'DR' | undefined
			// nodeOrder: ["140960025"],
			gravity: 0, // 10, The gravity strength to the center for all the nodes. Larger the number, more compact the nodes
			coulombDisScale: 0.004, // 0.005, A parameter for repulsive force between nodes. Large the number, larger the repulsion
			maxSpeed: 10000, // 1000, The max speed in each iteration
			maxIteration: 1000, // 1000, The max number of iterations. If the average movement do not reach minMovement but the iteration number is over maxIteration, terminate the layout
			minMovement: 0.4, // 0.5, When the average movement of nodes in one iteration is smaller than minMovement, terminate the layout
			damping: 2, // 0.9, Range [0, 1], affect the speed of decreasing node moving speed. Large the number, slower the decreasing
			// nodeSpacing: 200, // 0
			// preventOverlap: true,
			// nodeSize: 10000,
			nodeStrength: 1000, // 1000
			edgeStrength: 40, // 200
			gpuEnabled: true,
			linkDistance: d => {
				let length = GRAPH_SETTINGS.LINK_LENGTH_SHORT;

				if (d.data.groupLength > 10) {
					length = length * (d.data.groupLength / 10);
				}

				if (!d.data.fromTool && length < GRAPH_SETTINGS.LINK_LENGTH_DEFAULT_MIN) {
					length = GRAPH_SETTINGS.LINK_LENGTH_DEFAULT_MIN;
				}

				return length;
			},

			onTick: () => {
				// graph.layout();
			},

			onLayoutEnd: () => {
				const ghostNodes = graph.findAll("node", (node) => {
					return node.get("model").type === "ghost-node";
				});

				ghostNodes.forEach(ghostNode => {
					const nodeId = ghostNode.get("id");
					const edge = graph.findById(`${nodeId}_ghost`);

					if (edge) {
						setTimeout(() => {
							const group = edge.getContainer();
							const shape = group.get("children")[0];
							const midPoint = shape.getPoint(0.5);

							graph.updateItem(ghostNode, {
								x: midPoint.x,
								y: midPoint.y,
							});
						}, 100);
					}
					console.log("force layout done");
				});
			},
		},
		modes: {
			default: [
				"drag-canvas",
				"zoom-canvas",
				"select-neighbours",
				// "drag-node",
				{
					type: 'drag-node',
					shouldBegin: (event) => {
						const item = event.item || {};

						if (item.get && (item.get('currentShape') === "ghost-node" || item.get('type') === "edge")) {
							return false;
						}

						return true;
					},
				},
				{
					type: "brush-select",
					// trigger: "ctrl",
					includeEdges: false,
					shouldUpdate: event => {
						const cfg = event._cfg;
						const currentShape = cfg.currentShape;

						if (currentShape !== "ghost-node") {
							return true;
						}
						return false;
					}
				},
			],
		},

		defaultNode: {
			type: "master-concept-node",
			x: 0,
			y: 0,
			labelCfg: {
				position: "bottom",
				// offset: GRAPH_SETTINGS.NODE_LABEL_MARGIN,
				style: {
					fill: GRAPH_SETTINGS.NODE_LABEL_COLOR,
					fontSize: GRAPH_SETTINGS.MAIN_FONT_SIZE,
					fontWeight: 400,
					fontFamily: "Fira Sans",
					background: {
						fill: "#ffffff",
						stroke: "#9EC9FF",
						padding: [2, 2, 2, 2],
						radius: 2,
					},
				},
			},

		},
		defaultEdge: {
			type: "line",
			style: {
				radius: 20, // для polyline
				stroke: GRAPH_SETTINGS.EDGE_COLOR,
				lineWidth: 2,
				endArrow: {
					path: G6.Arrow.vee(8, 10, 30), // Using the built-in edges for the path, parameters are the width, length, offset (0 by default, corresponds to d), respectively
					d: 25,
					lineWidth: 3,
				},
			},
		},
	});

	graph.data(dependencies);
	graph.render();
	// graph.fitView();



	// let centerNodes = graph.getNodes();

	// graph.on('afterlayout', () => {
	// 	const hull1 = graph.createHull({
	// 		id: 'centerNode-hull',
	// 		type: 'bubble', // smooth-convex round-convex
	// 		members: centerNodes,
	// 		padding: 10,
	// 	});

	// 	graph.on('afterupdateitem', (e) => {
	// 		hull1.updateData(hull1.members);
	// 	});
	// });





	return graph;
}