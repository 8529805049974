import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import clsx from "clsx";

import { withStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { store } from './app/store';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const defaultTheme = createTheme();
const MyComponent = withStyles((props) => {
  const { classes, className, ...other } = props;
  return <div className={clsx(className, classes.root)} {...other} />
})(({ theme }) => ({ root: { background: theme.palette.primary.main } }));

root.render(
  <ThemeProvider theme={defaultTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

