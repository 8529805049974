import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchSendGetGraphData({ data = {}, success = null }) {

  return POST({
    path: API_URLS.graph.GetGraphData,
    data:{
      ...data,
      // graphData: graphData
    },
    success: (data) => {
      // let result = data && data.concepts && data.concepts.length > 0 ? data.concepts : [];
      success && success();
    },
  });
}
