
import { randomFromArray } from 'helpers';
import red from '@mui/material/colors/red';
import purple from '@mui/material/colors/purple';
import blue from '@mui/material/colors/blue';
import teal from '@mui/material/colors/teal';
import orange from '@mui/material/colors/orange';
import indigo from '@mui/material/colors/indigo';
import cyan from '@mui/material/colors/cyan';
import green from '@mui/material/colors/green';

const colors = [
    red[500],
    blue[500],
    teal[500],
    purple[500],
    orange[500],
    cyan[500],
    green[500],
    indigo[500],
];

export default function (G6) {
    G6.registerNode(
        'pie-node',
        {
            draw: (_cfg, group) => {
                var generateWedgeString = function (startX, startY, startAngle, endAngle, radius) {
                    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

                    var x1 = startX + radius * Math.cos(Math.PI * startAngle / 180);
                    var y1 = startY + radius * Math.sin(Math.PI * startAngle / 180);
                    var x2 = startX + radius * Math.cos(Math.PI * endAngle / 180);
                    var y2 = startY + radius * Math.sin(Math.PI * endAngle / 180);

                    var pathString = "M" + startX + " " + startY + " L" + x1 + " " + y1 + " A" + radius + " " + radius + " 0 " + largeArcFlag + " 1 " + x2 + " " + y2 + " z";

                    return pathString;

                }


                if (group) {
                    group.addShape('path', {
                        attrs: {
                            path: generateWedgeString(0, 0, 0, 0, 30),
                            stroke: '#fff',
                            lineWidth: 0,
                            fill: '#fff',
                            zIndex: 10,

                        },
                        name: 'fan-in-path',
                    });
                }



                const radius = 18;

                const tools = 30;

                for (let i = 1; i <= tools; i++) {

                    const deg = 360 / tools;
                    const currentDeg = deg * i;

                    if (group) {
                        group.addShape('path', {
                            attrs: {
                                path: generateWedgeString(0, 0, currentDeg, currentDeg + deg, radius),
                                stroke: randomFromArray(colors),
                                lineWidth: 0,
                                fill: randomFromArray(colors),
                                zIndex: 10,
                            },
                            name: 'fan-in-path',
                        });
                    }
                }



                let fanIn;
                if (group) {
                    fanIn = group.addShape('circle', {
                        attrs: {
                            r: 10,
                            fill: '#eff4ff',
                            stroke: '#72CC4A',
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                            shadowColor: 'gray',
                            shadowBlur: 6,
                            zIndex: 100,
                            // strokeWidth: 50,
                            // opacity: 0.2
                            // strokeDasharray: 30,
                            // transition: 'stroke-dasharray 2.3s ease',
                            // x and y should be minus width / 2 and height / 2 respectively to translate the center of the rect to the midPoint

                        },
                    });
                }


                return fanIn;
            },
        },
        'single-node',
    );
}
