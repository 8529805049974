import React, { memo } from "react";

import { getGraph, getIcon, fixColor, setGraph } from "helpers";
import { GRAPH_SETTINGS } from "constants";

import { useSelector, useDispatch } from "react-redux";

import createGraph from 'features/graph/engine/createGraph';
import {
    Box,
    ListItem,
    ListItemText,
    IconButton,
} from "@mui/material";

import ShareIcon from '@mui/icons-material/Share';

import { red, green, yellow } from '@mui/material/colors';


import {
    selectResultGraphId,
    setResultGraphId,
} from "features/pages/pagesSlice";

import {
    setCurrentPage,
} from "features/main/mainSlice";

import {
    sendGetGraphData,
} from "features/graph/graphSlice";


const getColors = (weight) => {
    let mainColor;

    if (weight >= 0.8) {
        mainColor = red;
    } else if (weight > 0.5 && weight < 0.9) {
        mainColor = yellow;
    } else if (weight <= 0.5) {
        mainColor = green;
    }

    return {
        color: mainColor[900],
        backgroundColor: mainColor["50"],
        border: `1px solid ${mainColor["100"]}`,
    };
}


const ResultItemComponent = ({ item, depth = 0 }) => {
    const dispatch = useDispatch();
    // const resultGraphId = useSelector(selectResultGraphId);

    const colors = getColors(item.weight);

console.log(item);
    const handleClickGraphOpen = () => {

        dispatch(sendGetGraphData({
            data: {
                nozologyId: ""+(item.id || item.idl),// item.name - только на время разработки. Т.к. id иногда не приходит
                // guid: item.guid || item.name,// item.name - только на время разработки. Т.к. id иногда не приходит
            },
            success: () => {
                dispatch(setCurrentPage("graph"));
            }
        }));
    }

    return <ListItem
        dense
        sx={{
            width: "auto",
            ml: `${30 * depth}px`,
            mt: 1,
            mb: 1,
            mr: 3,
            ...colors,
        }}
        secondaryAction={
            <IconButton
                edge="end"
                aria-label="graph"
                onClick={handleClickGraphOpen}
            >
                <ShareIcon />
            </IconButton>
        }
    // disablePadding
    >
        <Box>{`${Math.round(item.weight * 100)}%`}</Box>
        <ListItemText
            sx={{
                ml: 3,
                color: "rgba(0, 0, 0, 0.87)"
            }}
            primary={`${item.name}`}
        />

    </ListItem>;
}


export const ResultItem = memo(ResultItemComponent);
