import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchSendClearCache({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.ClearCache,
    data,
    // success: (result) => {
    //   let searchResult = result && result.concepts && result.concepts.length > 0 ? result.concepts : [];
    //   success && success(searchResult);
    // },
  });
}
